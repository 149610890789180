<template>
  <div class="form-group" :class="className">
    <label class="gf_col-sm-4 gf_col-md-5">{{title}}</label>
    <input
      class="gf_col-sm-8 gf_col-md-7"
      type="text"
      :placeholder="placeholder"
      v-model="val"
      @input="onInput"
      v-bind="attrs"
      v-on="listeners"
      :readonly="readonly"
    />
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: {
    title: String,
    placeholder: String,
    value: String,
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: ""
    };
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    }
  },
  created() {
    this.val = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
    }
  }
};
</script>

<style>
</style>
