<template>
    <div class="gf_recm-temp">
        <div class="gf_recm-temp__header">
            <div class="gf_recm-temp__header-title">Preview Template</div>
            <div class="gf_recm-temp__header-action">
            <button class="gf_recm-temp__header-action-btn" @click="onUpdatePreviewTemplate()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12C7.72386 12 7.5 11.7761 7.5 11.5L7.5 2.75338L5.3627 5.00578C5.17262 5.2061 4.85615 5.2144 4.65583 5.02432C4.45552 4.83424 4.44722 4.51777 4.6373 4.31746L7.63304 1.16038C7.72437 1.06174 7.85497 1 8 1C8.14495 1 8.2755 1.06169 8.36683 1.16024L11.3627 4.31746C11.5528 4.51777 11.5445 4.83424 11.3442 5.02432C11.1438 5.2144 10.8274 5.2061 10.6373 5.00578L8.5 2.75338L8.5 11.5C8.5 11.7761 8.27614 12 8 12Z" fill="white"/>
                <path d="M1.50002 11C1.77616 11 2.00001 11.2239 2.00001 11.5L2 13.5001C2 13.7762 2.22382 14 2.5 14H13.4999C13.7761 14 13.9999 13.7762 13.9999 13.5L13.9999 11.5C13.9999 11.2238 14.2238 11 14.5 11C14.7761 11 14.9999 11.2239 14.9999 11.5L14.9999 13.5C14.9999 14.3285 14.3283 15 13.4999 15H2.5C1.67161 15 1 14.3285 1 13.5001L1.00001 11.5C1.00001 11.2238 1.22388 11 1.50002 11Z" fill="white"/>
                </svg>
                Update
            </button>
            </div>
        </div>
        <div class="gf_recm-temp__body px-3 py-3">
            <VRow class="mx-0 my-0">
                <VCol cols="6">
                <VCard>
                    <VCardTitle>
                    <h5 class="mr-8">Template Preview</h5>
                    <VTextField
                        append-icon="fa-search"
                        label="Search"
                        v-model="searchSelected"
                    />
                    </VCardTitle>
                    <VVirtualScroll
                    :items="queryTemplateSelected"
                    height="400"
                    item-height="56"
                    >
                    <template #default="{ item }">
                        <VListItem :key="`${item.handle}:${item.id}`">
                        <VListItemAction>
                            <VBtn
                            fab
                            x-small
                            depressed
                            color="error"
                            @click="doDeletePreviewTemplate(item)"
                            >
                            <VIcon x-small>fa-minus</VIcon>
                            </VBtn>
                        </VListItemAction>
    
                        <VListItemContent>
                            <VListItemTitle>
                            <span class="ml-2">{{ item.name }}</span>
                            </VListItemTitle>
                        </VListItemContent>
                        </VListItem>
                    </template>
                    </VVirtualScroll>
                </VCard>
                </VCol>
                <VCol cols="6">
                <VCard>
                    <VCardTitle>
                    <h5 class="mr-8">All Template</h5>
                    <VTextField
                        append-icon="fa-search"
                        label="Search"
                        v-model="searchUnSelect"
                    />
                    </VCardTitle>
                    <VVirtualScroll
                    :items="queryTemplateSelect"
                    height="400"
                    item-height="56"
                    >
                    <template #default="{ item }">
                        <VListItem :key="`${item.handle}:${item.id}`">
                        <VListItemAction>
                            <VBtn
                            fab
                            x-small
                            depressed
                            color="primary"
                            @click="doAddPreviewTemplate(item)"
                            >
                            <VIcon x-small>fa-plus</VIcon>
                            </VBtn>
                        </VListItemAction>
                        <VListItemContent>
                            <VListItemTitle>
                            <span class="ml-2">{{ item.name }}</span>
                            </VListItemTitle>
                        </VListItemContent>
                        </VListItem>
                    </template>
                    </VVirtualScroll>
                </VCard>
                </VCol>
            </VRow>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "Preview Template",
  data() {
    return {
      selectedRoles: [],
      searchSelected: "",
      searchUnSelect: "",
      templateSelected: []
    };
  },
  created () {
    const templatePreviewIds = this.previewTemplates.value || [];
    this.templateSelected = this.filtered.filter(item => templatePreviewIds.includes(item.id));
  },
  computed: {
    ...mapState({
      templates: state => state.template.list,
      previewTemplates: state => state.template.previewTemplates
    }),
    filtered() {
      return this.templates;
    },

    queryTemplateSelected() {
      const q = (this.searchSelected || "").toLowerCase();
      return (this.templateSelected || []).filter(x => {
        let name = (x.name || "").toLowerCase();
        return name.indexOf(q) > -1
      });
    },
    queryTemplateSelect() {
      const q = (this.searchUnSelect || "").toLowerCase();
      const templateSelected = this.templateSelected || [];
      return (this.filtered || [])
        .filter(x => {
          return templateSelected.findIndex(p => p.id == x.id) < 0;
        })
        .filter(x => {
          const name = (x.name || "").toLowerCase();
          return name.indexOf(q) > -1;
        });
    },
    previewTemplateIds() {
        return (this.templateSelected || []).map(x => x.id);
    }
  },
  methods: {
    ...mapActions({
      updatePreviewTemplate: "updatePreviewTemplate",
    }),
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
    }),
    async onUpdatePreviewTemplate() {
      this.OpenLoading();
      await this.updatePreviewTemplate({
        data: this.previewTemplateIds,
        onSuccess: () => {
          this.CloseLoading();
          this.OpenSuccess({
            message: "Update Preview Template Successfully",
            time: 1500,
          });
        },
        onError: (err) => {
          this.CloseLoading();
          this.OpenError({ message: err });
        }
      });
    },
    doAddPreviewTemplate(item) {
      this.templateSelected.push(item);
    },
    doDeletePreviewTemplate(item) {
      const idx = this.templateSelected.findIndex(x => x.id == item.id);
      if (idx > -1) {
        this.templateSelected.splice(idx, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
