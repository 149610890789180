const timestamp = [1, 5, 60, 120, 3600, 7200, 86400, 172800, 604800, 1209600, 2419200, 4838400, 29030400, 58060800];

/**
 * Function calculate how long has passed.
 * @param {Date} time 
 * @param {String} locale 
 */
let format = (time, locale = en) => {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = new Date();
  }
  let loc = locale.toUpperCase() === "FR" ? "fr" : "en";

  const getTimeago = require("./locales/" + loc + ".js");
  let diff = Math.round((new Date() - time) / 1000);
  let index = timestamp.length - 1;
  while ((index >= 0) && (diff < timestamp[index]))
    index--;

  let result = "";
  // console.error(`${getTimeago(index, 1)} with index=${index} and diff=${diff}`);

  if (index <= 0)
    return getTimeago(0, 0)[0];

  if (index % 2 === 0) {
    result = getTimeago(index, index)[0];
  } else {
    result = getTimeago(index, index)[0].replace("%s", Math.floor(diff / timestamp[index - 1]));
  }
  return result;
}

export default format;