// import arrayUnique from "@/library/arrayUnique.js";
import shopAPI from "@/api/shop.js";

export default {
  state: {
    list: [],
    loading: false,
    fetched: false,
  },
  mutations: {
    SHOP_FETCH_DOMAINS(state, list) {
      state.list = list
    }
  },
  actions: {
    fetchDomains(store) {
      let state = store.state;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      shopAPI
        .getListDomain()
        .then(res => {
          store.commit("SHOP_FETCH_DOMAINS", res.data.shops);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    async updateDefaultTemplateMetaFields(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      store.commit("OpenLoading");
      await shopAPI
        .updateDefaultTemplateMetaFields(data)
        .then((res) => {
          state.loading = false;
          store.commit("CloseLoading");
          if (res && res.data.status == "error") {
            store.commit("OpenError", { message: res.data.message })
          } else {
            store.commit("OpenSuccess", { message: "Update Template MetaField successfully!" })
          }
        })
        .catch((err) => {
          state.loading = false;
          store.commit("CloseLoading");
          store.commit("OpenError", { message: err });
        });
    },
  },
  getters: {
  }
};
