<template>
  <VListGroup no-action sub-group :prepend-icon="category.icon" class="pl-5">
    <template v-slot:activator>
      <VListItemTitle>{{ category.title }} </VListItemTitle>
      <VBtn class="mx-2" fab dark small color="indigo">
        <VIcon dark>
          fa-plus
        </VIcon>
      </VBtn>
    </template>

    <VListItem v-for="(item, i) in articles" :key="i" link>
      <VListItemTitle v-text="item.title"></VListItemTitle>

      <VListItemIcon>
        <VIcon v-text="item.icon"></VIcon>
      </VListItemIcon>
    </VListItem>

    <CategoryList
      v-for="(category, index) in categories"
      :key="index + category.title"
      :category="category"
      :categories="category.categories"
      :articles="category.articles"
    ></CategoryList>
  </VListGroup>
</template>

<script>
export default {
  name: "CategoryList",
  props: {
    category: {
      type: Object
    },
    categories: {
      type: Array
    },
    articles: {
      type: Array
    },
    expandAll: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  methods: {}
};
</script>

<style></style>
