<template>
  <div class="form-group" :class="className">
    <label class="gf_col-sm-4 gf_col-md-5">{{ title }}</label>
    <div class="gf_col-sm-8 gf_col-md-7 gf_mt-20 gf_no-padding">
      <ImageUpload
        :oldSrc.sync="picture"
        @delete="deleteImg"
        :progress="progress"
        :loading="process.loading"
        @load="uploadToServer"
        @uploadError="OpenError"
        :readonly="readonly"
        ref="uploader"
      >
        <svg width="50.748" height="47" viewBox="0 0 50.748 47">
          <path
            d="M41.964,28.668a8.758,8.758,0,0,0-3.319.649,13.666,13.666,0,0,0-26.543,0A8.784,8.784,0,1,0,8.783,46.235H41.964a8.783,8.783,0,1,0,0-17.567Z"
            transform="translate(0 -18.909)"
            fill="#6fc5d6"
          />
          <path
            d="M230.193,201.1l-2.307-3.46a.88.88,0,0,0-.06-.079l-.018-.02c-.016-.017-.032-.034-.049-.05l-.024-.022q-.027-.024-.056-.045l-.02-.015a.839.839,0,0,0-.084-.052.915.915,0,0,0-.088-.041l-.023-.008c-.023-.009-.047-.016-.071-.023l-.028-.007c-.024-.006-.048-.01-.072-.014l-.027,0a.879.879,0,0,0-.1-.006.854.854,0,0,0-.1.006l-.027,0c-.024,0-.049.008-.072.014l-.028.007q-.036.01-.071.023l-.023.008a.908.908,0,0,0-.086.039.876.876,0,0,0-.086.053l-.02.015c-.019.014-.038.029-.056.045l-.024.022c-.017.016-.033.033-.049.05l-.018.02a.87.87,0,0,0-.06.079l-2.307,3.461a.865.865,0,1,0,1.439.96l.722-1.083v24.735a.865.865,0,0,0,1.73,0V200.98l.722,1.083a.865.865,0,0,0,1.44-.96Z"
            transform="translate(-201.793 -179.581)"
            fill="#1b4145"
          />
        </svg>
        <h3 class="gt_title_sub_upload">Drag your image here</h3>
      </ImageUpload>
      <VTextField
        outlined
        dense
        class="gf_col-sm-12 gf_col-md-12 mt-2"
        type="text"
        :placeholder="placeholder"
        v-model="val"
        @input="onInput"
        @change="picture = val"
        @paste="onPaste"
        v-bind="attrs"
        v-on="listeners"
        :disabled="readonly"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/api/utils.js";
import { mapMutations } from "vuex";

export default {
  name: "InputImage",
  props: {
    title: {
      type: String,
      default: "Image"
    },
    folder: {
      type: String,
      default: "dashboard"
    },
    placeholder: String,
    value: String,
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: "",
      process: {
        upload: 0,
        loading: false
      },
      loading: false,
      picture: ""
    };
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    },
    progress() {
      return this.process.upload;
    }
  },
  methods: {
    ...mapMutations({
      OpenError: "OpenError"
    }),
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async onPaste(e) {
      if (e.clipboardData == false) {
        if (typeof callback == "function") {
          console.log("Undefined ");
          callback(undefined);
        }
      }

      var items = e.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
          console.log("Undefined 2");
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        var blob = items[i].getAsFile();
        console.log("should upload to server");
        var base64 = await this.toBase64(blob);
        this.uploadToServer({
          base64: base64
        });
      }
    },
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    },
    uploadToServer(data) {
      const self = this;
      this.process.upload = 0;
      this.process.loading = true;
      const config = {
        onUploadProgress(e) {
          let process = Math.round((e.loaded * 100) / e.total);
          if (process >= 100) {
            process = 95;
          }
          self.process.upload = process;
        }
      };
      utils
        .uploadImage({ data: data.base64, folder_name: this.folder }, config)
        .then(res => {
          if (res.data && res.data.status) {
            var img = new Image();
            img.src = res.data.url;
            this.val = res.data.url;
            img.onload = () => {
              this.process.loading = false;
              this.process.upload = 100;
              this.picture = res.data.url;
              this.$emit("input", this.val);
              this.$emit("onChangeWidthHeight", {width: img.width, height: img.height});
            };
            img.onerror = () => {
              this.process.loading = false;
              this.process.upload = 100;
              this.OpenError({
                message: "Cannot read image"
              });
            };
          } else {
            this.process.loading = false;
            this.process.upload = 0;
            this.picture = "";
            this.$refs.uploader.picture = "";
            this.OpenError({
              message: res.data.message || "Cannot read image"
            });
          }
        })
        .catch(err => {
          this.picture = "";
          this.$refs.uploader.picture = "";
          this.process.upload = 0;
          this.process.loading = false;
          this.OpenError({
            message: res.data.message || "Cannot read image"
          });
        });
    },
    deleteImg() {}
  },
  created() {
    this.val = this.value;
    this.picture = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
      this.picture = this.value;
    }
  }
};
</script>

<style></style>
