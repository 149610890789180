export default {
  state: {
    error: {
      message: null,
    },
    success: {
      message: null,
      time: 2000,
    },
    loading: {
      count: 0,
    },
  },
  mutations: {
    OpenError(state, error) {
      state.error.message = error.message || "Unknown Error Occur";
    },
    CloseError(state) {
      state.error.message = null;
    },
    OpenSuccess(state, success) {
      state.success.message = success.message;
      if (success.time) {
        state.success.time = success.time;
      }
    },
    CloseSuccess(state) {
      state.success.message = null;
      state.success.time = 2000;
    },
    // ChangeLoading(state, value) {
    //     if (value) {
    //         this.OpenLoading();
    //     } else {
    //         this.CloseLoading();
    //     }
    // },
    OpenLoading(state) {
      state.loading.count++;
    },
    CloseLoading(state) {
      state.loading.count--;
      if (state.loading.count < 0) state.loading.count = 0;
    },
    ForceCloseLoading(state) {
      state.loading.count = 0;
    },
  },
};
