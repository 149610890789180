<template>
  <div class="authentication">
    <div class="banner">
      <div class="red">Always</div>
      <div class="yellow">Deliver</div>
      <div class="green">More than</div>
      <div class="blue">Expected</div>
      <div class="quote">Larry Page, co-founder of Google</div>
    </div>
    <div class="form">
      <form @submit.prevent>
        <h5>Administrator Board Login</h5>
        <button
          type="button"
          class
          :class="btnClass"
          :disabled="processing || popupRunning"
          @click="fSignin"
        >
          {{ signInText }}
        </button>
        <div class="error" v-if="error && error !== ''">{{ error }}</div>
        <!-- <div class="sub-info">
          <br>
          for more information, please contact us at care@gempages.help or visit our website:
          <a
            href="https://gempages.net"
            target="_blank"
          >https://gempages.net</a>
        </div>-->
      </form>
    </div>
  </div>
</template>
<script>
import oauth from "@/api/auth.js";
import oauthLogin from "@/mixins/oauthLogin.js";
import Cookie from "@/utils/cookie.js";
import { baseUrl } from "@/configs";

export default {
  name: "Authenticate",
  mixins: [oauthLogin("loginWithToken", "popupRunning")],
  data() {
    return {
      popupRunning: false,
      processing: false,
      error: "",
      status: "",
    };
  },
  computed: {
    signInText() {
      if (this.processing) {
        return "Signing In...";
      }
      if (this.status == "ok") {
        return "Verified";
      }
      if (this.status == "err") {
        return "Login Fail";
      }
      if (this.status == "redirect") return "Login via SSO...";
      return "Sign In";
    },
    btnClass() {
      if (this.processing) {
        return "ajax-processing";
      }
      if (this.status == "ok") {
        return "ajax-success";
      }
      if (this.status == "err") {
        return "ajax-failed";
      }
      if (this.status == "redirect") return "ajax-error";
      return "gf_btn-normal";
    },
  },
  methods: {
    fSignin() {
      this.processing = true;

      oauth
        .verify(Cookie.get(Cookie.key))
        .then((res) => {
          this.processing = false;
          this.status = "ok";
          this.$router.push({ name: "Root" });
        })
        .catch((err) => {
          this.processing = false;
          this.error =
            err.message || "Session expired or invalid. Please re-sign-in.";
          this.status = "redirect";
          this.openOAuthLoginPopup(baseUrl() + "/auth")
        })
        .finally(() => {
          return;
        });
    },
    loginWithToken(token) {
      this.processing = false;
      Cookie.set(Cookie.key, token, 24 * 3600);
      this.status = "ok";
      this.error = "";
      this.$router.push("/");
      // console.log(res)
    },
  },
  created() {
    // let { prevent } = this.$route.query;
    // if (!prevent) this.fSignin();
  },
};
</script>

<style lang="scss" scoped>
.authentication {
  display: flex;
  flex-direction: row;
  background: white;
  min-height: 95vh;
  max-height: 95vh;
  overflow: auto;
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 60vw;
    height: 80vh;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 3rem;
    .red,
    .yellow,
    .green,
    .blue {
      height: 3rem;
    }
    .red {
      color: #ea4235;
      transform: scaleX(0.8);
    }
    .yellow {
      color: #fabc07;
      transform: scaleX(0.8);
    }
    .green {
      color: #32a853;
      transform: scaleX(0.8);
    }
    .blue {
      color: #4286f5;
      transform: scaleX(0.8);
    }
    .quote {
      font-size: 1rem;
      text-transform: none;
      position: relative;
      margin-top: 1rem;
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 2px;
        background: black;
        top: 50%;
      }
      &::before {
        left: -12px;
      }
      &::after {
        right: -12px;
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    justify-content: space-around;
    .error {
      display: block;
      border: 1px solid rgb(245, 71, 71);
      border-radius: 2px;
      background: rgba(233, 150, 150, 0.589) !important;
    }
    form {
      h5 {
        font-weight: bold;
        font-size: 1.5rem;
      }
      text-align: center;
      input.input-field {
        display: flex;
        width: 100%;
        height: 50px;
        margin: 20px 0;
        border-radius: 3px;
        &.error {
          background: rgba(255, 193, 193, 0.589);
          border-color: red;
          &:-internal-autofill-selected {
            background: rgba(255, 193, 193, 0.589);
          }
          // border-radius: 2px;
        }
      }
      button {
        display: flex;
        background: #4a90e2;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 350px;
        border: none;
        border-radius: 2px;
        margin: 20px 0;
        color: white;
        &:hover {
          cursor: pointer;
          background: #2473ce;
        }
        &.ajax-failed {
          background: rgb(204, 52, 52);
        }
        &.ajax-error {
          background: rgb(128, 128, 128);
        }
        &.ajax-success {
          background: rgb(68, 206, 33);
        }
        &.ajax-processing {
          background: rgb(33, 206, 197);
        }
      }
      .sub-info {
        text-align: left;
        color: #949494;
        max-width: 350px;
        font-size: 12px;
        .center {
          text-align: center;
        }
      }
    }
  }
}
</style>
