<template>
  <div class="d-flex flex-column">
    <VTabs>
      <VTab :to="{ name: 'ShopList' }" exact>Shop Activities</VTab>
      <VTab :to="{ name: 'DashboardV3' }">Dashboard V3</VTab>
      <!-- <VTab :to="{ name: 'WrongShopPlan' }">Wrong Plan Shop</VTab> -->
      <VTab :to="{ name: 'FixGidDuplicated' }">Fix g_id Duplicated</VTab>
      <VTab :to="{ name: 'ShopifyAppCredit' }">Shopify App Credit</VTab>
      <VTab :to="{ name: 'UpdateGemPagesVersion' }">Update GemPages Version</VTab>
      <VTab :to="{ name: 'UpdateDefaultTemplateMetaFields' }">Update Default Template MetaFields</VTab>
    </VTabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ShopsIndex",
};
</script>

<style></style>
