// import arrayUnique from "@/library/arrayUnique.js";
import api from "@/api/gp_config.js";

export default {
  state: {
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,
  },
  mutations: {
    GPCONFIG_TO_PAGE(state, page) {
      state.page = page;
    },
    GPCONFIG_ADD_TEMPLATE(state, template) {
      let index = state.list.findIndex((item) => item.id == template.id);
      if (index > -1) state.list.splice(index, 1, template);
      else state.list.push(template);
    },
    GPCONFIG_REMOVE_TEMPLATE(state, template) {
      let index = state.list.findIndex((item) => item.id == template.id);
      if (index > -1) state.list.splice(index, 1);
    },
    GPCONFIG_FETCH_TEMPLATES(state, list) {
      if (!list || !list.length) return;
      state.list = [];
      list.forEach((item) => {
        state.list.push(item);
      });
    },
  },
  actions: {
    fetchGPConfigs(store) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      api
        .getAll()
        // .getPage(state.page)
        .then((res) => {
          store.commit("GPCONFIG_FETCH_TEMPLATES", res.data.objects);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch((err) => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
  },
  getters: {},
};
