import api from "@/api";
import { catcher } from "@/api/handleError";
import { su } from "./endpoints";

export default {
  user: {
    list() {
      return api()
        .get(su.user.list)
        .catch(catcher);
    },
    update(data) {
      return api()
        .put(su.user.update, data)
        .catch(catcher);
    },

    addRole(data) {
      return api()
        .post(su.user.role.add, data)
        .catch(catcher);
    },
    addRoles(data) {
      return api()
        .post(su.user.role.adds, data)
        .catch(catcher);
    },
    getRoles(email) {
      return api()
        .get(su.user.role.get, { params: { email } })
        .catch(catcher);
    },
    getImplicitRoles(email) {
      return api()
        .get(su.user.role.implicit, { params: { email } })
        .catch(catcher);
    },
    deleteRole(data) {
      return api()
        .delete(su.user.role.delete, { data })
        .catch(catcher);
    },

    addPolicy(data) {
      return api()
        .post(su.user.policy.add, data)
        .catch(catcher);
    },
    addPolicies(data) {
      return api()
        .post(su.user.policy.adds, data)
        .catch(catcher);
    },
    getPoliciess(email) {
      return api()
        .get(su.user.policy.get, { params: { email } })
        .catch(catcher);
    },
    getImplicitPolicies(email) {
      return api()
        .get(su.user.policy.implicit, { params: { email } })
        .catch(catcher);
    },
    deletePolicy(data) {
      return api()
        .delete(su.user.policy.delete, { data })
        .catch(catcher);
    },
  },
  role: {
    list() {
      return api()
        .get(su.role.list)
        .catch(catcher);
    },
  },
  policy: {
    list() {
      return api()
        .get(su.policy.list)
        .catch(catcher);
    },
  },
};
