import Vue from "vue";
import Vuex from "vuex";
import NProgress from "nprogress";
Vue.use(Vuex);

import modules from "./modules";

NProgress.configure({ easing: "ease", speed: 500, showSpinner: true });

const store = new Vuex.Store({
  modules,
  state: {},
  mutations: {},
  actions: {},
});

store.watch(
  (state) => state.loader.loading,
  (nv, ov) => {
    if (nv === 0) return NProgress.done();
    if (ov === 0) return NProgress.start();
    NProgress.set(1.8 / Math.max(ov, nv));
  }
);

export default store;
