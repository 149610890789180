import api from "@/api";
import { catcher } from "@/api/handleError";
import { template } from "./endpoints";

export default {
  getPage(page = 1) {
    return api()
      .get(template.getPage, { params: { page } })
      .catch(catcher);
  },
  getAll() {
    return api()
      .get(template.getAll)
      .catch(catcher);
  },
  getOne(id) {
    return api()
      .get(template.get(id))
      .catch(catcher);
  },
  update(id, data) {
    return api()
      .put(template.update(id), data)
      .catch(catcher);
  },
  create(data) {
    return api()
      .post(template.create, data)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(template.delete(id))
      .catch(catcher);
  },
  getTemplateTags() {
    return api()
      .get(template.getTags)
      .catch(catcher);
  },
};
