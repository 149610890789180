<template>
  <div
    class="gf_notification-item gf_flex gf_flex-row gf_notification-item--content"
    v-if="item"
    @click="$emit('click', item)"
    ref="container"
  >
    <div class="gf_notification-item--text gf_notification-item--text-title">
      <h4>{{ item.title.en }}</h4>
      <div>
        <span class="gf_ranged-desc">Description: </span>
        <span class="gf_ranged-text">{{ item.desc.en }}</span>
      </div>
    </div>
    <div class="gf_notification-item--text gf_notification-item--text-content">
      <span class="gf_ranged-text">{{ item.content.en }}</span>
    </div>
    <div class="gf_notification-item--date">
      <span>{{ formatDate(item.from_date) }} ~ {{ formatDate(item.to_date) }}</span>
    </div>
    <div class="gf_notification-item--status">
      <span :class="{enable: item.is_enable, disable: !item.is_enable}">{{ item.is_enable ? "Enable" : "Disable" }}</span>
    </div>
    <div class="gf_notification-item--text gf_notification-item--action" @click.stop="$emit('delete', item)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="25" style="color: red">
        <path
          fill="currentColor"
          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
          class
        />
      </svg>
    </div>
  </div>

  <div class="gf_notification-item" v-else>
    <div class="gf_flex gf_flex-row gf_notification-item--content">
      <div class="gf_notification-item--text">
        <h4 class="gt_shimmer-180"></h4>
        <!-- <span class="gf_ranged-text"></span> -->
      </div>
      <div class="gf_notification-item--text gf_ml-20">
        <a class="gt_shimmer-140"></a>
        <span class="gt_shimmer-250"></span>
      </div>
      <div class="gf_notification-item--text gf_ml-auto gf_mr-auto gf_flex gf_align-i-center">
        <a class="gt_shimmer-300"></a>
      </div>
    </div>
  </div>
</template>

<script>
import notificationApi from "@/api/notification.js";
import { mapMutations, mapState } from "vuex";
export default {
  name: "NotificationItem",
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    ...mapMutations({
      updateNotification: "NOTIFICATION_UPDATE_NOTIFICATION"
    }),
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  created() {
  },
  watch: {
    item(newV) {
    }
  },

};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";
.gf_notification-item {
  white-space: nowrap;
  width: calc(100%);
  transition: all 0.3s;
  background: rgba(#d2dae2, 0.1);
  border: 1px solid rgba(#d2dae2, 0.5);
  border-radius: 4px;
  margin: 0 0em 1.5em 0em;
  padding: 1em;
  overflow: hidden;

  &--text {

    display: flex;
    align-items: center;
    padding-right: 5%;
    padding: 0 10px;
    h4 {
      font-weight: 700;
    }

    span,
    h4,
    a {
      color: #485259;
      font-size: 16px;
      line-height: 22px;

      &:empty {
        animation: pulse 1s infinite ease-in-out;
        // width: 50px;
        height: 17px;
        // min-width: 70px;
        display: block;
        // margin-bottom: 10px;
        border-radius: 10px;
      }
    }

    .gf_ranged-text {
      width: 100%;
      max-width: 100%;
      // min-width: 350px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      width: 100%;
      // display: flex;
    }

    a {
      // line-height: 13px;
      // font-size: 12px;
      // font-style: italic;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // height: calc(12px * 2);
      color: #1779ba;
    }
  }

  &--action {
    width: 5%;
    margin: -16px -16px -16px 16px;
    // border-radius: 50%;
    // margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: #888;
    margin: 0;
    padding-right: 0;
    &:hover {
      color: $primary_1;
      background: #ddd;
    }
  }

  &--text-title {
    width: 30%;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 30%;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    h4 {
      width: 100%;
      text-align: left;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div {
      width: 100%;
      display: flex;
      .gf_ranged-desc {
        width: auto;
        margin-right: 5px;
        text-decoration: underline;
      }
    }
  }

  &--text-content {
    min-width: 40%;
    padding: 0 10px;
    margin: 0;
    padding-right: 15px;
    max-width: 40%;
  }
  &--date {
    width: 15%;
    white-space: break-spaces;
    padding: 0 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--status {
    padding: 0 10px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    min-width: 60px;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    .enable {
      color: #3ba9ee;
    }
    .disable {
      color: #c4c5c7
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 #ccc;
  }
  .w-15p {
    width: 15%;
  }
}
</style>

