<template>
  <Portal to="modals">
    <div class="gt_modal" :class="bClass" @click.self="handleCancel">
      <div class="gt_modal-backdrop" @click.prevent="handleCancel" v-if="!noBackdrop"></div>
      <div class="gt_modal-container" :class="cClass">
        <header class="gt_modal-container_header" :class="hClass" v-if="hasHeader">
          <slot name="header"></slot>
          <div class="gf_ml-auto gt_modal-close" v-if="!hideExit" @click.prevent="handleCancel">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                class
              />
            </svg>
          </div>
        </header>
        <header class="gt_modal-container_actions" :class="aClass" v-if="hasActions">
          <slot name="actions"></slot>
        </header>
        <main class="gt_modal-container_body" :class="mClass" v-if="hasBody">
          <slot></slot>
        </main>
        <footer class="gt_modal-container_footer" :class="fClass" v-if="hasFooter">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </Portal>
</template>

<script>
export default {
  name: "Modal",
  props: {
    aClass: String,
    bClass: String,
    cClass: String,
    hClass: String,
    mClass: String,
    fClass: String,
    noBackdrop: {
      type: Boolean,
      default: false
    },
    hideExit: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    hasHeader() {
      return !!this.$slots["header"] || !!this.$scopedSlots["header"];
    },
    hasActions() {
      return !!this.$slots["actions"] || !!this.$scopedSlots["actions"];
    },
    hasBody() {
      return !!this.$slots["default"] || !!this.$scopedSlots["default"];
    },
    hasFooter() {
      return !!this.$slots["footer"] || !!this.$scopedSlots["footer"];
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
$baseIndex: 99999999999999;
.gt_modal {
  position: fixed;
  z-index: $baseIndex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(#000, 0.3);
  }

  &-container {
    background: white;
    // width: 400px;
    // height: 600px;
    display: flex;
    z-index: 10;
    flex-direction: column;
    border-radius: 5px;

    &_header {
      align-items: center;
    }
  }
  
  &-close {
    padding: 15px;
    transition: all 0.3s ease;
    color: #000;

    &:hover {
      cursor: pointer;
      color: #ff4757;
    }
  }
}
</style>

