import api from "@/api/index.js";
import { catcher } from "@/api/handleError";
import { promotion } from "./endpoints";

export default {
  getAll(page, limit) {
    if(page && limit) {
      return api()
      .get(promotion.getAll, { params: { page, limit }})
      .catch(catcher);
    }else {
      return api()
      .get(promotion.getAll)
      .catch(catcher);
    }
    
  },
  getDetail(id) {
    return api()
      .get(promotion.getDetail(id))
      .catch(catcher);
  },
  changeStatusCampaign(promotionId, body) {
    return api()
      .put(promotion.changeStatus(promotionId), body)
      .catch(catcher);
  },
  getCoupon(promotionId, page, limit) {
    if(page && limit) {
      const params = {
        promotion: promotionId,
        page,
        limit
      };
      return api()
        .get(promotion.coupon.get, { params })
        .catch(catcher);
    }
  },
  searchCoupon(promotionId, page, limit, search) {
    const params = {
      promotion: promotionId,
      keyword: search
    }
    if(page && limit) {
      params.page = page;
      params.limit = limit;
    }
    return api()
      .get(promotion.coupon.search, { params})
      .catch(catcher);
  },
  pickCoupon(promotionId, shopDomain, inherit) {
    const params = {}
    if(shopDomain) {
      params.shop = shopDomain;
    }
    if (inherit) {
      params.inherit = inherit;
    }

    return api()
      .get(promotion.coupon.pick(promotionId), { params })
      .catch(catcher);
  },
  changeStatusCoupon(couponId, body) {
    return api()
      .put(promotion.coupon.changeStatus(couponId), body)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(promotion.delete(id))
      .catch(catcher);
  },
  createCampaign(data) {
    return api()
      .post(promotion.create, data)
      .catch(catcher);
  },
  getLimit(limit, offset) {
    const params = {
      limit,
    }
    if (offset >= 0) {
      params.offset = offset;
    }
    return api()
      .get(promotion.getSnippets, { params })
      .catch(catcher);
  },
  update(data, id) {
    return api()
      .put(promotion.update(id), data)
      .catch(catcher);
  },
  create(data) {
    return api()
      .post(promotion.createSnippet, data)
      .catch(catcher);
  }
};
