import axios from "axios";
import store from "@/store/index.js";
import Cookie from "@/utils/cookie.js";
import { baseUrl } from "@/configs";

var settings = {
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + Cookie.get(Cookie.key),
    "G-Server-Domain": localStorage._GEM_API_HOST,
  },
  // withCredentials: true
};

let http = axios.create(settings);

// On request
http.interceptors.request.use(
  config => {
    // console.log(config)
    store.commit("loader/START_LOADING");
    return config;
  },
  error => {
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);

// On response
http.interceptors.response.use(
  response => {
    store.commit("loader/FINISH_LOADING");
    return response;
  },
  error => {
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);

export default () => {
  http.defaults.headers["Authorization"] = "Bearer " + Cookie.get(Cookie.key);
  http.defaults.headers["G-Server-Domain"] = localStorage._GEM_API_HOST;
  return http;
};