import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import camelCase from "@/plugins/lodash/camelCase.js";
import upperFirst from "@/plugins/lodash/upperFirst.js";
import PortalVue from "portal-vue";
// import "./registerServiceWorker";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const requireComponent = require.context(
  // The relative path of the components folder
  "./components/base/",
  // Whether or not to look in oepnsubfolders
  false,
  // /Base[A-Z]\w+\.(vue|js)$/
  /[\w-]+\.vue$/
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.directive("clickaway", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

Vue.directive('number-only', {
  bind: function(el) {
    el.addEventListener('input', function(e) {
      // Check if the input value matches the pattern
      var regex = /^[0-9.]+$/;
      if (!regex.test(e.target.value)) {
        // If the input value doesn't match the pattern, remove the invalid characters
        e.target.value = e.target.value.replace(/[^0-9.]+/g, '');
      }
    });
  }
});

Vue.prototype.$forceCompute = function(
  computedName,
  forceUpdate /* default: true */
) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == "undefined") this.$forceUpdate();
  }
};

Vue.use(PerfectScrollbar);
Vue.use(PortalVue);

console.info(`%cBuild time: ${process.env.VUE_APP_BUILD_TIME}`, "color: #00cec9;font-size: 18px;font-weight: 600;");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
