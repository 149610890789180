<template>
  <div class="gt_dropdown" :class="[pclass, 'drop'+popupDirection]" v-clickaway="closeDropdown">
    <button
      type="button"
      class="gt_btn gt_dropdown_toggle"
      :class="[bclass, {'active': showDropdown}]"
      ref="inputBounder"
      @click="toggleDropdown"
    >
      <slot name="button">Toggle Dropdown</slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.348"
        height="5"
        viewBox="0 0 9.348 5"
        v-if="!hideArrow"
      >
        <g transform="translate(-6.4 -33.4)">
          <path
            fill="#627381"
            d="M15.652 33.5a.33.33 0 0 0-.467 0l-4.107 4.115L6.964 33.5a.33.33 0 0 0-.467.467l4.34 4.34a.322.322 0 0 0 .234.1.336.336 0 0 0 .234-.1l4.34-4.34a.324.324 0 0 0 .007-.467z"
          />
        </g>
      </svg>
    </button>
    <transition name="fadeIn">
      <div class="gt_dropdown_list" :class="lclass" ref="dropContain" v-if="showDropdown">
        <slot>Dropdown items</slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    pclass: String,
    bclass: String,
    lclass: String,
    hideArrow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false,
      inVP: true,
      elHeight: 0
    };
  },
  computed: {
    popupDirection() {
      if (this.inVP) {
        return "down";
      }
      return "up";
    }
  },
  methods: {
    toggleDropdown() {
      if (this.showDropdown) {
        this.showDropdown = false;
      } else {
        this.showDropdown = true;
      }

      // if (!this.showDropdown) {
      // this.elHeight = 0
      // if (this.$refs && this.$refs.dropContain)
      //   this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
      // else {
      //   console.warn("DEO THAY REF")
      // }
      // }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    autoPosition() {
      if (!this.$refs) return;
      if (!this.$refs.inputBounder) return;
      if (this.$refs.dropContain)
        this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
      let viewportTop = window.pageYOffset,
        viewportBottom =
          window.pageYOffset +
          window.document.documentElement.clientHeight -
          80,
        rect = this.$refs.inputBounder.getBoundingClientRect(),
        elementTop = rect.top + viewportTop,
        elementBottom = elementTop + rect.height;
      // console.log({
      //   elementBottom,
      //   viewportBottom
      // });
      if (elementBottom + this.elHeight <= viewportBottom) this.inVP = true;
      else this.inVP = false;
    }
  },
  created() {
    this.$emit("created", this);
    window.addEventListener("scroll", this.autoPosition);
    window.addEventListener("mousewheel", this.autoPosition);
    window.addEventListener("ps-scroll-y", this.autoPosition);
    window.addEventListener("resize", this.autoPosition);
    this.toggleDropdown();
    this.autoPosition();
    this.toggleDropdown();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.autoPosition);
    window.removeEventListener("mousewheel", this.autoPosition);
    window.removeEventListener("ps-scroll-y", this.autoPosition);
    window.removeEventListener("resize", this.autoPosition);
  },
  watch: {
    showDropdown(newV) {
      if (this.$refs && this.$refs.dropContain)
        this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
    },
    elHeight(newV) {
      this.autoPosition();
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  &-enter-active {
    animation-name: fadeIn;
    animation-duration: 0.2s;
  }

  &-leave-active {
    animation-name: fadeIn;
    animation-duration: 0.2s;
    animation-direction: reverse;
  }
}

.gt_dropdown {
  position: relative;
  display: flex;
  // flex-direction: row;
  margin-left: auto;
  align-items: center;
  align-self: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #627381;

  &_toggle {
    // border: none;
    // outline: none;
    align-self: center;
    padding: 12px 15px;
    display: flex;
    align-items: center;

    &:focus {
      // border: inherit;
    }

    svg {
      margin-left: 10px;
      transition: all 0.3s ease;
    }

    &.active {
      color: #ff4757;
      svg {
        transform: rotate(180deg);
        path {
          fill: #ff4757;
        }
      }
    }
  }

  &_list {
    $arrowHeight: 10px;
    $arrowWidth: 10px;

    position: absolute;
    display: flex;
    flex-flow: column;
    padding: 10px;
    // top: calc(100% + #{$arrowHeight} + 2px);
    top: 100%;
    width: calc(100% + 26px);
    right: 0px;
    // text-align: right;
    z-index: 50;
    transform: translate(0, 0);

    border-radius: 5px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.01);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    // transform: translateY(50%)

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   right: 20px;
    //   border-left: $arrowWidth solid transparent;
    //   border-right: $arrowWidth solid transparent;
    //   border-bottom: $arrowHeight solid white;
    //   box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    //   /* border-top: 5px solid black; */
    // }
  }
}
</style>
<style lang="scss">
.gt_dropdown_item {
  padding: 9px 13px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #627381;
  &:hover {
    color: #5f6ec7;
    background-color: #f3f5f7;
    cursor: pointer;
  }
}
</style>
