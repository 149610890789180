<template>
  <div class="login-page">
    <div class="banner">
      <div class="red">Always</div>
      <div class="yellow">Deliver</div>
      <div class="green">More than</div>
      <div class="blue">Expected</div>
      <div class="quote">Larry Page, co-founder of Google</div>
    </div>
    <div class="form">
      <form @submit.prevent="requestLogin">
        <h5>Administrator Board Login</h5>
        <VTextField
          dense
          outlined
          type="text"
          name="username"
          v-model="username"
          class="input-field"
          :class="{'error': error.indexOf('username') > -1}"
          placeholder="chris@gempages.help"
          required
          autocomplete
          label="Username"
        />
        <VTextField
          dense
          outlined
          label="Password"
          type="password"
          name="password"
          v-model="password"
          class="input-field"
          :class="{'error': error.indexOf('password') > -1}"
          placeholder="password"
          required
          autocomplete
        />
        <button class="" :disabled="processing" @click="fSignin">{{signInText}}</button>
        <div class="error" v-if="error !== ''">{{error}}</div>
        <div class="sub-info">
          <!-- <router-link to="/signup" class="center">Doesn't have an account? Register now!</router-link> -->
          <br>
          for more information, please contact us at care@gempages.help or visit our website:
          <a
            href="https://gempages.net"
            target="_blank"
          >https://gempages.net</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import auth from "@/api/auth";
import jwt from "jsonwebtoken";
import { IDENTICAL } from "@/utils/CONSTANT.js";
import Cookie from "@/utils/cookie.js";
import { mapActions } from "vuex";
import { Promise } from 'q';
export default {
  name: "LoginPage",
  components: {
  },
  computed: {
    signInText() {
      if (this.processing) {
        return "Signing In..."
      }
      if (this.status == "ok") {
        return "Verified"
      }
      if (this.status == "err") {
        return "Login Fail"
      }
      return "Sign In";
    }
  },
  data() {
    return {
      username: "",
      password: "",
      processing: false,
      error: "",
      status: ""
    };
  },
  methods: {
    requestLogin() {
      this.error = "";
      // event.preventDefault();
      return false;
    },
    fSignin() {
      this.processing = true;
      setTimeout(() => {
        this.processing = false;
        this.verifyAccount()
          .then((token) => {
            this.status = "ok";
            let key = Cookie.key;
            // let token = jwt.sign()
            console.log(token)
            Cookie.set(key, process.env.VUE_APP_AUTH_TOKEN, 2);
            window.location.replace("//"+window.location.host);
          })
          .catch(() => {
            this.status = "err";
            this.error = "Wrong username or password."
          })
      }, 1000);
    },
    verifyAccount() {
      return new Promise((resolve, reject) => {
        if (this.username == IDENTICAL.USERNAME && this.password == IDENTICAL.PASSWORD) {
          jwt.sign({un: this.username, up: this.password}, IDENTICAL.KEY, { algorithm: 'HS256' }, (err, token) => {
            resolve(token);
          })
        } else {
          reject();
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: row;
  background-color: white;
  min-height: 95vh;
  max-height: 95vh;
  overflow: auto;
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 60vw;
    height: 80vh;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 3rem;
    .red,
    .yellow,
    .green,
    .blue {
      height: 3rem;
    }
    .red {
      color: #ea4235;
      transform: scaleX(0.8);
    }
    .yellow {
      color: #fabc07;
      transform: scaleX(0.8);
    }
    .green {
      color: #32a853;
      transform: scaleX(0.8);
    }
    .blue {
      color: #4286f5;
      transform: scaleX(0.8);
    }
    .quote {
      font-size: 1rem;
      text-transform: none;
      position: relative;
      margin-top: 1rem;
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 2px;
        background-color: black;
        top: 50%;
      }
      &::before {
        left: -12px;
      }
      &::after {
        right: -12px;
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    justify-content: space-around;
    .error {
      display: block;
      border: 1px solid rgb(245, 71, 71);
      border-radius: 2px;
      background-color: rgba(233, 150, 150, 0.589) !important;
    }
    form {
      h5 {
        font-weight: bold;
        font-size: 1.5rem;
      }
      text-align: center;
      input.input-field {
        display: flex;
        width: 100%;
        height: 50px;
        margin: 20px 0;
        border-radius: 3px;
        &.error {
          background-color: rgba(255, 193, 193, 0.589);
          border-color: red;
          &:-internal-autofill-selected {
            background-color: rgba(255, 193, 193, 0.589);
          }
          // border-radius: 2px;
        }
      }
      button {
        display: flex;
        background-color: #4a90e2;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 350px;
        border: none;
        border-radius: 2px;
        margin: 20px 0;
        color: white;
        &:hover {
          cursor: pointer;
          background-color: #2473ce;
        }
        &.ajax-failed {
          background-color: rgb(204, 52, 52);
        }
        &.ajax-error {
          background-color: rgb(128, 128, 128);
        }
        &.ajax-success {
          background-color: rgb(68, 206, 33);
        }
        &.ajax-processing {
          background-color: rgb(33, 206, 197);
        }
      }
      .sub-info {
        text-align: left;
        color: #949494;
        max-width: 350px;
        font-size: 12px;
        .center {
          text-align: center;
        }
      }
    }
  }
}
</style>
