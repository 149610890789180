<template>
  <!-- <div class="main--body--content"> -->
    <!-- <ul v-if="routing">
      <li :key="index" v-for="(item, index) in routing">
        <router-link :to="{name: item.name}" class="gf_flex">
          <span v-html="item.icon"></span>
          <span>{{item.text}}</span>
        </router-link>
      </li>
    </ul> -->

    <router-view />
  <!-- </div> -->
</template>

<script>
// import AllCampaigns from "./AllCampaigns";

export default {
  name: "Promotion",
  components: {
    // AllCampaigns
  },
  data() {
    return {
      // routing: [
      //   {
      //     name: "ShowAllCampaigns",
      //     text: "Campaigns"
      //   },
      //   {
      //     name: "CreateCampaign",
      //     text: "Create"
      //   }
      // ]
    };
  }
};
</script>

<style lang="scss" scoped>
.main--body--content {
  background-color: unset;
}
::v-deep .campaign_wrapper {
  margin-top: 20px;
}
// .gf_section-2 {
::v-deep hr {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  height: 1px;
  background: #ebebec;
  border: 1px #ebebec;
}
// }
::v-deep .hr_low {
  margin: 10px 0;
}
</style>
