import shopAPI from "@/api/shop.js";

export default {
  state: {
    list: [],
    loading: false,
    fetched: false,
  },
  mutations: {
    WRONG_PLAN_FETCH_LIST(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.list) !== JSON.stringify(list)) {
        state.list = list;
      }
    },
    WRONG_PLAN_UPDATE_LIST(state) {
      state.list = [];
    },
  },
  actions: {
    fetchWrongShopsPlan(store) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      shopAPI
        .fetchWrongShopsPlan()
        .then((res) => {
          store.commit("WRONG_PLAN_FETCH_LIST", res.data.shops);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch((err) => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    updateWrongShopsPlan(store) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      store.commit("OpenLoading");
      shopAPI
        .updateWrongShopsPlan()
        .then((res) => {
          store.commit("WRONG_PLAN_UPDATE_LIST");

          state.loading = false;
          
          store.commit("CloseLoading");
        })
        .catch((err) => {
          state.loading = false;
          store.commit("CloseLoading");
        });
    },
  },
  getters: {
    wrongShopsPlan: (state) => {
      return state.list;
    },
  },
};
