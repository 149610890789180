<template>
  <!-- <div class="gpa-tabs_container"> -->
    <nav class="gpa-tabs" :class="wrapperClass">
      <div
        class="gpa-tabs__item"
        type="button"
        v-for="tab in tabs"
        :ref="tab.key"
        :key="tab.title"
        :class="[
        { 'gpa-tabs__item_active' : tab.key === value },
        tab.key === value && tabActiveClass ? tabActiveClass: '',
        tabClass,
      ]"
        :disabled="tab.disabled || false"
        @click="handleClick(tab.key)"
      >
        <span v-html="tab.title"></span>
      </div>
    </nav>
    <!-- <section class="gpa-tabs_content"> -->

    <!-- </section> -->
  <!-- </div> -->
</template>

<script>
export default {
  name: "Tabs",
  props: {
    value: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    wrapperClass: {
      type: String,
      required: false
    },
    tabClass: {
      type: String,
      required: false
    },
    tabActiveClass: {
      type: String,
      required: false
    }
  },
  data: () => ({
    activeLineWidth: 0,
    activeLineOffset: 0,
    newTab: ""
  }),
  methods: {
    handleClick(value) {
      this.$emit("input", value);
      this.newTab = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.gpa-tabs {
  display: flex;
  flex-flow: row wrap;

  &__item {
    padding: 15px;
    border-radius: 0;
    cursor: pointer;
    // border-bottom: 1px solid #ccc;
    user-select: none;
    margin-bottom: -1px;
    // &:hover {
    //   border-bottom-color: #547AFF;
    // }
    &:hover,
    &_active {
      // border-bottom-color: #547aff;
      // color: #547aff;
      // background: #ddd;
    }
  }
}
</style>
