import api from "@/api";
import { catcher } from "@/api/handleError";
import { notification } from "./endpoints";

export default {
  getPage(page = 1) {
    return api()
      .get(notification.getPage, { params: { page }})
      .catch(catcher);
  },
  getAll() {
    return api()
      .get(notification.getAll)
      .catch(catcher);
  },
  getOne(noti_id) {
    return api()
      .get(notification.getOne(noti_id))
      .catch(catcher);
  },
  update(id,data) {
    return api()
      .put(notification.update(id), data)
      .catch(catcher);
  },
  create(data) {
    return api()
      .post(notification.create, data)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(notification.delete(id))
      .catch(catcher);
  }
};
