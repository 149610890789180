<template>
  <div></div>
</template>
<script>
import Cookie from "@/utils/cookie.js";
export default {
  mounted() {
    Cookie.set(Cookie.key, undefined, -1);
    this.$router.push({ name: "Authenticate", query: {prevent: true}, meta: {prevent: true}});
  }
}
</script>