// import arrayUnique from "@/library/arrayUnique.js";
import promotionAPI from "@/api/promotion.js";

export default {
  state: {
    campaignList: [
    ],
    currentCampaign : null,
    lastCampaign : null,
    couponList: [],
    randomCoupon : '',
    loading: false,
    hasNext: true,
    page: 1,
    limit : 10,
    total_campaigns: null,
    total_pages: null,
    paginationCoupon : {
      promotion_id: null,
      page: 1,
      limit : 10,
      total_campaigns: null,
      total_pages: null
    },
    fetched: false,
    fetchedDetail : false
  },
  mutations: {
    CAMPAIGN_FETCH_ALL(state, list) {
      if (!list || !list.length) return;

      state.campaignList = list;
      
    },

    CAMPAIGN_FETCH_CURRENT(state, currentCampaign) {
      if (!currentCampaign) return;

      if((currentCampaign.sent + currentCampaign.used) == currentCampaign.limit) {
        state.randomCoupon = '';
      }
      state.currentCampaign = currentCampaign;
    },

    CAMPAIGN_FETCH_COUPON(state, coupon) {
      if (!coupon) return;

      state.couponList = coupon;
      state.paginationCoupon.promotion_id  = coupon[0].promotion_id;
    },

    CAMPAIGN_FETCH_PAGINATION(state, pagination) {
      if (!pagination) return;

      state.page = pagination.current_page;
      state.limit = pagination.per_page;
      state.total_pages = pagination.total_pages;
      state.total_campaigns = pagination.total_objects;
    },
    
    CAMPAIGN_FETCH_PAGINATION_COUPON(state, pagination) {
      if (!pagination) return;

      state.paginationCoupon.page = pagination.current_page;
      state.paginationCoupon.limit = pagination.per_page;
      state.paginationCoupon.total_pages = pagination.total_pages;
      state.paginationCoupon.total_campaigns = pagination.total_objects;
    },

    CAMPAIGN_PICK_COUPON(state, data) {
      state.randomCoupon = data;
    },

    CAMPAIGN_SET_LASTCAMPAIGN(state, data) {
      if(!data) return;

      state.lastCampaign = data;
    },

    CAMPAIGN_FETCH_BY_PAGE(state, page) {
      state.page = page;
    },

    COUPON_FETCH_BY_PAGE(state, page) {
      state.paginationCoupon.page = page;
    }
  },
  actions: {
    fetchCampaigns(store, isPaging) {
      let state = store.state;
      state.loading = true;
      if (!state.fetched)
        store.commit("OpenLoading");
      else if(isPaging)
        store.commit("OpenLoading");

        promotionAPI
        .getAll(state.page, state.limit)
        .then(res => {
          if (res.data) {
            if (!state.fetched) {
              state.fetched = true;
            }
            store.commit("CloseLoading");

            store.commit("CAMPAIGN_FETCH_ALL", res.data.data.objects);
            store.commit("CAMPAIGN_FETCH_PAGINATION", res.data.data.pagination);
            // console.log(res.data.data)
            // state.offset += state.limit;
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          store.commit("OpenError", {message: err})
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        })

    },

    fetchCampaignDetail(store, id) {
      let state = store.state;
      state.loading = true;
      store.commit("OpenLoading");
        promotionAPI
        .getDetail(id)
        .then(res => {
          if (res.data) {
            store.commit("CloseLoading");
            state.randomCoupon = '';

            // if(state.lastCampaign) {
            //   if(res.data.data.id != state.lastCampaign.id) {
            //     state.randomCoupon = '';
            //   }
            // }
            

            store.commit("CAMPAIGN_FETCH_CURRENT", res.data.data);
            
            // store.commit("CAMPAIGN_FETCH_PAGINATION", res.data.pagination);
            // console.log(res.data.data)
            state.offset += state.limit;
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        })

    },
    fetchCoupon(store, promotionId) {
      let state = store.state;
      state.loading = true; 

      store.commit("OpenLoading");
      if(state.currentCampaign) {
        if(state.currentCampaign.id != state.paginationCoupon.promotion_id){
          state.paginationCoupon.page = 1;
        } 
      }
        promotionAPI
        .getCoupon(promotionId, state.paginationCoupon.page, state.paginationCoupon.limit)
        .then(res => {
          if (res.data) {
            store.commit("CloseLoading");

            store.commit("CAMPAIGN_FETCH_COUPON", res.data.data.objects);
            store.commit("CAMPAIGN_FETCH_PAGINATION_COUPON", res.data.data.pagination);
            // console.log(res.data.data)
            state.offset += state.limit;
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        })

    },

    searchCouponAction(store, params) {

      console.log(params)
      let state = store.state;
      state.loading = true; 

      store.commit("OpenLoading");
      state.paginationCoupon.page = 1;
      
        promotionAPI
        .searchCoupon(params.promotionId, state.paginationCoupon.page, state.paginationCoupon.limit, params.search)
        .then(res => {
          if (res.data) {
            store.commit("CloseLoading");

            store.commit("CAMPAIGN_FETCH_COUPON", res.data.data.objects);
            store.commit("CAMPAIGN_FETCH_PAGINATION_COUPON", res.data.data.pagination);
            // console.log(res.data.data)
            state.offset += state.limit;
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        })
    },

    pickCouponAction(store, params) {
      let state = store.state;
      state.loading = true; 

      if(params.showLoading != 'none')
        store.commit("OpenLoading");

        promotionAPI
        .pickCoupon(params.campaignId, params.shopDomain, !params.randomize)
        .then(res => {
          if (res.data && res.data != null) {
            store.commit("CloseLoading");
            store.commit("CAMPAIGN_PICK_COUPON", res.data.data);

            if(!res.data.data.shopify_domain && params.shopDomain) {
              store.commit("OpenError", {
                message: "Shop domain does not exist"
            });
            }
            state.offset += state.limit;
          } else {
            store.commit("CAMPAIGN_PICK_COUPON", res.data);
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          store.commit("CAMPAIGN_PICK_COUPON", '');
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        })

    },

    deleteCampaign(store, id) {
      let state = store.state;
      
      state.loading = true;
      store.commit("OpenLoading");
        promotionAPI
        .delete(id)
        .then(res => {
          if (res.data) {
            window.location.href = '/promotion';
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          store.commit("CloseLoading");
        })
    }
  }
};
