import shopAPI from "@/api/shop.js";

export default {
  state: {
    list: [],
    loading: false,
    fetched: false,
  },
  mutations: {
    PAGES_DUPLICATED_GID(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.list) !== JSON.stringify(list)) {
        state.list = list;
      }
    },
    UPDATE_PAGES_DUPLICATED_GID_ITEM(state, item) {
      const itemInList = state.list.find(x => x.id == item.id);
      if(itemInList){
        itemInList.g_id = item.g_id;
      }
    },
  },
  actions: {
    fetchPagesGidDuplicated(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");

      shopAPI
        .getPagesByShopGid(data)
        .then((res) => {
          store.commit("PAGES_DUPLICATED_GID", res.data.pages);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch((err) => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    updateGidPage(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      store.commit("OpenLoading");
      shopAPI
        .changeGidPage(data)
        .then((res) => {
          store.commit("UPDATE_PAGES_DUPLICATED_GID_ITEM", res.data);
          state.loading = false;
          store.commit("CloseLoading");
        })
        .catch((err) => {
          state.loading = false;
          store.commit("CloseLoading");
        });
    },
  },
  // getters: {
  //   wrongShopsPlan: (state) => {
  //     return state.list;
  //   },
  // },
};
