// import arrayUnique from "@/library/arrayUnique.js";
export default {
    state: {
        list: [],
    },
    mutations: {
        UpdateListTheme(state, list) {
            state.list = list
        },
    },
    actions: {

    },
    getters: {
        language_list: () => {
            return process.env.VUE_APP_LANGUAGE_LIST.split("-")
        }
    }
}