import { mapMutations } from "vuex";

export default (executor, statusHolding) => ({
  data: () => ({
    popup: null,
    googleTimeout: -1,
    glPreviousUrl: null,
  }),
  created() {
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
			CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
    }),
    toggleLoading(val = true) {
      if (val) {
        this.OpenLoading();
      } else {
        this.CloseLoading();
      }
      // if (val == false && typeof mutate == "object") {
      //   Object.keys(mutate).forEach(key => {
      //     this[key] = mutate[key];
      //   })
      // }
      this[statusHolding] = val;
    },

    openOAuthLoginPopup(url) {
      window.removeEventListener("message", this.receiveMessage);
      const windowName = "Login to GemPages CMS via GemAuth";
      const features = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

      if (this.popup === null || this.popup.closed) {
        /**
         * if the pointer to the window object in memory does not exist
         * or if such pointer exists but the window was closed
         */
        this.popup = window.open(url, windowName, features);
      } else if (this.glPreviousUrl !== url) {
        /**
         * if the resource to load is different,
         * then we load it in the already opened secondary window
         * and then we bring such window back on top/in front of its parent window.
         */
        this.popup = window.open(url, windowName, features);
        // this.popup.focus();
      } else {
        /**
         * else the window reference must exist and the window
         * is not closed; therefore, we can bring it back on top of any other
         * window with the focus() method. There would be no need to re-create
         * the window or to reload the referenced resource.
         */
        window.gPopup = this.popup;
        // this.popup.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener("message", this.receiveMessage, false);

      this.popup.addEventListener("unload", this.checkPopupClose);

      // this.popup.addEventListener("load", () => {});
      this.popup.focus();
    },
    receiveMessage(e) {
      if (e) {
        const { data } = e;
        console.log(e, data)
        if (data == "") {
          this.closePopup(false);
        } else {
          this.closePopup(true);
          this[executor](data);
        }
      }
    },
    closePopup(success = false) {
      if (this.popup && this.popup.opener) {
        this.popup.close();
      }
      this.toggleLoading(false);
      if (success) {
        this.OpenSuccess({
          message: "Authenticated",
          time: 1500
        })
      }
      this.popup = null;
      window.removeEventListener("message", this.receiveMessage, false);
    },
    checkPopupClose() {
      let interval = setInterval(() => {
        if (this.popup === null || this.popup.closed) {
          clearInterval(interval);
          this.toggleLoading(false);
        }
      }, 500);
    }
  },
  
});
