export const auth = {
  login: "/auth",
};
export const banner = {
  getAll: `/api/banner`,
  create: `/api/banner`,
  update: (id) => `/api/banner/${id}`,
  delete: (id) => `/api/banner/${id}`,
};

export const feedback = {
  getAll: `/api/feedbacks`,
  create: `/api/feedback/save`,
  update: (id) => `/api/feedback/${id}`,
  delete: (id) => `/api/feedback/${id}`,
  getFeedbackByHandle: (handle) => `api/feedback/getBy?handle=${handle}`,
};

export const crawler = {
  getActiveShop: `/crawler/track/active_shop`,
  getActivities: `/crawler/get-activites`,
  filterActivities: `/crawler/activity/filter`,
};

export const block = {
  getPage: (page) => `/api/blocks?page=${page}`,
  getAll: `/api/blocks?page=1&limit=1000`,
  getOne: (id) => `/api/blocks/${id}`,
  update: (id) => `/api/blocks/${id}`,
  create: `/api/blocks`,
  delete: (id) => `/api/blocks/${id}`,
  getTags: `/api/block/tags`,
};

export const blog = {
  getAll: `/api/blogs`,
  create: `/api/blogs`,
  update: (id) => `/api/blogs/${id}`,
  delete: (id) => `/api/blogs/${id}`,
};

export const customJob = {
  setShop: `/api/custom-job/set-shop-custom-dashboard`,
  insertShop: `/api/custom-job/insert-shop`,
};

export const gpConfig = {
  getPage: `/api/system_settings`,
  getAll: `/api/system_settings?page=1&limit=1000`,
  getOne: (id) => `/api/system_settings/${id}`,
  update: (id) => `/api/system_settings/${id}`,
  create: `/api/system_settings`,
  delete: (id) => `/api/system_settings/${id}`,
};

export const log = {
  getList: `/api/logs`,
  filter: `/api/fetch-logs`,
  getOne: (id) => `/api/logs/${id}`,
  update: `/api/logs`,
  updateStatus: "/api/logs/status",
};

export const notification = {
  getPage: `/api/notification`,
  getAll: `/api/notification?page=1&limit=1000`,
  getOne: (id) => `/api/notification/${id}`,
  update: (id) => `/api/notification/${id}`,
  create: `/api/notification`,
  delete: (id) => `/api/notification/${id}`,
};

export const promotion = {
  getAll: `/api/promotions`,
  getDetail: (id) => `/api/promotions/${id}`,
  changeStatus: (id) => `/api/promotions/${id}`,
  coupon: {
    get: `/api/promotion-coupons`,
    search: `/api/promotion-coupons`,
    pick: (promotionId) => `/api/promotions/${promotionId}/pick`,
    changeStatus: (id) => `/api/open/promotion-coupons/${id}`,
  },
  delete: (id) => `/api/promotions/${id}`,
  create: `/api/promotions`,
  getSnippets: `/api/snippets-admin`,
  update: (id) => `/api/promotions/${id}`,
  createSnippet: `/api/snippets-admin`,
};

export const purchase = {
  getAll: `/api/purchases`,
  create: `/api/purchases`,
};

export const shop = {
  listDomain: `/api/shop-list-domain`,
  updateDomain: `/api/shop-upper-sharing`,
  fetchWrongPlan: `/api/shop-wrong-plan`,
  updateWrongPlan: `/api/shop-wrong-plan`,
  getPagesByShopGid: `/api/list-duplicates`,
  changeGidPage: `/api/update-gid`,
  listAppCredit: `/api/list-app-credit`,
  createAppCredit: `/api/create-app-credit`,
  updateGemPagesVersion: `/api/update-gempages-version`,
  updateDefaultTemplateMetaFields: `/api/update-default-template-metafields`,
};

export const snippet = {
  getPage: `/api/snippets`,
  getAll: `/api/snippets?page=1&limit=1000`,
  getCategories: `/api/categories`,
  getWithKeyword: (keyword) => `/api/snippets-keyword/${keyword}`,
  get: (id) => `/api/snippets/${id}`,
  getOldVersion: (id) => `/api/snippets-old/${id}`,
  update: (id) => `/api/snippets/${id}`,
  create: `/api/snippets`,
  delete: (id) => `api/snippets/${id}`,
};

export const suggestion = {
  getAll: `/api/suggestions`,
  create: `api/suggestions`,
  update: (id) => `/api/suggestions/${id}`,
  delete: (id) => `/api/suggestions/${id}`,
};

export const template = {
  getPage: `/api/templates`,
  getAll: `/api/templates?page=1&limit=1000`,
  get: (id) => `/api/templates/${id}`,
  update: (id) => `/api/templates/${id}`,
  create: `/api/templates`,
  delete: (id) => `/api/templates/${id}`,
  getTags: `/api/template-tags`,
};

export const util = {
  uploadImage: "/api/utils/upload",
};

export const su = {
  user: {
    list: "/api/user-list",
    update: "/api/user-update",
    role: {
      add: "/admin/permission-manager/role-add",
      adds: "/admin/permission-manager/role-adds",
      get: `/admin/permission-manager/get-role-for-user`,
      implicit: `/admin/permission-manager/get-implicit-roles-for-user`,
      delete: "/admin/permission-manager/delete-role-for-user",
    },
    policy: {
      add: "/admin/permission-manager/policy-add",
      adds: "/admin/permission-manager/policy-adds",
      get: `/admin/permission-manager/get-permissions-for-user`,
      implicit: `/admin/permission-manager/get-implicit-permissions-for-user`,
      delete: "/admin/permission-manager/policy-remove",
    },
  },
  role: {
    list: "/admin/permission-manager/get-all-role",
  },
  policy: {
    list: "/admin/policy-list",
  },
};

export const i18n = {
  list: "/api/i18n",
  publish: "/api/i18n",
  refresh: "/api/i18n",
};

export const integration = {
  createRead: `/api/integrations`,
  RUD: (id) => `/api/integrations/${id}`,
  endpoint: {
    createRead: (id) => `/api/integrations/${id}/endpoints`,
    RUD: (id, endpointId) => `/api/integrations/${id}/endpoints/${endpointId}`,
    input: {
      createRead: (id, endpointId) =>
        `/api/integrations/${id}/endpoints/${endpointId}/inputs`,
      RUD: (id, endpointId, inputId) =>
        `/api/integrations/${id}/endpoints/${endpointId}/inputs/${inputId}`,
    },
  },
};

export const internalCategory = {
  list: "/api/internal/categories",
  create: `/api/internal/categories`,
  get: (categoryId) => `/api/internal/categories/${categoryId}`,
  update: (categoryId) => `/api/internal/categories/${categoryId}`,
  delete: (categoryId) => `/api/internal/categories/${categoryId}`,
};

export const internalArticle = {
  listByCategoryId: (categoryId) => `/api/internal/categories/${categoryId}`,
  list: "/api/internal/articles",
  get: (articleId) => `/api/internal/articles/${articleId}`,
  create: (categoryId) => `/api/internal/categories/${categoryId}/articles`,
  update: (articleId) => `/api/internal/articles/${articleId}`,
  delete: (articleId) => `/api/internal/articles/${articleId}`,
};
