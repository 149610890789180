import api from "@/api";
import { catcher } from "@/api/handleError";
import { snippet } from "./endpoints";

export default {
  getPage(page = 1) {
    return api()
      .get(snippet.getPage, { params: { page } })
      .catch(catcher);
  },
  getAll() {
    return api()
      .get(snippet.getAll)
      .catch(catcher);
  },
  getCategories() {
    return api()
      .get(snippet.getCategories)
      .catch(catcher);
  },
  getWithKeyword(keyword) {
    return api()
      .get(snippet.getWithKeyword(keyword))
      .catch(catcher);
  },
  getOne(snippet_id) {
    return api()
      .get(snippet.get(snippet_id))
      .catch(catcher);
  },
  getOldVersion(snippet_id, offset, limit) {
    const params = { offset, limit }
    return api()
      .get(snippet.getOldVersion(snippet_id), { params })
      .catch(catcher);
  },
  update(id, data) {
    return api()
      .put(snippet.update(id), data)
      .catch(catcher);
  },
  create(data) {
    return api()
      .post(snippet.create, data)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(snippet.delete(id))
      .catch(catcher);
  },
};
