<template>
  <div class="d-flex flex-column">
    <div class="purchase-header justify-end">
      <!-- <VTextField
        type="search"
        placeholder="Shopify domain..."
        outlined
        dense
        label="Search shopify domain"
        v-model="search"
      />
      <VBtn color="pink" class="mx-2" @click="searchByShopifyDomain"
        >Search</VBtn
      > -->
      <router-link class="add" :to="{ name: 'CreatePurchase' }">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New Purchase</span>
        </VBtn>
      </router-link>
    </div>

    <div class="tab_content">
      <div class="purchase_container">
        <div class="purchase_wrapper">
          <div class="purchase">
            <div class="item_wrapper purchase-top gf_flex gf_align-i-center">
              <div class="item purchase-item-action">
                Action
              </div>
              <div class="item purchase-item-charge">
                Charge ID
              </div>
              <div class="item purchase-item-status">
                Status
              </div>
              <div class="item purchase-item-domain">
                Title & Domain
              </div>
              <div class="item purchase-item-price">
                Price
              </div>
              <div class="item purchase-item-link">
                Link
              </div>
              <div class="item purchase-item-author">
                Author
              </div>
              <div class="item purchase-item-date">
                Date Created
              </div>
            </div>
          </div>
          <template v-for="(item, i) in purchaseList">
            <Purchase :purchaseItem="item" />
          </template>
        </div>
      </div>
      <Pagination
        :pagina.sync="pagination"
        :total.sync="pagination.total"
        @page="goToPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Purchase from "./Purchase";
import Pagination from "@/components/essenstials/Pagination";

export default {
  name: "AllLinkPurchase",
  data() {
    return {
      search: ""
    };
  },
  computed: {
    ...mapState({
      purchaseList: state => state.purchases.purchaseList,
      limit: state => state.purchases.limit,
      currentPage: state => state.purchases.page,
      total_purchases: state => state.purchases.total_purchases,
      total_pages: state => state.purchases.total_pages
    }),

    pagination() {
      return {
        per: this.limit,
        current: this.currentPage,
        total: this.total_pages
      };
    },
  },
  methods: {
    ...mapMutations({
      ChangeLoading: "ChangeLoading",
      toPage: "PURCHASE_FETCH_BY_PAGE"
    }),
    ...mapActions({
      fetchPurchases: "fetchPurchases"
    }),
    goToPage(page) {
      this.toPage(page);
      this.fetchPurchases({ isPaging: true, query: this.search });
    },
    searchByShopifyDomain() {
      this.fetchPurchases({ isPaging: true, query: this.search });
    }
  },
  created() {
    this.fetchPurchases({ query: this.search });
  },
  updated() {},
  components: {
    Purchase,
    Pagination
  }
};
</script>
<style lang="scss" scoped>
.tab_content {
  .tab_item {
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .create_segment {
    margin-right: 25px;
  }

  .tab_summary {
    min-height: 200px;
    background-color: #f9fafb !important;
  }

  .purchase_wrapper {
    margin-bottom: 20px;
  }
}

.tab_list {
  background-color: #fff;
  padding: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}
</style>
<style lang="scss">
.purchase {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin-bottom: 12px;
  &_container {
    overflow-x: auto;
    max-width: 100%;
  }
  &_wrapper{
    min-width: 1259px;
  }
  &-header {
    display: flex;
  }
  &-top {
    font-weight: bold;
  }

  .purchase-item {
    overflow: hidden;

    &-action {
      min-width: 8%;
    }
    &-charge {
      min-width: 10%;
    }

    &-status {
      min-width: 7%;
    }

    &-domain {
      min-width: 20%;
      max-width: 20%;
    }

    &-price {
      min-width: 10%;
      text-align: center;
    }

    &-link {
      min-width: 20%;
    }

    &-author {
      min-width: 15%; 
    }

    &-date {
      min-width: 10%;
      text-align: center;
    }
  }

  .item_wrapper {
    padding: 16px;

    .fix-200 {
      width: 200px;
    }

    .not_name {
      width: 100%;
    }
  }

  &:hover {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    transition: 0.3s all;
    cursor: pointer;
  }
}
</style>
