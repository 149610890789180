// import arrayUnique from "@/library/arrayUnique.js";
import docsAPI from "@/api/internal/docs.js";

export default {
  state: {
    categories: {
      loading: false,
      fetched: false,
      list: [],
      error: false
    },

    articles: {
      loading: false,
      fetched: false,
      list: [],
      error: false
    }
  },
  mutations: {
    DOCS_FETCH_CATEGORIES(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.categories.list) !== JSON.stringify(list)) {
        let union = [];
        const find = item => {
          let found = state.categories.list.findIndex(obj => {
            return obj.id === item.id;
          });

          return found;
        };

        list.forEach(item => {
          if (typeof item.metas == "string")
            item.metas = JSON.parse(item.metas);
          let index = find(item);
          if (index >= 0) {
            union.push({
              item: item,
              index: index
            });
          } else {
            state.categories.list.push(item);
          }
        });
        if (union.length > 0)
          union.forEach(uno => {
            state.categories.list.splice(uno.index, 1, uno.item);
          });
      }
    },
    DOCS_FETCH_ARTICLES(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.articles.list) !== JSON.stringify(list)) {
        let union = [];
        const find = item => {
          let found = state.articles.list.findIndex(obj => {
            return obj.id === item.id;
          });

          return found;
        };

        list.forEach(item => {
          if (typeof item.metas == "string")
            item.metas = JSON.parse(item.metas);
          let index = find(item);
          if (index >= 0) {
            union.push({
              item: item,
              index: index
            });
          } else {
            state.articles.list.push(item);
          }
        });
        if (union.length > 0)
          union.forEach(uno => {
            state.articles.list.splice(uno.index, 1, uno.item);
          });
      }
    },
    DOCS_REPLACE_ARTICLES_LIST(state, list) {
      state.articles.list = list;
    },
    DOCS_ADD_CATEGORY(state, category) {
      let index = state.categories.list.findIndex(
        item => item.id == category.id
      );
      if (index > -1) state.categories.list.splice(index, 1, category);
      else state.categories.list.push(category);
    },
    DOCS_ADD_ARTICLE(state, article) {
      let index = state.articles.list.findIndex(item => item.id == article.id);
      if (index > -1) state.articles.list.splice(index, 1, article);
      else state.articles.list.push(article);
    },
    DOCS_DELETE_ARTICLE(state, article) {
      let index = state.articles.list.findIndex(item => item.id == article.id);
      if (index > -1) {
        state.articles.list.splice(index, 1);
      }
    },
    DOCS_DELETE_CATEGORY(state, category) {
      let index = state.categories.list.findIndex(
        item => item.id == category.id
      );
      if (index > -1) {
        state.categories.list.splice(index, 1);
      }
    }
  },
  actions: {
    fetchCategories(store) {
      let state = store.state.categories;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      docsAPI
        .getAllCategories()
        .then(res => {
          store.commit("DOCS_FETCH_CATEGORIES", res.data.categories);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    fetchArticles(store, params) {
      let state = store.state.articles;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      docsAPI
        .getAllArticles(params)
        .then(res => {
          if (params && params.s) {
            store.commit("DOCS_REPLACE_ARTICLES_LIST", res.data.articles);
          } else {
            store.commit("DOCS_FETCH_ARTICLES", res.data.articles);
          }

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    }
  },
  getters: {
    getDocCategories: state => {
      return state.categories.list;
    },
    getDocArticles: state => {
      return state.articles.list;
    }
  }
};
