<template>
  <div class="main--body--content">
    <div class="gf_flex button-add-item">
      <VTextField
        type="search"
        placeholder="Type something to search..."
        outlined
        dense
        label="Search Block"
        v-model="search"
      />
      <VBtn color="pink" class="mx-2" @click="exportData">Export Data</VBtn>
      <router-link class="add" :to="{ name: 'NewBlock' }">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New Block</span>
        </VBtn>
      </router-link>
    </div>

    <div class="gf_flex gf_flex-wrap" v-if="!fetched && loading">
      <BlockItem v-for="index in 10" :key="index" />
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="!blocks.length">
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>
            There's still no block in database. Please insert more to view.
          </h4>
        </div>
        <img class="psyduck" src="../../assets/psyduck.png" />
      </div>
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="list.length">
      <BlockItem
        :key="i"
        v-for="(t, i) in list"
        :item="t"
        @click="viewDetailBlock"
        @delete="deleteBlock"
      />
      <Pagination
        :pagina.sync="pagination"
        :total.sync="pagination.total"
        @page="ToPage"
      />
    </div>
    <div
      class="gf_flex gf_flex-wrap"
      v-else-if="!list.length && blocks.length > 0"
    >
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>Nothing match your search. Please try another keyword.</h4>
        </div>
        <img class="pikachu" src="../../assets/pika_stand.png" />
      </div>
    </div>

    <!-- Modal -->
    <Modal
      v-if="itemDelete"
      :hideExit="true"
      cClass="gf_padding-20"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModalDelete"
    >
      <template v-slot:default>
        <div class="gf_col-md-12">
          <h3>Are you sure</h3>
          <h4 style="margin: 5px 0 30px 0;">
            you want to delete {{ itemDelete.name }}?
          </h4>
          <div class="gf_btn-group gf_mt-20">
            <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
              Cancel
            </VBtn>
            <VBtn color="red" class="ml-2 white--text" @click="doDelete">
              <template v-if="deleting">Deleting</template>
              <template v-else>Delete</template>
            </VBtn>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import blockAPI from "@/api/block.js";
import BlockItem from "./BlockItem.vue";
import Pagination from "@/components/essenstials/Pagination.vue";
import { serverUrl } from "@/configs";
import Cookie from "@/utils/cookie.js";

export default {
  name: "Block-Index",
  components: {
    BlockItem,
    Pagination,
  },
  data() {
    return {
      search: "",
      filterVal: -1,
      showModal: false,
      itemDelete: undefined,
      deleting: false,
    };
  },
  computed: {
    ...mapState({
      blocks: (state) => state.block.list,
      limit: (state) => state.block.limit,
      page: (state) => state.block.page,
      loading: (state) => state.block.loading,
      fetched: (state) => state.block.fetched,
    }),
    ...mapGetters({}),
    list() {
      return this.queriedList.filter((item, index) => {
        return (
          index >= (this.page - 1) * this.limit &&
          index < this.page * this.limit
        );
      });
    },
    queriedList() {
      let search = this.search.toLowerCase();
      return this.filtered.filter((item) => {
        let name = (item.name || "").toLowerCase();
        if (name.indexOf(search) > -1) return true;
        return false;
      });
    },
    pagination() {
      let total = Math.ceil(this.queriedList.length / this.limit);
      return {
        per: this.limit,
        current: this.page,
        currentraw: this.page,
        total: total,
      };
    },
    filtered() {
      return this.blocks;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      DeleteBlock: "BLOCK_REMOVE_BLOCK",
      ToPage: "BLOCK_TO_PAGE",
    }),
    ...mapActions({
      fetchBlocks: "fetchBlocks",
    }),
    exportData() {
      const token = Cookie.get(Cookie.key);
      const url = `${serverUrl()}/api/open/blocks/export-global?token=${token}`;
      window.open(url, "_blank");
    },
    viewDetailBlock(item) {
      this.$router.push({
        name: "EditBlock",
        params: { block_id: item.id },
      });
    },
    deleteBlock(item) {
      if (!item) return;
      this.itemDelete = item;
    },
    doDelete() {
      this.deleting = true;
      blockAPI
        .delete(this.itemDelete.id)
        .then((res) => {
          this.deleting = false;
          this.Deleteblock(this.itemDelete);
          this.closeModalDelete();
          this.OpenSuccess({ message: "<p>Delete block successfully!</p>" });
        })
        .catch((err) => {
          this.deleting = false;
          this.closeModalDelete();
          this.OpenError({ message: err });
        });
    },
    closeModalDelete() {
      this.itemDelete = undefined;
    },
  },
  created() {
    document.title = "Block Management";
    if (!this.fetched) this.fetchBlocks();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  },
};
</script>

<style lang="scss">
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
  position: relative;
  background: #d6cba4;
  border-radius: 0.4em;
  padding: 15px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}
</style>
