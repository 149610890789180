<template>
  <VContainer class="main--body--content">
    <VRow>
      <VCol cols="12">
        <InputImage
          title="File Uploader"
          placeholder="https://uploadcare.com/jasf9028qitws/asfl;asf/3000x3000/light"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h3 class="mb-2">GemPages Markdown Editor</h3>
        <div :id="id" class="gem-md-editor" />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
// deps for editor
import "@toast-ui/editor/dist/toastui-editor.css";
import Editor from "@toast-ui/editor";
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'prismjs/themes/prism.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import defaultOptions from "./default-options";

export default {
  name: "MarkdownEditor",
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default() {
        return (
          "markdown-editor-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      }
    },
    options: {
      type: Object,
      default() {
        return defaultOptions;
      }
    },
    mode: {
      type: String,
      default: "markdown"
    },
    height: {
      type: String,
      required: false,
      default: "600px"
    },
    language: {
      type: String,
      required: false,
      default: "en_US" // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
    }
  },
  data() {
    return {
      editor: null
    };
  },
  computed: {
    editorOptions() {
      const options = Object.assign({}, defaultOptions, this.options);
      options.initialEditType = this.mode;
      options.height = this.height;
      options.language = this.language;
      return options;
    }
  },
  watch: {},
  mounted() {
    this.initEditor();
  },
  destroyed() {},
  methods: {
    initEditor() {
      this.editor = new Editor({
        el: document.getElementById(this.id),
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        ...this.editorOptions
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
