// import arrayUnique from "@/library/arrayUnique.js";
import snippetAPI from "@/api/snippet.js";

export default {
  state: {
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,

    // snippets: {
    //   pages: {},
    //   list: [],
    //   current_page: 1,
    //   per_page: 10,
    //   total_objects: 0,
    //   total_pages: 1,
    // },

    category: {
      list: [],
      loading: false,
      fetched: false,
      error: false
    }
  },
  mutations: {
    // SNIPPET_FETCH_SNIPPETS_PAGE(state, {list, page}) {
    //   state.snippets.pages["_"+page] = list;
    //   state.snippets.list = list;
    // },
    SNIPPET_TO_PAGE(state, page) {
      // state.snippets.current_page = page;
      state.page = page;
    },
    // SNIPPET_UPDATE_PAGINATION(state, pagination) {
    //   Object.keys(pagination).forEach(key => {
    //     state.snippets[key] = pagination[key];
    //   })
    // },
    
    SNIPPET_ADD_SNIPPET(state, snippet) {
      let index = state.list.findIndex(item => item.id == snippet.id);
      if (index > -1)
        state.list.splice(index, 1, snippet);
      else
        state.list.push(snippet);
    },
    SNIPPET_REMOVE_SNIPPET(state, snippet) {
      let index = state.list.findIndex(item => item.id == snippet.id);
      if (index > -1)
        state.list.splice(index, 1);
    },
    SNIPPET_FETCH_SNIPPETS(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.list) !== JSON.stringify(list)) {
        let union = [];
        const find = item => {
          let found = state.list.findIndex(obj => {
            return obj.id === item.id;
          });

          return found;
        };

        list.forEach(item => {
          if (typeof item.metas == "string")
            item.metas = JSON.parse(item.metas);
          let index = find(item);
          if (index >= 0) {
            union.push({
              item: item,
              index: index
            });
          } else {
            state.list.push(item);
          }
        });
        if (union.length > 0)
          union.forEach(uno => {
            state.list.splice(uno.index, 1, uno.item);
          });
      }
    },
    SNIPPET_FETCH_CATEGORIES(state, list) {
      if (!list || !list.length) return;
      state.category.list = [];
      list.forEach(item => {
        state.category.list.push(item);
      });
    },
    SNIPPET_UPDATE_SNIPPET(state, snippet) {
      if (!snippet) return;
      let index = state.list.findIndex(item => {
        return snippet.id == item.id;
      });
      if (index > -1) {
        state.list.splice(index, 1, snippet);
      }
    }
  },
  actions: {
    fetchSnippets(store) {
      let state = store.state;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      snippetAPI
        .getAll()
        // .getPage(state.page)
        .then(res => {
          store.commit("SNIPPET_FETCH_SNIPPETS", res.data.objects);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    // fetchSnippetsPage(store, page) {
    //   let state = store.state;
    //   if (state.loading)
    //     return;
    //   state.loading = true;
    //   // if (!state.fetched)
    //     store.commit("OpenLoading");
    //   snippetAPI
    //     .getPage(page)
    //     .then(res => {
    //       store.commit("SNIPPET_FETCH_SNIPPETS", res.data.objects);
    //       store.commit("SNIPPET_FETCH_SNIPPETS_PAGE", {list: res.data.objects, page: res.data.pagination.current_page});
    //       // store.commit("SNIPPET_UPDATE_PAGINATION", res.data.pagination);
    //       let pagination = res.data.pagination;
    //       Object.keys(pagination).forEach(key => {
    //         state.snippets[key] = pagination[key];
    //       })
    //       store.commit("SNIPPET_TO_PAGE", res.data.pagination.current_page);
    //       // state.offset += state.limit;

    //       state.loading = false;
    //       // if (!state.fetched) 
    //       {
    //         store.commit("CloseLoading");
    //         state.fetched = true;
    //       }
    //     })
    //     .catch(err => {
    //       state.loading = false;
    //       // if (!state.fetched) {
    //         store.commit("CloseLoading");
    //       // }
    //     });
    // },
    fetchSnippetCategories(store) {
      let state = store.state.category;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      snippetAPI
        .getCategories()
        .then(res => {
          // console.log("========================================")
          if (!state.fetched) {
            store.commit("CloseLoading");
            
          }
          if (res.data) {
            store.commit("SNIPPET_FETCH_CATEGORIES", res.data.data);
            state.fetched = true;
            
          }
          state.loading = false;
        })
        .catch(err => {
          // console.warn("++++++++++++++++++++++++++++++++++++++++++")
          state.loading = false;
          state.error = true;
          // console.error(err)
          store.commit("OpenError", {message: err})
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    }
  },
  getters: {
    // snippets_currentPage: state => {
    //   return (state.snippets.pages["_"+(state.snippets.current_page)] || []);
    // },
    snippets_pagination: state => {
      let total = Math.ceil(state.list.length / state.limit);
      return {
        per: state.limit,
        current: state.page,
        currentraw: state.page,
        total: total
      };
    },
  }
};
