<template>
  <div class="main--body--content">
    <div class="back-menu">
      <a
        @click="$router.go(-1)"
        href="javascript:void('back')"
        :to="{ name: 'Snippets' }"
      >
        <VIcon class="mr-2" dense>fa-caret-left</VIcon>
        <span>Back</span>
      </a>
    </div>
    <div class="tab_content">
      <div class="tab_create">
        <h2></h2>
        <div class="create_segment gf_col-lg-7 gf_col-md-7 gf_col-sm-7">
          <div
            class="tab_item tab_warning"
            v-if="campaignDetailItem && campaignDetailItem.status == 'disable'"
          >
            <h4>This campaign is currently not activated.</h4>
          </div>
          <div class="tab_item gf_flex gf_justify-c-between">
            <div class="full-width mr-30">
              <p class="title">Name *</p>
              <VTextField
                dense
                outlined
                type="text"
                v-model="c_name"
                name="c_name"
              />
            </div>

            <div class="full-width">
              <p class="title">Prefix</p>
              <VTextField
                dense
                outlined
                type="text"
                v-model="c_prefix"
                name="c_prefix"
              />
            </div>
          </div>

          <div class="tab_item">
            <p class="title">UNIT*</p>
            <VRadioGroup v-model="unit">
              <VRadio value="%" label="% (Percent)" />
              <VRadio value="$" label="$ (Dollar)" />
            </VRadioGroup>
          </div>
          <!-- Value  -->
          <div class="tab_item">
            <p class="title">Value*</p>

            <!-- type Discount -->
            <div>
              <VTextField
                dense
                outlined
                v-if="unit == '%'"
                class="input_value"
                type="text"
                v-model="condition_value"
                suffix="%"
              />
              <VTextField
                dense
                outlined
                v-else
                class="input_value"
                type="text"
                v-model="condition_value"
                suffix="$"
              />
            </div>
          </div>

          <div class="tab_item">
            <p class="title">APPLIES TO*</p>
            <div>
              <VRadioGroup v-model="applyTo">
                <VRadio value="all" label="All" />
                <VRadio value="starter" label="Starter" />
                <VRadio value="professional" label="Professional" />
                <VRadio value="advanced" label="Advanced" />
                <VRadio value="build" label="Build" />
                <VRadio value="optimize" label="Optimize" />
                <VRadio value="enterprise" label="Enterprise" />
              </VRadioGroup>
            </div>
          </div>
          <div class="tab_item">
            <p class="title">Duration</p>
            <VSelect
              outlined
              dense
              class="col-3"
              v-model="duration"
              :items="durationOption"
            ></VSelect>
          </div>

          <!-- Active Date  -->
          <div class="tab_item">
            <p class="title">Active dates</p>

            <div class="datePicker">
              <DatePicker
                v-if="selectDateRange"
                v-model="date"
                range
                not-after
                not-before
                lang="en"
              />
              <DatePicker
                v-else
                v-model="date"
                not-after
                not-before
                lang="en"
              />

              <div class="gf_flex">
                <VCheckbox
                  label="Select date range"
                  v-model="selectDateRange"
                />
              </div>
            </div>
          </div>

          <div class="tab_item">
            <div class="gf_flex gf_justify-c-between">
              <p class="title">Coupon</p>
            </div>
            <p class="sub_title">Limit</p>
            <div class="gf_flex gf_justify-c-between">
              <VTextField
                outlined
                dense
                class="input_value"
                type="number"
                v-model="limit_used"
                name="limit_used"
                label="Coupon limit"
                placeholder="0"
              />
            </div>
            <div class="sub-title">
              Used: <strong>{{ coupon_shops.length }}</strong>
            </div>
            <VDataTable
              v-if="coupon_shops.length"
              :headers="tableHeaders"
              :items="couponShopData"
              :items-per-page="15"
              multi-sort
            />
          </div>

          <div class="tab_item">
            <div class="gf_flex gf_justify-c-between">
              <p class="title">Tracking URL</p>
            </div>

            <div class="d-flex gf_align-i-center">
              <VTextField
                dense
                outlined
                class="m-0"
                type="text"
                v-model="url_tracking"
                name="url_tracking"
                autocomplete="off"
                placeholder="?utm_source=firepush_promo&utm_medium=email&utm_campaign=halloween"
                :prefix="`${apiHost}/coupons/CODE`"
              />
            </div>
          </div>
        </div>

        <div class="summary_segment gf_col-lg-4 gf_col-md-4 gf_col-sm-4">
          <div class="tab_summary tab_item">
            <p class="title">Summary</p>
            <p class="title_summary">
              {{ c_name }} {{ c_prefix != "" ? "(" + c_prefix + ")" : "" }}
            </p>
            <ul class="category_summary">
              <li v-if="types == 'discount' && condition_value != ''">
                {{ condition_value + unit }} off
              </li>
              <li v-if="types == 'plan' && condition_value != ''">
                Upgrade to {{ condition_value }}
              </li>
              <li v-if="types == 'plan'">Revert to</li>
              <li>For {{ applyTo }}</li>
              <li v-if="date != ''">Start: {{ formatDate(start_time) }}</li>
              <li v-if="date != ''">End: {{ formatDate(end_time) }}</li>
              <li v-if="coupon_limit">Total: {{ coupon_limit }}</li>
              <!-- <li>New coupon: {{couponNew }}</li> -->
              <!-- <li>Sent coupon: {{couponSent }}</li> -->
              <li>Used coupon: {{ couponUsed }}</li>
            </ul>

            <hr />
            <router-link
              :to="{
                name: 'CouponList',
                params: { id: this.campaignDetailId }
              }"
            >
              <VBtn color="primary" block type="button">
                See all coupons
              </VBtn>
            </router-link>
          </div>

          <form
            @submit.prevent="pickCoupon"
            class="tab_summary tab_item"
            id="pickCoupon"
          >
            <p class="title">Pick coupon</p>
            <p class="sub_title mb-2">Shop domain (optional)</p>
            <VTextField
              dense
              outlined
              type="text"
              v-model="shopDomain"
              placeholder="Shop domain"
            />
            <p v-if="error" class="notify error">{{ error }}</p>
            <p class="sub_title mb-2">Randomize Code?</p>
            <ToggleSwitch class="pl-0" hideTitle v-model="randomize" />

            <p class="sub_title mb-2 mt-10">Coupon Code</p>
            <!-- <input class="m-0" type="text" :value="randomCoupon ? randomCoupon.code : ''" placeholder="Coupon" disabled/>

            //-->
            <div class="input_with_copy gf_flex gf_align-i-center mb-2">
              <input
                class="m-0"
                ref="inputCoupon"
                type="text"
                :value="randomCoupon ? randomCoupon.code : ''"
                placeholder="Coupon"
                disabled
              />

              <svg
                @click="copy(randomCoupon.code)"
                width="30"
                height="30"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M53.9791 9.1429H50.0108C49.9281 9.1429 49.8546 9.1713 49.7777 9.1899V5.0228C49.7777 2.253 47.4731 0 44.6398 0H10.2172C7.38395 0 5.07935 2.253 5.07935 5.0228V51.8661C5.07935 54.6359 7.38395 56.8889 10.2172 56.8889H16.2539V59.1568C16.2539 61.8274 18.4702 64 21.1954 64H53.9791C56.7043 64 58.9206 61.8274 58.9206 59.1568V13.9861C58.9206 11.3155 56.7043 9.1429 53.9791 9.1429ZM7.11105 51.8661V5.0228C7.11105 3.374 8.50495 2.0318 10.2172 2.0318H44.6398C46.3521 2.0318 47.746 3.374 47.746 5.0228V51.8661C47.746 53.5149 46.3521 54.8572 44.6398 54.8572H10.2172C8.50495 54.8572 7.11105 53.5149 7.11105 51.8661ZM56.8888 59.1568C56.8888 60.7074 55.5833 61.9683 53.9791 61.9683H21.1954C19.5912 61.9683 18.2856 60.7074 18.2856 59.1568V56.8889H44.6398C47.4731 56.8889 49.7777 54.6359 49.7777 51.8661V11.1276C49.8546 11.1462 49.9281 11.1746 50.0108 11.1746H53.9791C55.5833 11.1746 56.8888 12.4355 56.8888 13.9861V59.1568V59.1568Z"
                  fill="black"
                />
                <path
                  d="M38.6031 13.2065H16. 2539C15.6924 13.2065 15.238 13.6608 15.238 14.2223C15.238 14.7839 15.6924 15.2382 16.2539 15.2382H38.6031C39.1646 15.2382 39.619 14.7839 39.619 14.2223C39.619 13.6608 39.1646 13.2065 38.6031 13.2065Z"
                  fill="black"
                />
                <path
                  d="M38.6031 21.3335H16.2539C15.6924 21.3335 15.238 21.7878 15.238 22.3493C15.238 22.9108 15.6924 23.3652 16.2539 23.3652H38.6031C39.1646 23.3652 39.619 22.9108 39.619 22.3493C39.619 21.7878 39.1646 21.3335 38.6031 21.3335Z"
                  fill="black"
                />
                <path
                  d="M38.6031 29.4604H16.2539C15.6924 29.4604 15.238 29.9148 15.238 30.4763C15.238 31.0378 15.6924 31.4922 16.2539 31.4922H38.6031C39.1646 31.4922 39.619 31.0378 39.619 30.4763C39.619 29.9148 39.1646 29.4604 38.6031 29.4604Z"
                  fill="black"
                />
                <path
                  d="M28.4444 37.5874H16.2539C15.6924 37.5874 15.238 38.0418 15.238 38.6033C15.238 39.1648 15.6924 39.6192 16.2539 39.6192H28.4444C29.0059 39.6192 29.4602 39.1648 29.4602 38.6033C29.4602 38.0418 29.0059 37.5874 28.4444 37.5874Z"
                  fill="black"
                />
              </svg>
            </div>

            <p class="sub_title mb-2">Coupon URL</p>
            <div class="input_with_copy gf_flex gf_align-i-center">
              <input
                class="m-0"
                ref="inputCoupon"
                type="text"
                :value="randomCoupon ? couponLink : ''"
                placeholder="Coupon URL"
                disabled
              />

              <svg
                @click="copy(couponLink)"
                width="30"
                height="30"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M53.9791 9.1429H50.0108C49.9281 9.1429 49.8546 9.1713 49.7777 9.1899V5.0228C49.7777 2.253 47.4731 0 44.6398 0H10.2172C7.38395 0 5.07935 2.253 5.07935 5.0228V51.8661C5.07935 54.6359 7.38395 56.8889 10.2172 56.8889H16.2539V59.1568C16.2539 61.8274 18.4702 64 21.1954 64H53.9791C56.7043 64 58.9206 61.8274 58.9206 59.1568V13.9861C58.9206 11.3155 56.7043 9.1429 53.9791 9.1429ZM7.11105 51.8661V5.0228C7.11105 3.374 8.50495 2.0318 10.2172 2.0318H44.6398C46.3521 2.0318 47.746 3.374 47.746 5.0228V51.8661C47.746 53.5149 46.3521 54.8572 44.6398 54.8572H10.2172C8.50495 54.8572 7.11105 53.5149 7.11105 51.8661ZM56.8888 59.1568C56.8888 60.7074 55.5833 61.9683 53.9791 61.9683H21.1954C19.5912 61.9683 18.2856 60.7074 18.2856 59.1568V56.8889H44.6398C47.4731 56.8889 49.7777 54.6359 49.7777 51.8661V11.1276C49.8546 11.1462 49.9281 11.1746 50.0108 11.1746H53.9791C55.5833 11.1746 56.8888 12.4355 56.8888 13.9861V59.1568V59.1568Z"
                  fill="black"
                />
                <path
                  d="M38.6031 13.2065H16. 2539C15.6924 13.2065 15.238 13.6608 15.238 14.2223C15.238 14.7839 15.6924 15.2382 16.2539 15.2382H38.6031C39.1646 15.2382 39.619 14.7839 39.619 14.2223C39.619 13.6608 39.1646 13.2065 38.6031 13.2065Z"
                  fill="black"
                />
                <path
                  d="M38.6031 21.3335H16.2539C15.6924 21.3335 15.238 21.7878 15.238 22.3493C15.238 22.9108 15.6924 23.3652 16.2539 23.3652H38.6031C39.1646 23.3652 39.619 22.9108 39.619 22.3493C39.619 21.7878 39.1646 21.3335 38.6031 21.3335Z"
                  fill="black"
                />
                <path
                  d="M38.6031 29.4604H16.2539C15.6924 29.4604 15.238 29.9148 15.238 30.4763C15.238 31.0378 15.6924 31.4922 16.2539 31.4922H38.6031C39.1646 31.4922 39.619 31.0378 39.619 30.4763C39.619 29.9148 39.1646 29.4604 38.6031 29.4604Z"
                  fill="black"
                />
                <path
                  d="M28.4444 37.5874H16.2539C15.6924 37.5874 15.238 38.0418 15.238 38.6033C15.238 39.1648 15.6924 39.6192 16.2539 39.6192H28.4444C29.0059 39.6192 29.4602 39.1648 29.4602 38.6033C29.4602 38.0418 29.0059 37.5874 28.4444 37.5874Z"
                  fill="black"
                />
              </svg>
            </div>

            <p v-if="notifyCopy" class="notify success">Copied!</p>
            <!-- <p class="warn" v-if="!isCouponAvailable">Oops, we're run out of coupon.</p> -->

            <hr />

            <VBtn
              color="primary"
              block
              :disabled="
                campaignDetailItem && campaignDetailItem.status == 'disable'
              "
              type="submit"
            >
              Get coupon
            </VBtn>
          </form>
        </div>

        <hr class="hr_low" />
        <div class="gf_flex gf_justify-c-between">
          <VBtn color="grey" type="button" @click="backToPromo('/promotions')">
            Cancel
          </VBtn>
          <VBtn color="success" @click="updateCampaign" type="button"
            >Save campaign</VBtn
          >
        </div>
      </div>

      <!-- Modal -->
      <Modal
        v-if="itemDelete"
        :hideExit="true"
        bClass="campaign-delete"
        mClass
        hClass="gf_flex gf_flex-wrap"
        @cancel="closeModalDelete"
      >
        <template v-slot:default>
          <div class="gf_col-md-12 confirmModal">
            <h3>Delete {{ itemDelete.name }}</h3>
            <hr />
            <h4 class="lh-20">
              Are you sure you want to delete the campaign
              <span class="strong">{{ itemDelete.name }}</span
              >? This can’t be undone.
            </h4>
            <hr />
            <div class="gf_btn-group gf_mt-20 gf_flex gf_justify-c-end">
              <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
                Cancel
              </VBtn>
              <VBtn color="red" class="ml-2 white--text" @click="doDelete">
                <template v-if="deleting">Deleting</template>
                <template v-else>Delete</template>
              </VBtn>
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Campaign from "./Campaign";
import DatePicker from "vue2-datepicker";
import promotionAPI from "@/api/promotion.js";
let _timeoutNotify = null;
import { appUrl } from "@/configs/";

export default {
  name: "CampaignDetail",
  components: {
    DatePicker,
    Campaign
  },
  data() {
    return {
      campaignDetailId: this.$route.params.id,
      shops: [],
      start_time: "",
      end_time: "",
      domain: "",
      types: "discount",
      applyTo: "all",
      c_name: "",
      c_prefix: "",
      condition_value: "",
      condition_plan: "professional",
      selectDateRange: true,
      unit: "%",
      revertPlan: "starter",
      date: "",
      oneTimeCoupon: false,
      coupon_limit: "",
      coupon_added: "0",
      duration: "",
      last_limit: "",
      showInput: false,
      itemDelete: undefined,
      shopDomain: null,
      couponSent: 0,
      couponUsed: 0,
      couponNew: 0,
      notifyCopy: false,
      error: null,
      couponType: "multiple",
      randomize: true,
      url_tracking: "",
      limit_used: "",
      coupon_shops: ""
    };
  },
  computed: {
    ...mapState({
      campaignDetailItem: state => state.promotion.currentCampaign,
      randomCoupon: state => state.promotion.randomCoupon
    }),

    apiHost() {
      return localStorage._GEM_API_HOST;
    },

    couponLink() {
      if (this.randomCoupon && this.randomCoupon.code) {
        return appUrl() + "/coupons/" + this.randomCoupon.code;
      }
      return "";
    },

    discountFixed() {
      this.unit = "$";
      if (this.condition_plan == "professional") {
        this.revertPlan = "starter";
        this.condition_value = "10";
        return this.condition_value + "$";
      }

      if (this.condition_plan == "advanced") {
        if (this.revertPlan == "starter") {
          this.condition_value = "30";
          return this.condition_value + "$";
        } else if (this.revertPlan == "professional") {
          this.condition_value = "20";
          return this.condition_value + "$";
        }
      }
    },

    isCouponAvailable() {
      return this.couponSent + this.couponUsed != this.coupon_limit;
    },

    durationOption() {
      return [
        {
          value: "",
          text: "Life Time"
        },
        {
          value: 1,
          text: "1 month"
        },
        {
          value: 2,
          text: "2 month"
        },
        {
          value: 3,
          text: "3 month"
        },
        {
          value: 4,
          text: "4 month"
        },
        {
          value: 5,
          text: "5 month"
        },
        {
          value: 6,
          text: "6 month"
        },
        {
          value: 7,
          text: "7 month"
        },
        {
          value: 8,
          text: "8 month"
        },
        {
          value: 9,
          text: "9 month"
        },
        {
          value: 10,
          text: "10 month"
        },
        {
          value: 11,
          text: "11 month"
        },
        {
          value: 12,
          text: "12 month"
        }
      ];
    },
    tableHeaders() {
      const output = [
        {
          text: "#",
          sortable: true,
          value: "no",
          width: 100
        },
        {
          text: "Shopify domain",
          sortable: true,
          value: "shopifyDomain"
        },
        {
          text: "Plan",
          sortable: true,
          value: "applied_plan"
        },
        {
          text: "Applied Date",
          sortable: true,
          value: "applied_date"
        },
      ];

      return output;
    },
    couponShopData() {
      return this.coupon_shops.map((item, index) => {
        return {
          no: index + 1,
          shopifyDomain: item.shopify_domain,
          applied_plan: item.applied_plan ? item.applied_plan.toUpperCase() : '',
          applied_date: this.formatDate(item.created_at)
        }
      })
    }
  },
  methods: {
    ...mapMutations({
      ChangeLoading: "ChangeLoading",
      OpenSuccess: "OpenSuccess",
      OpenError: "OpenError",
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      setLastCampaign: "CAMPAIGN_SET_LASTCAMPAIGN",
      setRandomCoupon: "CAMPAIGN_PICK_COUPON"
    }),
    ...mapActions({
      fetchCampaignDetail: "fetchCampaignDetail",
      deleteCampaignAction: "deleteCampaign",
      pickCouponAction: "pickCouponAction"
    }),
    updateCampaign() {
      this.updateDateTime();

      var body = {
        wrapper: {
          name: this.c_name,
          plan:
            this.types == "discount"
              ? this.applyTo
              : this.types == "extend-trial"
              ? "trial"
              : this.condition_plan,
          kind: this.types,
          prefix: this.c_prefix,
          discount: this.condition_value,
          unit: this.types == "extend-trial" ? "day" : this.unit,
          revert: this.types == "extend-trial" ? "" : this.revertPlan,
          status: "enable",
          start_time: this.start_time,
          end_time: this.end_time,
          last_limit: this.coupon_added,
          duration: this.duration,
          type_coupon: this.couponType,
          url_tracking: this.url_tracking,
          limit_used: this.limit_used
        }
      };

      this.OpenLoading();

      promotionAPI
        .update(body, this.campaignDetailId)
        .then(res => {
          // console.log(res.data)
          if (res.data.data) {
            // this.CloseLoading();

            if (this.coupon_added && this.coupon_added != "0") {
              this.backToPromo("/promotions/detail/" + this.campaignDetailId);
            }
            this.OpenSuccess({ message: "Save successfully", time: 1000 });
          } else {
            // this.CloseLoading();
            this.OpenError({
              message: res.data.message.messages
                ? res.data.message.messages
                : "Save failed",
              time: 1000
            });
          }
        })
        .catch(err => {
          // this.CloseLoading();
        })
        .finally(() => {
          this.CloseLoading();
        });
    },
    deleteCampaign(item) {
      if (!item) return;
      this.itemDelete = item;
    },

    doDelete() {
      this.deleteCampaignAction(this.campaignDetailId);
      this.closeModalDelete();
    },

    closeModalDelete() {
      this.itemDelete = undefined;
    },

    updateDateTime() {
      if (this.date.length > 1) {
        this.start_time = this.date[0];
        this.end_time = this.date[1];
      } else {
        this.start_time = this.date;
      }
    },

    showAddCouponInput() {
      this.showInput = true;
    },

    pickCoupon() {
      this.error = null;
      let regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/;

      if (this.shopDomain && this.shopDomain != "") {
        // // only get domain host from shopDomain input
        // if (!this.shopDomain)
        //   return;
        this.shopDomain.replace(/.*:\/\//, "").replace(/\/.*/, "");
        if (!regex.test(this.shopDomain)) {
          this.error = "Please enter a valid domain!";
          return;
        }
        var regexCheckShopifyDomain = /(?:.*)(?:.myshopify.com)/;
        if (!regexCheckShopifyDomain.test(this.shopDomain)) {
          this.error = "Domain must be in format: 'something.myshopify.com'";
          return;
        }
      }

      //update coupon number + status
      promotionAPI
        .getDetail(this.campaignDetailId)
        .then(res => {
          if (res.data.data) {
            this.couponSent = res.data.data.sent;
            this.couponUsed = res.data.data.used;
            this.couponNew = res.data.data.new;
          } else {
            console.warn(res.data.message);
          }
        })
        .catch(err => {});

      this.pickCouponAction({
        campaignId: this.campaignDetailId,
        shopDomain: this.shopDomain,
        randomize: this.randomize
      });

      //set last campaign to reset random coupon field when change campaign
      this.setLastCampaign(this.campaignDetailItem);
    },

    backToPromo(link) {
      window.location.href = link;
    },

    scrollBottom(position) {
      window.scrollTo(0, position);
    },

    copy(text) {
      // this.$refs.inputCoupon.select();
      // document.execCommand("copy");
      var top = window.pageYOffset || document.documentElement.scrollTop;
      var textArea = document.createElement("input");

      // console.log(top)
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        // console.log(textArea)
        // console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        // console.error('Fallback: Oops, unable to copy', err);
      }

      this.activeNofityCopy();
      document.body.removeChild(textArea);
      this.scrollBottom(top);
    },

    activeNofityCopy() {
      if (_timeoutNotify) {
        clearTimeout(_timeoutNotify);
      }
      this.notifyCopy = true;
      _timeoutNotify = setTimeout(() => {
        this.notifyCopy = false;
      }, 3000);
    },

    formatDate(date) {
      if (date != undefined && date) {
        var newDate = new Date(date);
        return (
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear()
        );
      } else {
        return null;
      }
      return date;
    }
  },
  created() {
    this.fetchCampaignDetail(this.campaignDetailId);
  },
  watch: {
    campaignDetailItem() {
      // this.updateDateTime();
      this.c_name = this.campaignDetailItem.name;
      this.c_prefix = this.campaignDetailItem.prefix;
      this.applyTo = this.campaignDetailItem.plan;
      this.types = this.campaignDetailItem.kind;
      this.condition_value = this.campaignDetailItem.discount;
      this.url_tracking = this.campaignDetailItem.url_tracking;
      (this.unit = this.campaignDetailItem.unit),
        (this.revertToPreviousPlan = true),
        (this.start_time = this.campaignDetailItem.start_time),
        (this.end_time = this.campaignDetailItem.end_time),
        (this.coupon_limit = this.campaignDetailItem.limit);
      this.last_limit = this.campaignDetailItem.last_limit;
      this.couponType = this.campaignDetailItem.type_coupon;
      this.couponSent = this.campaignDetailItem.sent;
      this.couponUsed = this.campaignDetailItem.used;
      this.couponNew = this.campaignDetailItem.new;
      this.duration = this.campaignDetailItem.duration || "";
      this.limit_used = this.campaignDetailItem.limit_used || "";
      this.coupon_shops = this.campaignDetailItem.coupon_shops || "";

      if (this.campaignDetailItem.end_time != null) {
        this.date = [
          this.campaignDetailItem.start_time,
          this.campaignDetailItem.end_time
        ];
      } else {
        this.date = this.campaignDetailItem.start_time;
        this.selectDateRange = false;
      }
    },

    randomCoupon() {
      if (this.randomCoupon && this.$refs.inputCoupon) {
        this.copy(this.couponLink);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main--body--content {
  padding-top: 0;
}

.back-menu {
  height: 50px !important;
  float: none !important;
  a {
    float: left !important;
  }
}

.tab_content {
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .hr_low {
    margin-top: -5px;
  }

  .tab_item {
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    input {
      width: 100%;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
    }

    input[type="radio"],
    input[type="checkbox"] {
      width: auto;
      margin-bottom: 15px;
      margin-right: 10px;
      border: none;
      box-shadow: none;
    }

    .input_value {
      width: 30%;
    }

    .hide {
      display: none;
    }

    .show {
      display: block;
    }

    .sub_title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    a {
      color: #006fbb;

      &:hover {
        color: #084e8a;
      }
    }

    .full-width {
      width: 100%;
    }

    .deletePromotion {
      color: #e6391a;

      &:hover {
        color: #d53417;
      }
    }
  }

  .tab_warning {
    background-color: #ffeaa7;
  }

  .create_segment {
    margin-right: 25px;
    padding-left: 0;
  }

  .summary_segment {
    padding-right: 0;
  }

  .tab_summary {
    min-height: 200px;
    background-color: #f9fafb !important;

    .title_summary {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .category_summary {
      list-style: disc;
      margin-left: 25px;

      li {
        margin-bottom: 5px;
      }
    }
  }

  .campaign_wrapper {
    margin-top: 20px;
  }
}

.input_with_copy {
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;

  &--enable {
    background-color: #ffffff;
  }

  input {
    border: none !important;
    box-shadow: none !important;
  }

  svg {
    cursor: pointer;
    margin-right: 5px;

    border-left: 1px solid #888;
    padding-left: 4px;

    path {
      fill: #888;
    }

    &:hover {
      transition: 0.3s all;
      path {
        fill: #333;
      }
    }
  }
}

.tab_list {
  background-color: #fff;
  padding: 20px;
}

.disable {
  color: #999;
}

.warn {
  font-size: 13px;
  color: #e74c3c;
  margin-top: 10px;
  margin-bottom: -10px;
}

.notify {
  margin-top: 10px;
  margin-bottom: -8px;
  // color: #29d89d;
  // margin-left: 5px;
  font-weight: 900;
  font-size: 13px;

  &.success {
    color: #29d89d;
  }

  &.error {
    margin-top: -10px;
    margin-bottom: 16px;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mr-30 {
  margin-right: 30px;
}
.pl-0 {
  padding-left: 0 !important;
}
</style>

<style lang="scss">
.campaign-delete {
  hr {
    margin: 20px 0;
    height: 1px;
    background: #ebebec;
    border: 1px #ebebec;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  h4 {
    font-weight: 300;
    font-size: 16px;
  }
  .strong {
    font-weight: 600;
  }
  .lh-20 {
    line-height: 20px;
  }
  .confirmModal {
    max-width: 600px;
  }

  .gt_modal-container {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10;
  }
}
</style>
