<template>
  <router-view />
</template>

<script>
// import Cookie from "./utils/cookie.js";
//
// const KEY = "__awHasf8$ar@$";
// const PASS = "gryffindor"
export default {
  methods: {
    // forceLogin() {
    //   let host = window.location.hostname;
    //   let id = Cookie.id();
    //   if (prompt("Mật khẩu là gì?") != "gryffindor") {
    //     location.reload();
    //   } else {
    //     Cookie.set(KEY, id, 0.5);
    //   }
    // }
  },
  created() {
    // let session = Cookie.get(KEY);
    // if (session) {
    //   Cookie.set(KEY, session, 0.5);
    // } else {
    //   this.forceLogin();
    // }
    if (
      window.location.host != "localhost:8080" &&
      window.location.protocol == "http:"
    ) {
      window.location.protocol = "https:";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/reset.scss";
@import "@/assets/flex.scss";
@import "@/assets/grid.scss";
@import "@/assets/theme.scss";
@import "@/assets/style.scss";
@import "./assets/_nprogress.scss";
@import "@/assets/theme/flag.scss";

// body {
// cursor: url("./assets/neon-blue.cur"), auto;
// }
</style>
