import jwt from "jsonwebtoken";
import Cookie from "@/utils/cookie";

const PKEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvn/MRKwNVSb8nX35rx8H
lwmN5yqcwjhF8s2fPglKR4nkmaEZKVMw8TlNzBxSM2/7/cr2tI5C3bTPKXdAajT4
f5kkzFKJ4nmNxIu0EcBCPd84XSzMlopqROgRXE28Z0o47Hnk1Y35ee9I84VmZSuF
YLgoXzVnwWtegZ4IiENBDEG2SIM3a7cAqDtZWdlnOnTNLzqdYWhGTSNFFGYtb1gg
ohhUdy7caM9J4+b9sHiwtKg4I9go1ctFZQ5Ig3fXetfXHAtIYC7aGsiGAr2GtBrr
3/mFG1RLhZ/2cr7zsce7j7mhayluc6jz+sOVkA6teZwGG64hbCJG9f7iHZ33Q/tg
8wIDAQAB
-----END PUBLIC KEY-----`;

export const currentUser = () => {
  return new Promise((resolve, reject) => {
    try {
      let token = Cookie.get(Cookie.key);
      jwt.verify(token, PKEY, { algorithm: "RS256" }, (err, decoded) => {
        if (err) {
          reject("token error");
        }

        resolve(decoded);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const verifyJwt = (sid) => {
  return new Promise((resolve, reject) => {
    if (!sid) {
      reject({ message: "Session not found." });
      return;
    }

    jwt.verify(sid, PKEY, { algorithm: "RS256" }, (err, decoded) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }

      if (decoded.exp > Date.now())
        reject({
          message: "Session expired.",
        });

      resolve(decoded);
    });
  });
};
