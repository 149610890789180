// import arrayUnique from "@/library/arrayUnique.js";
import i18nAPI from "@/api/i18n.js";
import { FETCH } from "../../utils/list";

const defaultKeySource = locale =>
  `https://gempagesv5-test.s3.ap-southeast-1.amazonaws.com/locales/${locale}.json`;

export default {
  namespaced: true,
  state: {
    language: {
      list: [],
      loading: false,
      fetched: false,
      map: {}
    }
  },
  mutations: {
    I18N_FETCH_LANGUAGES(state, { list, reset }) {
      if (!list || !list.length) return;
      let comparer = (obj, item) => {
        return obj.id == item.id;
      };
      if (reset) {
        state.language.list = [];
      }

      FETCH(state.language.list, list, comparer, null);
    },
    I18N_FETCH_LANGUAGE_MAP(state, { locale, data }) {
      state.language.map[locale] = data;
    }
  },
  actions: {
    fetchLanguages(store, force = false) {
      return new Promise(async (resolve, reject) => {
        let state = store.state.language;
        if (state.loading) {
          reject();
          return;
        }
        if (state.fetched && !force) {
          resolve(state.list);
          return;
        }
        state.loading = true;
        try {
          const res = await i18nAPI.list();
          store.commit("I18N_FETCH_LANGUAGES", {
            list: res.data.items,
            reset: true
          });

          state.loading = false;
          if (!state.fetched) {
            state.fetched = true;
          }
          resolve(res.data.items);
        } catch (err) {
          console.error(err);
          state.loading = false;
          reject(err);
        }
      });
    },
    fetchLanguageMap(store, { locale = "en", force = false }) {
      console.log("force: ", force);
      return new Promise(async (resolve, reject) => {
        let state = store.state.language;
        if (state.map[locale] && !force) {
          resolve(state.map[locale]);
          return;
        }
        try {
          const found = state.list.find(x => x.name == locale);
          let url = defaultKeySource("en");
          if (found) {
            url = found.source || defaultKeySource(found.name);
          }
          const res = await i18nAPI.getJSON(url);
          store.commit("I18N_FETCH_LANGUAGE_MAP", { locale, data: res.data });
          resolve(res.data);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    publishLanguages(store, locales) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await i18nAPI.publish({
            locales
          });
          store.commit("I18N_FETCH_LANGUAGES", {
            list: res.data.items,
            reset: false
          });
          resolve(res.data);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    refreshLocales(store) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await i18nAPI.refresh();
          resolve(res.data);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    }
  },
  getters: {
    languageSource(state) {
      if (!state.language || !state.language.list.length) {
        return {};
      }
      const output = {};
      state.language.list.forEach(lang => {
        output[lang.name] = lang.source;
      });
      return output;
    },
    allLocales(state) {
      if (!state.language || !state.language.list.length) {
        return [];
      }
      return state.language.list.map(x => x.name);
    }
  }
};
