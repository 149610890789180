import api from "@/api";
import { catcher } from "@/api/handleError";
import { internalCategory, internalArticle } from "../endpoints";

export default {
  getAllCategories(params) {
    return api()
      .get(internalCategory.list, { query: params })
      .catch(catcher);
  },
  getCategory(categoryId, params) {
    return api()
      .get(internalCategory.get(categoryId), { query: params })
      .catch(catcher);
  },
  createCategory(data) {
    return api()
      .post(internalCategory.create, data)
      .catch(catcher);
  },
  updateCategory(categoryId, data) {
    return api()
      .put(internalCategory.update(categoryId), data)
      .catch(catcher);
  },
  deleteCategory(categoryId) {
    return api()
      .delete(internalCategory.delete(categoryId))
      .catch(catcher);
  },
  getAllArticlesByCategoryId(categoryId, params) {
    return api()
      .get(internalArticle.listByCategoryId(categoryId), { query: params })
      .catch(catcher);
  },
  getAllArticles(params) {
    return api()
      .get(internalArticle.list, { params: params })
      .catch(catcher);
  },
  getArticle(articleId, params) {
    return api()
      .get(internalArticle.get(articleId), { query: params })
      .catch(catcher);
  },
  createArticle(categoryId, data) {
    return api()
      .post(internalArticle.create(categoryId), data)
      .catch(catcher);
  },
  updateArticle(articleId, data) {
    return api()
      .put(internalArticle.update(articleId), data)
      .catch(catcher);
  },
  deleteArticle(articleId) {
    return api()
      .delete(internalArticle.delete(articleId))
      .catch(catcher);
  }
};
