import Vue from "vue";
import Router from "vue-router";
import RouterContainer from "@/components/RouterContainer.vue";
import SnippetList from "@/pages/snippet/Index.vue";
import NotiList from "@/pages/notification/Index.vue";
import TemplateList from "@/pages/template/Index.vue";
import BlockList from "@/pages/block/Index.vue";
import Promotion from "@/pages/shop/Promotion/Promotion.vue";
import Purchase from "@/pages/shop/OneTimePurchase/Index.vue";
import ListPurchase from "@/pages/shop/OneTimePurchase/List.vue";
import Suggestion from "@/pages/shop/Suggestion/Suggestion.vue";
import Blog from "@/pages/shop/Blog/Blog.vue";
import Banner from "@/pages/shop/Banner/Banner.vue";
import SignInPage from "@/layouts/SignIn.vue";
import AuthenPage from "@/layouts/Authenticate.vue";
import SignOutPage from "@/layouts/SignOut.vue";
import Page404 from "@/pages/404.vue";
import Cookie from "@/utils/cookie.js";
import ShowAllCampaigns from "@/pages/shop/Promotion/AllCampaigns.vue";
import GPConfigs from "@/pages/gp_config/Index.vue";
import ShopsIndex from "@/pages/shop/ShopsIndex.vue";
import FileUploader from "@/pages/file-uploader/Index.vue";
import GemMarkdownEditor from "@/pages/markdown-editor/Index.vue";
import Docs from "@/pages/docs/Index.vue";
// import ErrorList from "@/pages/log/ErrorPage.vue";
import store from "@/store/index.js";
import oauth from "@/api/auth.js";
import menu from "@/configs/menu.js";
import FeedbackList from "@/pages/feedback/index.vue";

// import { validateAccess } from "@/utils/roles";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Authenticate",
      path: "/auth",
      component: AuthenPage,
    },
    {
      path: "/",
      component: () => import("@/layouts/Default.vue"),
      children: [
        {
          path: "",
          name: "Root",
          redirect: "dashboard",
        },
        {
          path: "dashboard",
          name: "Dashboard-Index",
          component: () => import("@/pages/index.vue"),
          // beforeEnter(to, from, next) {
          //   if (!to.query.page) {
          //     to.query.page = "1";
          //   }
          //   next(true);
          // }
        },
        {
          path: "snippets",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Snippets",
              component: SnippetList,
            },
            {
              path: "new",
              name: "NewSnippet",
              component: () => import("@/pages/snippet/NewSnippet.vue"),
            },
            {
              path: "edit/:snippet_id",
              name: "EditSnippet",
              component: () => import("@/pages/snippet/EditSnippet.vue"),
            },
          ],
        },
        {
          path: "integrations",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Integrations",
              component: () => import("@/pages/integration/index.vue"),
            },
            {
              path: "new",
              name: "NewIntegration",
              component: () => import("@/pages/integration/NewIntegration.vue"),
            },
            {
              path: ":id",
              name: "EditIntegration",
              component: () =>
                import("@/pages/integration/EditIntegration.vue"),
            },
          ],
        },
        {
          path: "templates",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Templates",
              component: TemplateList,
            },
            {
              path: "new",
              name: "NewTemplate",
              component: () => import("@/pages/template/NewTemplate.vue"),
            },
            {
              path: "edit/:template_id",
              name: "EditTemplate",
              component: () => import("@/pages/template/EditTemplate.vue"),
            },
          ],
        },
        {
          path: "blocks",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Blocks",
              component: BlockList,
            },
            {
              path: "new",
              name: "NewBlock",
              component: () => import("@/pages/block/NewBlock.vue"),
            },
            {
              path: "edit/:block_id",
              name: "EditBlock",
              component: () => import("@/pages/block/EditBlock.vue"),
            },
          ],
        },
        {
          path: "promotions",
          // name: "Promotion",
          component: Promotion,
          children: [
            {
              path: "",
              name: "ShowAllCampaigns",
              component: ShowAllCampaigns,
            },
            {
              path: "create",
              name: "CreateCampaign",
              component: () =>
                import("@/pages/shop/Promotion/CreateCampaign.vue"),
            },
            {
              path: "detail/:id",
              name: "CampaignDetail",
              component: () =>
                import("@/pages/shop/Promotion/CampaignDetail.vue"),
            },
            {
              path: "coupon/:id",
              name: "CouponList",
              component: () => import("@/pages/shop/Promotion/CouponList.vue"),
            },
          ],
        },
        {
          path: "purchase",
          component: Purchase,
          children: [
            {
              path: "",
              name: "ListPurchase",
              component: ListPurchase,
            },
            {
              path: "create",
              name: "CreatePurchase",
              component: () =>
                import("@/pages/shop/OneTimePurchase/CreatePurchase.vue"),
            }
          ],
        },
        {
          path: "notifications",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Notifications",
              component: NotiList,
            },
            {
              path: "new",
              name: "NewNoti",
              component: () => import("@/pages/notification/NewNoti.vue"),
            },
            {
              path: "edit/:noti_id",
              name: "EditNoti",
              component: () => import("@/pages/notification/EditNoti.vue"),
            },
          ],
        },
        {
          path: "suggestion",
          component: Suggestion,
          children: [
            {
              path: "",
              name: "ShowAllSuggestion",
              component: () =>
                import("@/pages/shop/Suggestion/ShowAllSuggestion.vue"),
            },
            {
              path: "create",
              name: "CreateSuggestion",
              component: () =>
                import("@/pages/shop/Suggestion/CreateSuggestion.vue"),
            },
          ],
        },
        {
          path: "blog",
          component: Blog,
          children: [
            {
              path: "",
              name: "ShowAllBlog",
              component: () => import("@/pages/shop/Blog/ShowAllBlog.vue"),
            },
            {
              path: "create",
              name: "CreateBlog",
              component: () => import("@/pages/shop/Blog/CreateBlog.vue"),
            },
          ],
        },
        {
          path: "banner",
          component: Banner,
          children: [
            {
              path: "",
              name: "ShowAllBanner",
              component: () => import("@/pages/shop/Banner/ShowAllBanner.vue"),
            },
            {
              path: "create",
              name: "CreateBanner",
              component: () => import("@/pages/shop/Banner/CreateBanner.vue"),
            },
          ],
        },
        {
          path: "shops",
          component: ShopsIndex,
          children: [
            {
              path: "list",
              name: "ShopList",
              component: () => import("@/pages/shop/analytics/Analytics.vue"),
            },
            {
              path: "dashboardv3",
              name: "DashboardV3",
              component: () => import("@/pages/shop/Extras/DashboardV3.vue"),
            },
            {
              path: "wrong-plan",
              name: "WrongShopPlan",
              component: () => import("@/pages/wrong_plan/Index.vue"),
            },
            {
              path: "fix-gid-duplicated",
              name: "FixGidDuplicated",
              component: () => import("@/pages/fix-gid-duplicated/Index.vue"),
            },
            {
              path: "shopify-app-credit",
              name: "ShopifyAppCredit",
              component: () => import("@/pages/shopify-app-credit/Index.vue"),
            },
            {
              path: "update-gempages-version",
              name: "UpdateGemPagesVersion",
              component: () => import("@/pages/update-gempages-version/Index.vue"),
            },
            {
              path: "update-default-template-metafields",
              name: "UpdateDefaultTemplateMetaFields",
              component: () => import("@/pages/update-default-template-metafields/Index.vue"),
            },
          ],
        },
        {
          path: "testing",
          component: RouterContainer,
          children: [
            ,
            {
              path: "custom_dashboard",
              name: "Testing",
              component: () =>
                import("@/pages/shop/Testing/CustomDashboard.vue"),
            },
          ],
        },
        {
          path: "configs",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "GPConfigs",
              component: GPConfigs,
            },
            {
              path: "new",
              name: "NewGPConfig",
              component: () => import("@/pages/gp_config/NewGPConfig.vue"),
            },
            {
              path: "edit/:config_id",
              name: "EditGPConfig",
              component: () => import("@/pages/gp_config/EditGPConfig.vue"),
            },
            {
              path: "banner/:config_id",
              name: "ListBanner",
              component: () =>
                import("@/pages/gp_config/banner/ListBanner.vue"),
            },
            {
              path: "blog/:config_id",
              name: "ListBlog",
              component: () =>
                import("@/pages/gp_config/blog/ListBlog.vue"),
            },
            {
              path: "faqs/:config_id",
              name: "ListFAQs",
              component: () => import("@/pages/gp_config/faqs/ListFAQs.vue"),
            },
            {
              path: "changelog/:config_id",
              name: "ListChangeLog",
              component: () =>
                import("@/pages/gp_config/changelog/ListChangeLog.vue"),
            },
          ],
        },
        {
          path: "admin",
          component: RouterContainer,
          children: [
            {
              path: "rbac",
              name: "AdminRPM",
              component: () => import("@/pages/admin/index.vue"),
              redirect: () => ({ name: "AdminUserManager" }),
              children: [
                {
                  path: "user",
                  name: "AdminUserManager",
                  component: () => import("@/pages/admin/ManageUser.vue"),
                },
                {
                  path: "policy",
                  name: "AdminPolicyManager",
                  component: () => import("@/pages/admin/ManagePolicy.vue"),
                },
                {
                  path: "role",
                  name: "AdminRoleManager",
                  component: () => import("@/pages/admin/ManageRole.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "logs",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "LogList",
              component: () => import("@/pages/log/Index.vue"),
            },
            // {
            //   path: "new",
            //   name: "NewGPConfig",
            //   component: () => import("@/pages/gp_config/NewGPConfig.vue"),
            // },
            // {
            //   path: "edit/:config_id",
            //   name: "EditGPConfig",
            //   component: () => import("@/pages/gp_config/EditGPConfig.vue"),
            // },
          ],
        },
        {
          path: "languages",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Languages",
              component: () => import("@/pages/languages/index.vue"),
            },
          ],
        },
        {
          path: "file-uploader",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "FileUploader",
              component: FileUploader,
            },
          ],
        },
        {
          path: "markdown-editor",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "GemMarkdownEditor",
              component: GemMarkdownEditor,
            },
          ],
        },
        {
          path: "development-docs",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "Docs",
              component: Docs,
            },
            {
              path: "/categories/new",
              name: "New-Category",
              component: () => import("@/pages/docs/CategoryNew.vue"),
            },
            {
              path: "/categories/:categoryId",
              name: "Edit-Category",
              component: () => import("@/pages/docs/CategoryEdit.vue"),
            },
            {
              path: "/articles/new",
              name: "New-Article",
              component: () => import("@/pages/docs/ArticleNew.vue"),
            },
            {
              path: "/articles/:articleId/edit",
              name: "Edit-Article",
              component: () => import("@/pages/docs/ArticleEdit.vue"),
            },
            {
              path: "/articles/:articleId",
              name: "View-Article",
              component: () => import("@/pages/docs/ArticleView.vue"),
            },
          ],
        },
        {
          path: "feedback",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "feedback",
              component: FeedbackList,
            },
            {
              path: "new",
              name: "NewFeedbackForm",
              component: () =>
                import("@/pages/feedback/UpdateFeedbackForm.vue"),
            },
            {
              path: "edit/:handle",
              name: "EditFeedbackForm",
              component: () =>
                import("@/pages/feedback/UpdateFeedbackForm.vue"),
            },
          ],
        },
      ],
    },
    {
      name: "DeAuthenticate",
      path: "/logout",
      component: SignOutPage,
    },
    {
      name: "Page404",
      path: "/404",
      component: Page404,
    },
    {
      name: "Fallback",
      path: "*",
      redirect: "/404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const rule = menu.find((item) => item.name == to.name);
  // console.log(JSON.parse(JSON.stringify({menu, to, rule})));
  let allowedRoute = ["Authenticate", "DeAuthenticate", "Page404", "Fallback"];
  if (allowedRoute.includes(to.name)) {
    next();
    return;
  }
  let token = Cookie.get(Cookie.key);

  oauth
    .verify(token, from, to)
    .then(async (res) => {
      store.commit("STORE_CURRENT_USER", res);

      try {
        const roles = await store.dispatch("getMyRoles");
        if (to.name == "Dashboard-Index") {
          next();
          return;
        }
        let idx = -1;
        if (rule) {
          if (typeof rule.role == "object") {
            rule.role.forEach((e) => {
              let index = roles.findIndex((r) => r.startsWith(e));
              if (index >= 0) {
                idx = index;
              }
            });
          } else {
            idx = roles.findIndex((r) => r.startsWith(rule.role));
          }
        }
        if (roles.includes("superadmin") || rule.role == "*" || !rule.role) {
          next();
          return;
        }
        // console.log({ roles, rule, idx });
        if (idx < 0) {
          throw `You have no permission to visit ${rule.name}`;
        }
      } catch (err) {
        if (to.name == "Dashboard-Index") {
          next();
          return;
        }
        store.commit("OpenError", {
          message: err,
        });
        next("/");
        return;
      }

      next();
    })
    .catch((err) => {
      //   Cookie.resetAuth();
      Cookie.set(Cookie.key, undefined, -1);
      console.error("error:", err);
      next({
        name: "Authenticate",
      });
    });
});

export default router;
