<template>
  <table class="object-creator" :class="className">
    <thead>
      <th>
        <td>Key</td>
        <td>Value</td>
      </th>
    </thead>
    <tbody>
      <tr :key="index*10+'-'+item" v-for="(item, index) in attrsList">
        <td :class="[{'error': item.error}, {'readonly': readonly}]" >
          <input placeholder="key" @change="calcBackWithCheck(item, index)" @keydown.enter.prevent="calcBackWithCheck(item, index)" v-model="item.key" class="input-key">
        </td>
        <td :class="{'readonly': readonly}">
          <input placeholder="value" @input="calcBackWithCheck(item, index)" @keydown.enter.prevent="calcBackWithCheck(item, index)" v-model="item.value" class="input-value">
        </td>
      </tr>
      <tr>
        <td :class="[{'error': newItem.error}, {'readonly': readonly}]" >
          <input :required="required" :disabled="readonly" placeholder="key" @keydown.enter.prevent v-model="newItem.key" @input="calcBackWithCheck(newItem)" @keyup.enter="createNewAttr" ref="inputNewKey" class="input-key" :title="newItem.error ? `Duplicate key name '${newItem.key}'` : ''">
        </td>
        <td :class="{'readonly': readonly}">
          <input :required="required" :disabled="readonly" placeholder="value" @keydown.enter.prevent v-model="newItem.value" @keyup.enter="createNewAttr" class="input-value">
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ObjectCreator",
  props: {
    value: Object,
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    checkBoolean: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: {

      },
      newItem: {
        key: "",
        value: "",
        error: false
      },
      attrsList: []
    };
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", this.val);
    },
    calcAttrs(newValue) {
      if (newValue == undefined || newValue == null)
        return [];
      // console.log({newValue})
      let keys = Object.keys(newValue);
      return keys.map(key => {
        let value = newValue[key];
        return {
          key,
          value
        };
      });
    },
    calcBack() {
      let newObj = {};
      const checkBoolean = this.checkBoolean;
      this.attrsList.forEach(item => {
        if(checkBoolean) {
          if(item.value == "true" || item.value == "false"){
            item.value = item.value == "true" ? true : (item.value == "false" ? false : "false");
          }
        }
        newObj[item.key] = item.value;
      })
      this.$emit('input', newObj);
    },
    isDuplicated(item, index = -1) {
      for (let i = 0, len = this.attrsList.length; i < len; i++) {
        if (i !== index) {
          let attr = this.attrsList[i];
          if (attr.key == item.key) {
            attr.error = true;
            item.error = true;
            return true;
          }
        }
      }
      return false;
    },
    resetWarn() {
      this.attrsList.forEach(attr => {
        attr.error = undefined;
      });
      this.newItem.error = undefined;
    },
    removeEmpty() {
      let removeList = [];
      this.attrsList.forEach((item, index) => {
        if (item.key.trim() == "") {
          removeList.push(index);
        }
      });
      removeList.sort((a,b) => b - a);
      removeList.forEach(index => {
        this.attrsList.splice(index, 1);
      });
    },
    calcBackWithCheck(item, index) {
      this.removeEmpty();
      this.resetWarn();
      if (this.isDuplicated(item, index)) {
        return;
      }
      this.calcBack();
    },
    createNewAttr() {
      let item = this.newItem;
      if (item.key.length == 0)
        return;
      this.resetWarn();
      if (this.isDuplicated(item))
        return;
      this.val[item.key] = item.value;
      this.attrsList.push({...item});
      this.calcBack();
      this.onInput();
      // this.calcAttrs();
      item.key = "";
      item.value = "";
      this.$refs.inputNewKey.focus();
    },
    reCalc(newV) {
      this.attrsList = this.calcAttrs(newV);
    }
  },
  created() {
    this.val = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
    },
    val(newV, oldV) {
      if (JSON.stringify(newV) == JSON.stringify(oldV))
        return;
      this.reCalc(newV);
    }
  }
};
</script>

<style lang="scss" scoped>
// .object-creator {
  th, tr {
    display: flex;
  }

  th {
    td {
      text-align: center;
      padding: 9px;
      font-weight: bold;
    }
  }

  tr {
    margin-top: 1px;
  }

  td {
    padding: 5px 9px;
    flex: 1;
    box-shadow: 0 0 2px 0 #2C3A47;
    text-align: left;
    &:not(:first-child) {
      margin-left: 1px;
    }

    &.error {
      background-color: rgba(226, 159, 159, 0.6);
    }
  }

  .input-key, .input-value {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;

    &:disabled, &[readonly] {
      background-color: #e6e6e6;
      cursor: not-allowed;
    }
  }
// }
</style>
