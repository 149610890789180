<template>
  <div class="main--body--content">
    <div class="gf_flex button-add-item">
      <VTextField
        class="mr-2"
        outlined
        dense
        type="search"
        placeholder="Search template...."
        v-model="search"
      />
      <router-link class="add" :to="{ name: 'NewGPConfig' }">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New template</span>
        </VBtn>
      </router-link>
    </div>

    <div class="gf_flex gf_flex-wrap" v-if="!fetched && loading">
      <!-- <H1>Fetching</H1> -->
      <Item v-for="index in 10" :key="index" />
      <!-- <center>Loading</center> -->
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="!gp_configs.length">
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>There's still no config in database. Please insert more to view.</h4>
        </div>
        <img class="psyduck" src="../../assets/psyduck.png" />
      </div>
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="list.length">
      <Item :key="i" v-for="(t, i) in list" :item="t" @click="viewDetail" @delete="deleteTemplate" />
      <Pagination :pagina.sync="pagination" :total.sync="pagination.total" @page="ToPage" />
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="!list.length && gp_configs.length > 0">
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>Nothing match your search. Please try another keyword.</h4>
        </div>
        <img class="pikachu" src="../../assets/pika_stand.png" />
      </div>
    </div>

    <!-- Modal -->
    <Modal
      v-if="itemDelete"
      :hideExit="true"
      cClass="gf_padding-20"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModalDelete"
    >
      <template v-slot:default>
        <div class="gf_col-md-12">
          <h3>Are you sure</h3>
          <h4 style="margin: 5px 0 30px 0;">you want to delete {{ itemDelete.label }}?</h4>
          <div class="gf_btn-group gf_mt-20">
            <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
              Cancel
            </VBtn>
            <VBtn color="red" class="ml-2 white--text" @click="doDelete">
              <template v-if="deleting">Deleting</template>
              <template v-else>Delete</template>
            </VBtn>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import api from "@/api/gp_config.js";
import Item from "./Item.vue";
import Pagination from "@/components/essenstials/Pagination.vue";
export default {
  name: "GPConfigs",
  components: {
    Item,
    Pagination
  },
  data() {
    return {
      search: "",
      filterVal: -1,
      showModal: false,
      itemDelete: undefined,
      deleting: false
    };
  },
  computed: {
    ...mapState({
      gp_configs: state => state.gp_config.list,
      limit: state => state.gp_config.limit,
      page: state => state.gp_config.page,
      loading: state => state.gp_config.loading,
      fetched: state => state.gp_config.fetched
    }),
    ...mapGetters({}),
    list() {
      return this.queriedList.filter((item, index) => {
        return (
          index >= (this.page - 1) * this.limit &&
          index < this.page * this.limit
        );
      });
    },
    queriedList() {
      let search = this.search.toLowerCase();
      return this.filtered.filter(item => {
        let name = (item.name || "").toLowerCase();
        if (name.indexOf(search) > -1) return true;
        return false;
      });
    },
    pagination() {
      let total = Math.ceil(this.queriedList.length / this.limit);
      return {
        per: this.limit,
        current: this.page,
        currentraw: this.page,
        total: total
      };
    },
    filtered() {
      // if (this.filterVal == -1) {
      return this.gp_configs;
      // }
      // return this.templates.filter(
      //   (item) => item.category_id == this.filterVal
      // );
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      DeleteTemplate: "TEMPLATE_REMOVE_TEMPLATE",
      ToPage: "TEMPLATE_TO_PAGE"
    }),
    ...mapActions({
      fetchGPConfigs: "fetchGPConfigs"
    }),
    viewDetail(item) {
      switch (item.key) {
        case "banner":
          this.$router.push({
            name: "ListBanner",
            params: { config_id: item.id }
          });
          break;
        case "blog":
          this.$router.push({
            name: "ListBlog",
            params: { config_id: item.id }
          });
          break;
        case "faqs":
          this.$router.push({
            name: "ListFAQs",
            params: { config_id: item.id }
          });
          break;
        case "changelog":
          this.$router.push({
            name: "ListChangeLog",
            params: { config_id: item.id }
          });
          break;
        default:
          this.$router.push({
            name: "EditGPConfig",
            params: { config_id: item.id }
          });
          break;
      }
    },
    deleteTemplate(item) {
      if (!item) return;
      this.itemDelete = item;
    },
    doDelete() {
      this.deleting = true;
      api
        .delete(this.itemDelete.id)
        .then(res => {
          this.deleting = false;
          this.DeleteTemplate(this.itemDelete);
          this.closeModalDelete();
          this.OpenSuccess({ message: "<p>Delete template successfully!</p>" });
        })
        .catch(err => {
          this.deleting = false;
          this.closeModalDelete();
          this.OpenError({ message: err });
        });
    },
    closeModalDelete() {
      this.itemDelete = undefined;
    }
  },
  created() {
    document.title = "Template Management";
    if (!this.fetched) this.fetchGPConfigs();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  }
};
</script>

<style lang="scss">
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
  position: relative;
  background: #d6cba4;
  border-radius: 0.4em;
  padding: 15px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}
</style>
