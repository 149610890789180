import api from "@/api";
import { catcher } from "@/api/handleError";
import { feedback } from "./endpoints";

export default {
  create(data) {
    return api().post(feedback.create, data).catch(catcher);
  },
  list() {
    return api().get(feedback.getAll).catch(catcher);
  },
  get(id) {
    return api().get(feedback.RUD(id)).catch(catcher);
  },
  update(data) {
    return api().post(feedback.create, data).catch(catcher);
  },
  delete(id) {
    return api().delete(feedback.delete(id)).catch(catcher);
  },
  getFeedbackFormTypes() {
    return api().get(feedback.getAll).catch(catcher);
  },

  getFeedbackFormByHandle(handle) {
    return api().get(feedback.getFeedbackByHandle(handle)).catch(catcher);
  },
};
