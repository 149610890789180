<template>
  <div class="campaign_item">
    <div class="item_wrapper gf_flex gf_align-i-center">
      <div
        class="item time-left"
        :class="{ disabled: timeLeft.disable }"
        :title="timeLeft.title"
      >
        <span>{{ timeLeft.text }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="47px"
        >
          <path
            fill="currentColor"
            d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"
          />
        </svg>
      </div>

      <div class="item item_title">
        <h2 class="mb-2 title">
          {{ campaign_item.name }}
          <span
            class="green-dot"
            v-if="campaign_item.status == 'enable'"
            title="This plan is currently activated."
          ></span>
        </h2>
        <p>{{ campaign_item.prefix }}</p>
      </div>

      <div class="not_name gf_flex gf_justify-c-around">
        <div class="item fix-200">
          <p class="mb-1">
            <span class="highlight">Discount:</span>
            {{ campaign_item.discount }}{{ campaign_item.unit }}
          </p>
          <p class="mb-0">
            <span class="highlight">Plan:</span>
            {{ cap(campaign_item.plan) }}
          </p>
        </div>

        <div class="item">
          <p class="mb-1">
            <span class="highlight">Start:</span>
            {{ formatDate(campaign_item.start_time) }}
          </p>
          <p class="mb-0">
            <span class="highlight">End:</span>
            {{ formatDate(campaign_item.end_time) }}
          </p>
        </div>

        <div class="item">
          <VBtn
            color="accent"
            @click.prevent="showGenerateCoupon"
            type="button"
            :disabled="status == 'disable'"
          >
            Get coupon
          </VBtn>
        </div>
        <div class="item actions">
          <VBtn
            color="error"
            v-if="status == 'enable'"
            @click.prevent="changeStatus('disable')"
            type="button"
          >
            Disable
          </VBtn>
          <VBtn
            color="success"
            v-else
            @click.prevent="changeStatus('enable')"
            type="button"
          >
            Activate
          </VBtn>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <Modal
      v-if="isShowModal"
      :hideExit="true"
      bClass="campaign-delete"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModalDelete"
    >
      <template v-slot:default>
        <div class="gf_col-md-12 confirmModal">
          <h3>Generate coupon for {{ campaign_item.name }}</h3>
          <hr />
          <!-- <h4 class="lh-20">Are you sure you want to delete the campaign <span class="strong">{{itemDelete.name}}</span>? This can’t be undone.</h4> -->
          <p class="sub_title">Shop domain (Optional)</p>
          <VTextField
            dense
            outlined
            type="text"
            class="fullWidth"
            v-model="shopDomain"
            placeholder="Shop domain"
          />
          <p class="warn" v-if="messageWrongFormat">
            Domain must be in format : 'something.myshopify.com'
          </p>
          <hr />
          <p class="sub_title">Coupon</p>
          <div class="gf_flex gf_align-i-center">
            <div class="input_with_copy gf_flex gf_align-i-center">
              <input
                ref="inputCoupon"
                type="text"
                :value="randomCoupon ? randomCoupon.code : ''"
                placeholder="Coupon"
                disabled
              />

              <svg
                @click="copy(randomCoupon.code)"
                width="30"
                height="30"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M53.9791 9.1429H50.0108C49.9281 9.1429 49.8546 9.1713 49.7777 9.1899V5.0228C49.7777 2.253 47.4731 0 44.6398 0H10.2172C7.38395 0 5.07935 2.253 5.07935 5.0228V51.8661C5.07935 54.6359 7.38395 56.8889 10.2172 56.8889H16.2539V59.1568C16.2539 61.8274 18.4702 64 21.1954 64H53.9791C56.7043 64 58.9206 61.8274 58.9206 59.1568V13.9861C58.9206 11.3155 56.7043 9.1429 53.9791 9.1429ZM7.11105 51.8661V5.0228C7.11105 3.374 8.50495 2.0318 10.2172 2.0318H44.6398C46.3521 2.0318 47.746 3.374 47.746 5.0228V51.8661C47.746 53.5149 46.3521 54.8572 44.6398 54.8572H10.2172C8.50495 54.8572 7.11105 53.5149 7.11105 51.8661ZM56.8888 59.1568C56.8888 60.7074 55.5833 61.9683 53.9791 61.9683H21.1954C19.5912 61.9683 18.2856 60.7074 18.2856 59.1568V56.8889H44.6398C47.4731 56.8889 49.7777 54.6359 49.7777 51.8661V11.1276C49.8546 11.1462 49.9281 11.1746 50.0108 11.1746H53.9791C55.5833 11.1746 56.8888 12.4355 56.8888 13.9861V59.1568V59.1568Z"
                  fill="black"
                />
                <path
                  d="M38.6031 13.2065H16. 2539C15.6924 13.2065 15.238 13.6608 15.238 14.2223C15.238 14.7839 15.6924 15.2382 16.2539 15.2382H38.6031C39.1646 15.2382 39.619 14.7839 39.619 14.2223C39.619 13.6608 39.1646 13.2065 38.6031 13.2065Z"
                  fill="black"
                />
                <path
                  d="M38.6031 21.3335H16.2539C15.6924 21.3335 15.238 21.7878 15.238 22.3493C15.238 22.9108 15.6924 23.3652 16.2539 23.3652H38.6031C39.1646 23.3652 39.619 22.9108 39.619 22.3493C39.619 21.7878 39.1646 21.3335 38.6031 21.3335Z"
                  fill="black"
                />
                <path
                  d="M38.6031 29.4604H16.2539C15.6924 29.4604 15.238 29.9148 15.238 30.4763C15.238 31.0378 15.6924 31.4922 16.2539 31.4922H38.6031C39.1646 31.4922 39.619 31.0378 39.619 30.4763C39.619 29.9148 39.1646 29.4604 38.6031 29.4604Z"
                  fill="black"
                />
                <path
                  d="M28.4444 37.5874H16.2539C15.6924 37.5874 15.238 38.0418 15.238 38.6033C15.238 39.1648 15.6924 39.6192 16.2539 39.6192H28.4444C29.0059 39.6192 29.4602 39.1648 29.4602 38.6033C29.4602 38.0418 29.0059 37.5874 28.4444 37.5874Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="couponStat">
              <p>All: {{ couponSent }}</p>
              <hr class="hr_low" />
              <p>Used: {{ couponUsed }}</p>
            </div>
          </div>
          <p class="sub_title gf_mt-20 mb-2">Coupon URL</p>
          <div class="input_with_copy gf_flex gf_align-i-center">
            <input
              class="m-0"
              ref="inputCoupon"
              type="text"
              :value="randomCoupon ? couponLink : ''"
              placeholder="Coupon URL"
              disabled
            />

            <svg
              @click="copy(couponLink)"
              width="30"
              height="30"
              viewBox="0 0 64 64"
              fill="none"
            >
              <path
                d="M53.9791 9.1429H50.0108C49.9281 9.1429 49.8546 9.1713 49.7777 9.1899V5.0228C49.7777 2.253 47.4731 0 44.6398 0H10.2172C7.38395 0 5.07935 2.253 5.07935 5.0228V51.8661C5.07935 54.6359 7.38395 56.8889 10.2172 56.8889H16.2539V59.1568C16.2539 61.8274 18.4702 64 21.1954 64H53.9791C56.7043 64 58.9206 61.8274 58.9206 59.1568V13.9861C58.9206 11.3155 56.7043 9.1429 53.9791 9.1429ZM7.11105 51.8661V5.0228C7.11105 3.374 8.50495 2.0318 10.2172 2.0318H44.6398C46.3521 2.0318 47.746 3.374 47.746 5.0228V51.8661C47.746 53.5149 46.3521 54.8572 44.6398 54.8572H10.2172C8.50495 54.8572 7.11105 53.5149 7.11105 51.8661ZM56.8888 59.1568C56.8888 60.7074 55.5833 61.9683 53.9791 61.9683H21.1954C19.5912 61.9683 18.2856 60.7074 18.2856 59.1568V56.8889H44.6398C47.4731 56.8889 49.7777 54.6359 49.7777 51.8661V11.1276C49.8546 11.1462 49.9281 11.1746 50.0108 11.1746H53.9791C55.5833 11.1746 56.8888 12.4355 56.8888 13.9861V59.1568V59.1568Z"
                fill="black"
              />
              <path
                d="M38.6031 13.2065H16. 2539C15.6924 13.2065 15.238 13.6608 15.238 14.2223C15.238 14.7839 15.6924 15.2382 16.2539 15.2382H38.6031C39.1646 15.2382 39.619 14.7839 39.619 14.2223C39.619 13.6608 39.1646 13.2065 38.6031 13.2065Z"
                fill="black"
              />
              <path
                d="M38.6031 21.3335H16.2539C15.6924 21.3335 15.238 21.7878 15.238 22.3493C15.238 22.9108 15.6924 23.3652 16.2539 23.3652H38.6031C39.1646 23.3652 39.619 22.9108 39.619 22.3493C39.619 21.7878 39.1646 21.3335 38.6031 21.3335Z"
                fill="black"
              />
              <path
                d="M38.6031 29.4604H16.2539C15.6924 29.4604 15.238 29.9148 15.238 30.4763C15.238 31.0378 15.6924 31.4922 16.2539 31.4922H38.6031C39.1646 31.4922 39.619 31.0378 39.619 30.4763C39.619 29.9148 39.1646 29.4604 38.6031 29.4604Z"
                fill="black"
              />
              <path
                d="M28.4444 37.5874H16.2539C15.6924 37.5874 15.238 38.0418 15.238 38.6033C15.238 39.1648 15.6924 39.6192 16.2539 39.6192H28.4444C29.0059 39.6192 29.4602 39.1648 29.4602 38.6033C29.4602 38.0418 29.0059 37.5874 28.4444 37.5874Z"
                fill="black"
              />
            </svg>
          </div>
          <p v-if="notifyCopy" class="notify">Copied!</p>
          <hr />
          <div class="gf_btn-group gf_mt-20 gf_flex gf_justify-c-end">
            <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
              Cancel
            </VBtn>
            <VBtn color="red" class="ml-2 white--text" @click="generateCoupon">
              Generate coupon
            </VBtn>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import promotionAPI from "@/api/promotion.js";
import timeago from "@/plugins/timeago";
let _timeoutNotify = null;

export default {
  name: "Campaign",
  props: {
    campaign_item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowModal: false,
      shopDomain: "",
      couponSent: 0,
      couponUsed: 0,
      couponNew: 0,
      couponLimit: 0,
      status: this.campaign_item.status,
      notifyCopy: false,
      messageWrongFormat: false,
    };
  },
  computed: {
    ...mapState({
      randomCoupon: (state) => state.promotion.randomCoupon,
    }),
    apiHost() {
      return localStorage._GEM_API_HOST;
    },

    couponLink() {
      if (this.randomCoupon && this.randomCoupon.code) {
        return this.apiHost + "/coupons/" + this.randomCoupon.code;
      }
      return "";
    },
    timeLeft() {
      if (!this.campaign_item) {
        return {
          disable: true,
          text: "",
        };
      } else {
        let endDate = new Date(this.campaign_item.end_time);
        let today = new Date();
        // Check if campaign is ended.
        if (endDate <= today) {
          return {
            disable: true,
            text: "end",
            title: "This campaign is ended.",
          };
        }
        let tLeft = endDate - today;
        let tAgo = timeago(today - tLeft, "en");
        tAgo = tAgo.split(" ");
        let text = `${tAgo[0]}${tAgo[1][0]}`;
        let disable = !(Number.parseFloat(tAgo[0]) > 0);
        let title = `Only ${tAgo[0]} ${tAgo[1]} left.`;
        return {
          disable,
          text,
          title,
        };
      }
    },
  },
  methods: {
    ...mapActions({
      pickCouponAction: "pickCouponAction",
    }),

    formatDate(date) {
      // console.log(date)
      if (date != undefined && date) {
        var newDate = new Date(date);
        return (
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear()
        );
      } else {
        return null;
      }
      return date;
    },

    cap(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    changeStatus(status) {
      var body = {
        wrapper: {
          status: status,
        },
      };
      promotionAPI
        .changeStatusCampaign(this.campaign_item.id, body)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.data) {
            this.status = res.data.data.status;
          }
        });
    },

    updateCouponAmount() {
      //update coupon number + status
      promotionAPI
        .getDetail(this.campaign_item.id)
        .then((res) => {
          if (res.data.data) {
            this.couponSent = res.data.data.sent;
            this.couponUsed = res.data.data.used;
            this.couponNew = res.data.data.new;
            this.couponLimit = res.data.data.limit;
          } else {
            console.warn(res.data.message);
          }
        })
        .catch((err) => {});
    },

    generateCoupon() {
      var regexCheckShopifyDomain = new RegExp("(?:.*)(?:.myshopify.com)");

      if (this.shopDomain) {
        if (regexCheckShopifyDomain.test(this.shopDomain)) {
          this.pickCouponAction({
            campaignId: this.campaign_item.id,
            shopDomain: this.shopDomain,
            showLoading: "none",
          });
        } else {
          this.activeMessageWrongFormat();
        }
      } else {
        this.pickCouponAction({
          campaignId: this.campaign_item.id,
          shopDomain: "",
          showLoading: "none",
        });
      }
    },

    showGenerateCoupon() {
      this.isShowModal = true;
    },

    closeModalDelete() {
      this.isShowModal = false;
    },

    copy(text) {
      // this.$refs.inputCoupon.select();
      // document.execCommand("copy");

      var textArea = document.createElement("input");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        // console.log(textArea)
        // console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        // console.error('Fallback: Oops, unable to copy', err);
      }

      this.activeNofityCopy();
      document.body.removeChild(textArea);
    },

    activeNofityCopy() {
      if (_timeoutNotify) {
        clearTimeout(_timeoutNotify);
      }
      this.notifyCopy = true;
      _timeoutNotify = setTimeout(() => {
        this.notifyCopy = false;
      }, 3000);
    },

    activeMessageWrongFormat() {
      this.messageWrongFormat = true;
      setTimeout(() => {
        this.messageWrongFormat = false;
      }, 3000);
    },
  },
  created() {
    this.updateCouponAmount();
  },
  watch: {
    randomCoupon() {
      this.copy(this.randomCoupon.code);
      this.updateCouponAmount();
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign_item {
  width: 100%;
  /* padding: 16px; */
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .title {
    // padding: 16px;
    font-weight: 600;
    // background: linear-gradient(180deg, #6371c7, #5563c1);
  }

  .item_wrapper {
    padding: 16px;

    .item {
      // min-width: 400px;
      overflow: hidden;

      &_title {
        min-width: 350px;
      }

      &.actions {
        button {
          min-width: 92px;
        }
      }
    }

    .fix-200 {
      width: 200px;
    }

    .not_name {
      width: 100%;
    }
  }

  &:hover {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    transition: 0.3s all;
    cursor: pointer;
  }

  .time-left {
    display: flex;
    order: -1;
    margin-right: 15px;
    position: relative;
    overflow: unset !important;
    span {
      position: absolute;
      bottom: 11px;
      font-weight: bold;
      font-size: 13px;
      left: -4px;
    }

    svg {
      color: #27ae60;
    }

    &.disabled {
      svg {
        color: #999;
      }
      span {
        color: #999;
      }
    }
  }

  .green-dot {
    display: inline-block;
    $size: 15px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: #2ecc71;
    margin-left: 10px;
  }
}

input {
  margin: 0;
}

.input_with_copy {
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;

  input {
    border: none;
    box-shadow: none;
    flex: 1;
  }

  svg {
    cursor: pointer;
    margin-right: 5px;

    border-left: 1px solid #888;
    padding-left: 4px;

    path {
      fill: #888;
    }

    &:hover {
      transition: 0.3s all;
      path {
        fill: #333;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.hr_low {
  margin: 10px 0;
}

.sub_title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px !important;
}

.warn {
  font-size: 12px;
  color: #e74c3c;
  margin-top: 10px;
  margin-bottom: -10px;
}

.couponStat {
  font-size: 14px;
  margin-left: 25px;
  font-weight: 800;

  p {
    font-weight: 700px;
    color: #29d89d;
  }

  p:first-child {
    margin-bottom: 5px;
    color: #547aff;
  }
}

.notify {
  margin-top: 5px;
  margin-bottom: -8px;
  color: #29d89d;
  margin-left: 5px;
  font-weight: 900;
  font-size: 13px;
}

.highlight {
  color: #5563c1;
}
</style>
