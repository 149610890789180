// import arrayUnique from "@/library/arrayUnique.js";
import templateAPI from "@/api/template.js";
import gpConfigAPI from "@/api/gp_config.js";
const convertDataRcm = function (data) {
  if (data && data.length > 0) {
    return data;
  } else {
    return [null,null,null,null]
  }
}

export default {
  state: {
    recommendTemplates: {},
    previewTemplates: {},
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,
  },
  mutations: {
    TEMPLATE_TO_PAGE(state, page) {
      state.page = page;
    },
    TEMPLATE_ADD_TEMPLATE(state, template) {
      let index = state.list.findIndex(item => item.id == template.id);
      if (index > -1)
        state.list.splice(index, 1, template);
      else
        state.list.push(template);
    },
    TEMPLATE_REMOVE_TEMPLATE(state, template) {
      let index = state.list.findIndex(item => item.id == template.id);
      if (index > -1)
        state.list.splice(index, 1);
    },
    TEMPLATE_FETCH_TEMPLATES(state, list) {
      if (!list || !list.length) return;
      state.list = [];
      list.forEach(item => {
        state.list.push(item);
      });
    },
    RECOMMEND_TEMPLATES(state, data) {
      if (!data) return;
      state.recommendTemplates = data;
      let parseData = JSON.parse(data.value);
      let dataValue = {
        "all": convertDataRcm(parseData.all),
        "landingpages": convertDataRcm(parseData.landingpages),
        "homepages": convertDataRcm(parseData.homepages),
        "articlepages": convertDataRcm(parseData.articlepages),
        "collectionpages": convertDataRcm(parseData.collectionpages),
        "productpages": convertDataRcm(parseData.productpages)
      }
      state.recommendTemplates.value = dataValue;
      return;
    },
    PREVIEW_TEMPLATES(state, data) {
      if (!data) return;
      state.previewTemplates = data;
      let parseData = JSON.parse(data.value);
      state.previewTemplates.value = parseData;
      return;
    }
  },
  actions: {
    fetchTemplates(store) {
      let state = store.state;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      templateAPI
        .getAll()
        // .getPage(state.page)
        .then(res => {
          store.commit("TEMPLATE_FETCH_TEMPLATES", res.data.objects);
          store.commit("RECOMMEND_TEMPLATES", res.data.recommendTemplates);
          store.commit("PREVIEW_TEMPLATES", res.data.previewTemplates);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    updateRecmTemplate(store, { data, onSuccess, onError }) {
      let infoRecmTemplate = store.state.recommendTemplates;
      let dataPayload = JSON.parse(JSON.stringify(infoRecmTemplate));
      dataPayload.value = JSON.stringify(data);
      const dataRecmPayload = {
        wrapper: dataPayload
      }
      gpConfigAPI
        .update(infoRecmTemplate.id, dataRecmPayload)
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          if (onError) onError(err);
        });
    },
    updatePreviewTemplate(store, { data, onSuccess, onError }) {
      let infoRecmTemplate = store.state.previewTemplates;
      let dataPayload = JSON.parse(JSON.stringify(infoRecmTemplate));
      dataPayload.value = JSON.stringify(data);
      const dataRecmPayload = {
        wrapper: dataPayload
      }
      gpConfigAPI
        .update(infoRecmTemplate.id, dataRecmPayload)
        .then((res) => {
          store.commit("PREVIEW_TEMPLATES", res.data.data);
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          if (onError) onError(err);
        });
    }
  },
  getters: {}
};