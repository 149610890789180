import axios from "axios";
import store from "@/store/index.js";
import { catcher } from "@/api/handleError";
import { util } from "./endpoints";
import { baseUrl, getAppMode } from "@/configs";
import Cookie from "@/utils/cookie.js";

function serverUrl() {
  let appMode = getAppMode();
  if (appMode == "dev") {
    return "https://gemtickets-staging.herokuapp.com";
  }

  return "https://gemtickets.herokuapp.com"
}

var settings = {
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + Cookie.get(Cookie.key),
    "G-Server-Domain": serverUrl(),
  },
};

let http = axios.create(settings);

// On request
http.interceptors.request.use(
  (config) => {
    // console.log(config)
    store.commit("loader/START_LOADING");
    return config;
  },
  (error) => {
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);

// On response
http.interceptors.response.use(
  (response) => {
    store.commit("loader/FINISH_LOADING");
    return response;
  },
  (error) => {
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);


export default {
  uploadImage(data, config) {
    http.defaults.headers["Authorization"] = "Bearer " + Cookie.get(Cookie.key);
    return http.post(util.uploadImage, data, config).catch(catcher);
  }
};
