<template>
  <VContainer class="main--body--content">
    <VRow>
      <VCol cols="8">
        <VToolbar dense elevation="0">
          <VTextField
            outlined
            dense
            hide-details
            prepend-inner-icon="fa-search"
            single-line
            placeholder="Search by title, description or content article"
            @input="onSearch"
            v-model="search"
          ></VTextField>
        </VToolbar>
        <VSubheader v-if="search"
          >Found: {{ articles.length }} articles</VSubheader
        >
      </VCol>
      <VCol cols="2">
        <VBtn
          :to="{ name: 'New-Category' }"
          class="ml-auto"
          depressed
          color="primary"
        >
          <VIcon left dark>
            fa-plus
          </VIcon>
          New Category
        </VBtn>
      </VCol>
      <VCol cols="2">
        <VBtn
          :to="{ name: 'New-Article' }"
          class="ml-auto"
          depressed
          color="primary"
        >
          <VIcon left dark>
            fa-plus
          </VIcon>
          New Article
        </VBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12" class="py-2">
        <VBtnToggle v-model="expandAll" tile color="deep-purple accent-3" group>
          <VBtn @click="doCollapseAll">
            Collapse all
          </VBtn>

          <VBtn @click="$refs.tree.updateAll(true)">
            Expand all
          </VBtn>
        </VBtnToggle>
      </VCol>
    </VRow>
    <VRow
      v-if="!['localhost:8080', 'admin.gempages.net'].includes(currentDomain)"
    >
      <VCol cols="12">
        <v-alert outlined type="warning" prominent border="left">
          You are using GemPages Admin Staging server, please go to
          <a target="_blank" href="https://admin.gempages.net/development-docs"
            >https://admin.gempages.net/development-docs</a
          >
          to see the docs
        </v-alert>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VTreeview
          ref="tree"
          hoverable
          v-if="!loading"
          item-text="title"
          :items="getCategoriesTree"
        >
          <template #prepend="{item, open}">
            <VIcon v-if="item.type == 'category'">
              {{ open ? "fa-folder-open" : "fa-folder" }}
            </VIcon>
            <VIcon v-else>
              fa-file
            </VIcon>
          </template>
          <template #label="{ item }">
            <router-link
              v-if="item.type == 'article'"
              :to="{
                name: 'View-Article',
                params: { articleId: item.id }
              }"
            >
              {{ item.title }}
              <VIcon class="ml-1">fa-eye</VIcon>
            </router-link>
            <template v-else>
              {{ item.title }}
            </template>
          </template>
          <template #append="{ item }" v-if="hasEditRole">
            <template v-if="item.type == 'category'">
              <VTooltip bottom>
                <template #activator="{ on, attrs }">
                  <VBtn
                    :to="{
                      name: 'New-Article',
                      query: { categoryId: item.id }
                    }"
                    class="mx-3"
                    fab
                    dark
                    x-small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <VIcon dark> fa-plus </VIcon>
                  </VBtn>
                </template>
                <span>Add new article on this category</span>
              </VTooltip>

              <VTooltip bottom>
                <template #activator="{ on, attrs }">
                  <VBtn
                    :to="{
                      name: 'Edit-Category',
                      params: { categoryId: item.id }
                    }"
                    class="mx-3"
                    fab
                    dark
                    x-small
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <VIcon dark> fa-edit </VIcon>
                  </VBtn>
                </template>
                <span>Edit the category</span>
              </VTooltip>

              <VTooltip bottom>
                <template #activator="{ on, attrs }">
                  <VBtn
                    class="mx-3"
                    fab
                    dark
                    x-small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="onDelete(item)"
                  >
                    <VIcon dark> fa-trash </VIcon>
                  </VBtn>
                </template>
                <span>Delete the category</span>
              </VTooltip>
            </template>
            <template v-else>
              <VTooltip bottom>
                <template #activator="{ on, attrs }">
                  <VBtn
                    :to="{
                      name: 'Edit-Article',
                      params: { articleId: item.id }
                    }"
                    class="mx-3"
                    fab
                    dark
                    x-small
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <VIcon dark> fa-edit </VIcon>
                  </VBtn>
                </template>
                <span>Edit the article</span>
              </VTooltip>

              <VTooltip bottom>
                <template #activator="{ on, attrs }">
                  <VBtn
                    class="mx-3"
                    fab
                    dark
                    x-small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="onDelete(item)"
                  >
                    <VIcon dark> fa-trash </VIcon>
                  </VBtn>
                </template>
                <span>Delete the article</span>
              </VTooltip>
            </template>
          </template>
        </VTreeview>

        <!-- <VCard class="mx-auto" v-if="!loading">
          <VList>
            <CategoryList
              v-for="(category, index) in getCategoriesTree"
              :key="index + category.title"
              :category="category"
              :categories="category.categories"
              :articles="category.articles"
            ></CategoryList>
          </VList>
        </VCard> -->
      </VCol>
    </VRow>

    <VDialog v-model="dialog" width="500">
      <VCard>
        <VCardTitle class="text-h5 grey lighten-2">
          Are you sure want to delete?
        </VCardTitle>

        <VCardText v-if="selectedDelete">
          Are you sure want to delete {{ deletedType }}
          <b>{{ selectedDelete.title }}</b
          >?
        </VCardText>

        <VDivider></VDivider>

        <VCardActions>
          <VSpacer></VSpacer>
          <VBtn color="error" text @click="onAccept()">
            Oke la
          </VBtn>
          <VBtn color="secondary" text @click="onClose()">
            Cancel
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </VContainer>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { currentUser } from "@/utils/jwt";
import CategoryList from "./CategoryList.vue";
import docsAPI from "@/api/internal/docs.js";

export default {
  name: "Docs-Index",
  components: {
    CategoryList
  },
  data() {
    return {
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"]
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"]
      ],
      dialog: false,
      selectedDelete: null,
      search: "",
      runnerSearch: null,
      expandAll: ""
    };
  },
  computed: {
    ...mapState({
      categories: state => state.docs.categories.list,
      articles: state => state.docs.articles.list,
      loadingCategories: state => state.docs.categories.loading,
      loadingArticles: state => state.docs.articles.loading
    }),
    ...mapGetters({
      me: "authUser"
      // categories: "getDocCategories",
      // articles: "getDocArticles"
    }),
    deletedType() {
      if (this.selectedDelete.type == "category") {
        return "the category";
      }

      return "the article";
    },
    hasEditRole() {
      return this.me && this.me.roles && this.me.roles.includes("docs");
    },
    currentDomain() {
      return window.location.host;
    },
    loading() {
      return this.loadingCategories || this.loadingArticles;
    },
    // transformCategoryAttributes() {
    //   return this.categories.map(s => {
    //     if (s.hasOwnProperty("title")) {
    //       s.name = s.title;
    //     }
    //     return s;
    //   });
    // },
    getCategoriesTree() {
      console.log("huhu tai sao lai chay vao day");

      const categoriesList = [
        ...this.categories.map(e => ({ ...e, ...{ type: "category" } })),
        ...this.articles.map(e => ({ ...e, ...{ type: "article" } }))
      ];
      const tree = categoriesList
        .filter(x => !x.category_id)
        .map(x => ({
          ...x,
          children: this.getChildren(categoriesList, x)
        }));
      return tree;
    }
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      DeleteArticle: "DOCS_DELETE_ARTICLE",
      DeleteCategory: "DOCS_DELETE_CATEGORY"
    }),
    ...mapActions({
      fetchCategories: "fetchCategories",
      fetchArticles: "fetchArticles"
    }),
    onSearch() {
      this.runnerSearch && window.clearTimeout(this.runnerSearch);

      this.runnerSearch = window.setTimeout(() => {
        this.expandAll = null;
        this.fetchArticles({ s: this.search });
      }, 200);
    },
    onDelete(item) {
      this.dialog = true;
      this.selectedDelete = item;
    },
    onAccept() {
      this.dialog = false;
      let deleteFunc = null;
      let deleteId = this.selectedDelete.id;
      if (this.selectedDelete.type == "category") {
        deleteFunc = docsAPI.deleteCategory;
      } else {
        deleteFunc = docsAPI.deleteArticle;
      }
      this.OpenLoading();
      deleteFunc(deleteId)
        .then(res => {
          console.log(res);
          let mutateFunc = null;
          if (this.selectedDelete.type == "category") {
            deleteFunc = this.DeleteCategory;
          } else {
            deleteFunc = this.DeleteArticle;
          }
          deleteFunc(this.selectedDelete);
          this.OpenSuccess({
            message: "Success!",
            time: 2000
          });
        })
        .catch(err => {
          // this.OpenError({ message: `<p>${err.message}</p>` });
        })
        .finally(() => {
          this.CloseLoading();
        });
    },
    onClose() {
      this.dialog = false;
      this.selectedDelete = null;
    },
    getChildren(array, item) {
      return array
        .filter(x => x.category_id == item.id && item.type == "category")
        .map(x => ({
          ...x,
          children: this.getChildren(array, x)
        }));
    },
    isTreviewNode(item) {
      return (
        item &&
        item.$el &&
        item.$el.classList &&
        item.$el.classList.contains("v-treeview-node")
      );
    },
    recursiveCollapse(item) {
      if (!item.isOpen) {
        item.isOpen = true;
      }
      if (item.$children && item.$children.length) {
        item.$children
          .filter(child => {
            return this.isTreviewNode(child);
          })
          .forEach(child => {
            this.recursiveCollapse(child);
          });
      }
    },
    doCollapseAll() {
      if (this.$refs && this.$refs.tree) {
        this.$refs.tree.updateAll(false);
        this.$refs.tree.$children
          .filter(child => {
            return this.isTreviewNode(child);
          })
          .forEach(child => {
            this.recursiveCollapse(child);
          });
      }
    }
  },
  created() {
    document.title = "Development Documents";
    if (!this.categories || !this.categories.length) {
      this.fetchCategories();
    }
    if (!this.articles || !this.articles.length) {
      this.fetchArticles();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  }
};
</script>

<style lang="scss">
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
  position: relative;
  background: #d6cba4;
  border-radius: 0.4em;
  padding: 15px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}
</style>
