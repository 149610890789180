import auth from "../../api/auth";

export default {
  state: {
    currentUser: {},
    initial: {
      loading: false,
      data: null,
      roles: null,
    },
  },
  mutations: {
    STORE_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    COMMIT_USER_ROLES(state, roles) {
      state.initial.roles = roles;
    }
  },
  actions: {
    getMyRoles(store) {
      const state = store.state.initial;
      return new Promise(async (resolve, reject) => {
        if (state.roles) {
          resolve(state.roles);
          return;
        }
        try {
          state.loading = true;
          if (!store.state.currentUser || !store.state.currentUser.email) {
            throw(new Error("No user"));
          }
          const res = await auth.getRoles(store.state.currentUser.email);
          store.commit("COMMIT_USER_ROLES", res.data.data)
          resolve(res.data.data);
        } catch (err) {
          console.error(err);
          reject(err);
        } finally {
          state.loading = false;
        }
      });
    },
  },
  getters: {
    authUser: (state) => ({
      ...state.currentUser,
      roles: state.initial.roles
    }),
  },
};
