<template>
  <div class="purchase">
    <div class="item_wrapper gf_flex gf_align-i-center">
      <div class="item purchase-item-action">
        <VBtn small color="info" @click="showModal">Detail</VBtn>
      </div>
      <div class="item purchase-item-charge">
        {{ purchaseItem.charge_id }}
      </div>
      <div class="item purchase-item-status">
        <span v-if="purchasePaid" class="purchase-active">Paid</span>
        <span v-else class="purchase-pending">Un Paid</span>
      </div>
      <div class="item purchase-item-domain">
        <p><strong>Shopify Domain: </strong> <span>{{ purchaseItem.shopify_domain }}</span></p>
        <p><strong>Title: </strong> <span>{{ purchaseItem.title }}</span></p>
      </div>
      <div class="item purchase-item-price">${{ purchaseItem.price }}</div>
      <div class="item purchase-item-link">
        <p class="sub_title mb-1">
          Copy: <span v-if="notifyCopy" class="notify ml-1">Copied!</span>
        </p>
        <div class="gf_flex gf_align-i-center">
          <div
            class="input_with_copy gf_flex gf_align-i-center"
            @click="copy(purchaseItem.link)"
          >
            <input
              ref="inputCoupon"
              type="text"
              :value="purchaseItem ? purchaseItem.link : ''"
              placeholder="Coupon"
              disabled
            />
            <i @click="copy(purchaseItem.link)" class="fa fa-clone"></i>
          </div>
        </div>
      </div>
      <div class="item purchase-item-author">
        {{ purchaseItem.created_by }}
      </div>
      <div class="item purchase-item-date">
        {{ purchaseItem.created_at | formatDate }}
      </div>
    </div>
    <!-- Modal -->
    <Modal
      v-if="isShowModal"
      :hideExit="true"
      bClass="purchase-modal"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModal"
    >
      <template v-slot:default>
        <div class="gf_col-12">
          <h2 class="title">Detail Purchase</h2>
          <div class="purchase-modal-content">
            <div class="gf_col-md-6">
              <div class="item">
                <h4>Shopify domain:</h4>
                <p>{{ purchaseItem.shopify_domain }}</p>
              </div>
              <div class="item">
                <h4>Title:</h4>
                <p>{{ purchaseItem.title }}</p>
              </div>
              <div class="item">
                <h4>Status:</h4>
                <p>
                  <span v-if="purchasePaid" class="purchase-active">Paid</span
                  ><span v-else class="purchase-pending">Un Paid</span>
                </p>
              </div>
              <div class="item">
                <h4>Created by:</h4>
                <p>{{ purchaseItem.created_by }}</p>
              </div>
              <div class="item">
                <h4>
                  Link:
                  <span v-if="notifyCopy" class="notify ml-1">Copied!</span>
                </h4>
                <div
                  class="input_with_copy gf_flex gf_align-i-center"
                  @click="copy(purchaseItem.link)"
                >
                  <input
                    ref="inputCoupon"
                    type="text"
                    :value="purchaseItem ? purchaseItem.link : ''"
                    placeholder="Coupon"
                    disabled
                  />
                  <i class="fa fa-clone"></i>
                </div>
              </div>
            </div>
            <div class="gf_col-md-6">
              <div class="item">
                <h4>Charge ID:</h4>
                <p>{{ purchaseItem.charge_id }}</p>
              </div>
              <div class="item">
                <h4>Price:</h4>
                <p>${{ purchaseItem.price }}</p>
              </div>
              <div class="item">
                <h4>Type:</h4>
                <p>{{ purchaseItem.category }}</p>
              </div>
              <div class="item">
                <h4>Created at:</h4>
                <p>{{ purchaseItem.created_at | formatDate }}</p>
              </div>
              <div class="item">
                <h4>Updated at:</h4>
                <p>{{ purchaseItem.updated_at | formatDate }}</p>
              </div>
              
            </div>
            <div class="gf_col-md-12">
              <div class="item">
                <h4>Note:</h4>
                <div
                  class="purchase-modal-content-note"
                  v-html="purchaseItem.note"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="purchase-footer">
          <button
            type="button"
            class="gt_btn gt_btn-secondary"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

let _timeoutNotify = "";
const PAY_STATUS = {
  PAID: "paid",
  UN_PAID: "unpaid"
};
export default {
  name: "Purchase",
  props: {
    purchaseItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowModal: false,
      shopDomain: "",
      notifyCopy: false,
      messageWrongFormat: false
    };
  },
  computed: {
    ...mapState({
      randomCoupon: state => state.purchases.randomCoupon
    }),
    purchasePaid() {
      return this.purchaseItem.status == PAY_STATUS.PAID;
    },
    apiHost() {
      return localStorage._GEM_API_HOST;
    },

    couponLink() {
      if (this.randomCoupon && this.randomCoupon.code) {
        return this.apiHost + "/coupons/" + this.randomCoupon.code;
      }
      return "";
    }
  },
  methods: {
    ...mapActions({
      pickCouponAction: "pickCouponAction"
    }),

    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.activeNotifyCopy();
      } catch (error) {
        console.error('Failed to copy text: ', error);
      }
    },

    activeNotifyCopy() {
      if (_timeoutNotify) {
        clearTimeout(_timeoutNotify);
      }
      this.notifyCopy = true;
      _timeoutNotify = setTimeout(() => {
        this.notifyCopy = false;
      }, 1000);
    },

    showModal() {
      this.isShowModal = true;
    },

    closeModal() {
      this.isShowModal = false;
    }
  },
  filters: {
    formatDate(isoDate) {
      const date = new Date(isoDate);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const seconds = date
        .getSeconds()
        .toString()
        .padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.purchase {
  &-modal {
    width: 80%;
    padding: 50px;

    header {
      margin-bottom: 30px;
    }

    main {
      height: 600px;
      overflow: auto;
    }
  }

  &-pending {
    color: #ffc700;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-active {
    color: green;
    font-weight: bold;
    text-transform: uppercase;
  }
}

input {
  margin: 0;
}

.input_with_copy {
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;
  width: 100%;
  margin-right: 15px;

  input {
    border: none;
    box-shadow: none;
    flex: 1;
    padding: 4px 6px;
  }

  i {
    cursor: pointer;
    margin-right: 5px;

    border-left: 1px solid #888;
    padding-left: 4px;

    path {
      fill: #888;
    }

    &:hover {
      transition: 0.3s all;

      path {
        fill: #333;
      }
    }
  }
}

.notify {
  color: #29d89d;
  margin-left: 5px;
}

.highlight {
  color: #5563c1;
}
</style>
<style lang="scss">
.purchase-footer {
  display: flex;
  justify-content: end;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.87);

  .gt_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;

    &:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
    }
  }
}

.purchase-modal .gt_modal-container {
  width: 80%;
  padding: 16px;

  header {
    margin-bottom: 30px;
  }
  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  .purchase-modal-content {
    margin-top: 16px;
    margin-bottom: 16px;

    p {
      margin-bottom: 0;
    }

    .item {
      margin-bottom: 16px;
    }

    &-note {
      white-space: pre-wrap;
    }
  }

  main {
    height: 600px;
    overflow: auto;
  }
}
</style>
