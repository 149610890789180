import api from "@/api";
import { catcher } from "@/api/handleError";
import { su } from "./endpoints";
import { verifyJwt } from "@/utils/jwt";

export default {
  verify(sid, fromRouter, toRouter) {
    return new Promise((resolve, reject) => {
      try {
        if (!sid) {
          reject({ message: "Session not found." });
          return;
        }

        verifyJwt(sid)
          .then((decoded) => {
            resolve(decoded);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        if (typeof err == "string") {
          reject({ message: err });
        }
        reject(err);
      }
    });
  },
  // TODO: sẽ phải yêu cầu viết api riêng để lấy roles cho user hiện tại thông qua token chứ k phải qua query như này nữa
  getRoles(email) {
    return api()
      .get(su.user.role.get, { params: { email } })
      .catch(catcher);
  },
};
