// import arrayUnique from "@/library/arrayUnique.js";
import feedbackApi from "@/api/feedback.js";

export default {
  state: {
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,
    feedbackFormTypes: [],
  },
  mutations: {
    FEEDBACK_TO_PAGE(state, page) {
      state.page = page;
    },
    FEEDBACK_ADD_FEEDBACK_FORM(state, snippet) {
      let index = state.list.findIndex((item) => item.id == snippet.id);
      if (index > -1) state.list.splice(index, 1, snippet);
      else state.list.push(snippet);
    },

    FEEDBACK_FETCH_FEEDBACK_FORMS(state, list) {
      if (!list || !list.length) return;
      state.list = list;
    },
    FEEDBACK_FETCH_FEEDBACK_FORM_TYPES(state, list) {
      if (!list || !list.length) return;
      state.feedbackFormTypes = list;
    },
    FEEDBACK_UPDATE_FEEDBACK_FORM(state, feedbackForm) {
      if (!snippet) return;
      let index = state.list.findIndex((item) => {
        return feedbackForm.id == item.id;
      });
      if (index > -1) {
        state.list.splice(index, 1, feedbackForm);
      }
    },
  },
  actions: {
    fetchFeedbackForms(store) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      feedbackApi
        .list()
        .then((res) => {
          console.log("resres", res);
          store.commit("FEEDBACK_FETCH_FEEDBACK_FORMS", res.data.feedbacks);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch((err) => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },

    fetchFeedbackFormTypes(store) {
      feedbackApi
        .getFeedbackFormTypes()
        .then((res) => {
          if (res.data) {
            store.commit("FEEDBACK_FETCH_FEEDBACK_FORM_TYPES", res.data);
          }
        })
        .catch((err) => {});
    },
  },
  getters: {
    // snippets_currentPage: state => {
    //   return (state.snippets.pages["_"+(state.snippets.current_page)] || []);
    // },
    feedbackPagination: (state) => {
      let total = Math.ceil(state.list.length / state.limit);
      return {
        per: state.limit,
        current: state.page,
        currentraw: state.page,
        total: total,
      };
    },
  },
};
