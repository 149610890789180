import api from "@/api/index.js";
import { catcher } from "@/api/handleError";
import { purchase } from "./endpoints";

export default {
  getAll({page, limit, shopify_domain}) {
    if(page && limit) {
      return api()
      .get(purchase.getAll, { params: { page, limit, shopify_domain }})
      .catch(catcher);
    }else {
      return api()
      .get(purchase.getAll)
      .catch(catcher);
    }
    
  },
  createPurchase(data) {
    return api()
      .post(purchase.create, data)
      .catch(catcher);
  }
};
