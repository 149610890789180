<template>
  <div class="main--body--content">
    <div class="gf_flex button-add-item">
      <VTextField
        type="search"
        placeholder="Type something to search..."
        outlined
        dense
        label="Search Feedback Forms"
        class="mx-2"
        v-model="search"
      />
      <router-link class="add" :to="{ name: 'NewFeedbackForm' }">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New Feedback Form</span>
        </VBtn>
      </router-link>
    </div>
    <div class="tab_content">
      <!-- <a class="add" :to="{name: 'CampaignDetail',params: { id : item.id }} "> -->
      <div class="add">
        <div class="item item-first">
          <span class="item-id">ID</span>
          <span class="item-type">Title</span>
          <span class="item-date">Feature</span>
          <span class="item-title">Status</span>
        </div>
      </div>
      <div class="gf_flex gf_flex-wrap" v-if="!fetched && loading">
        <!-- <H1>Fetching</H1> -->
        <FeedbackFormItem v-for="index in 10" :key="index" />
        <!-- <center>Loading</center> -->
      </div>
      <div class="gf_flex gf_flex-wrap" v-else-if="!feedbackForms.length">
        <div class="message-empty">
          <div class="speech-bubble">
            <h4>
              There's still no snippet in database. Please insert more to view.
            </h4>
          </div>
          <img class="psyduck" src="../../assets/psyduck.png" />
        </div>
      </div>
      <a
        v-else-if="list.length"
        class="add"
        :key="i"
        v-for="(item, i) in list"
        @click.prevent="viewDetailFeedbackForm(item)"
      >
        <div class="item">
          <span class="item-id">{{ item.id }}</span>
          <span class="item-type">{{ item.title }}</span>
          <span class="item-date">{{ item.handle }}</span>
          <span class="item-title">{{ item.status }}</span>
        </div>
      </a>
      <div
        class="gf_flex gf_flex-wrap"
        v-else-if="!list.length && feedbackForms.length > 0"
      >
        <div class="message-empty">
          <div class="speech-bubble">
            <h4>Nothing match your search. Please try another keyword.</h4>
          </div>
          <img class="pikachu" src="../../assets/pika_stand.png" />
        </div>
      </div>
      <!-- <Pagination :pagina.sync="pagination" :total.sync="pagination.total" @page="goToPage"/> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import FeedbackFormItem from "./FeebbackFormItem.vue";
import Pagination from "@/components/essenstials/Pagination.vue";
export default {
  name: "Feedback-Index",
  components: {
    FeedbackFormItem,
    Pagination,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState({
      feedbackForms: (state) => state.feedback.list,
      limit: (state) => state.feedback.limit,
      page: (state) => state.feedback.page,
      loading: (state) => state.feedback.loading,
      fetched: (state) => state.feedback.fetched,
    }),

    list() {
      return this.queriedList.filter((item, index) => {
        return (
          index >= (this.page - 1) * this.limit &&
          index < this.page * this.limit
        );
      });
    },
    queriedList() {
      let search = this.search.toLowerCase();
      return this.filtered.filter((item) => {
        let label = (item.title || "").toLowerCase();
        if (label.indexOf(search) > -1) return true;
        // let features = item.features.join(" | ").toLowerCase();
        // if (features.indexOf(search) > -1) return true;
        return false;
      });
    },
    pagination() {
      let total = Math.ceil(this.queriedList.length / this.limit);
      return {
        per: this.limit,
        current: this.page,
        currentraw: this.page,
        total: total,
      };
    },
    filtered() {
      return this.feedbackForms;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      ToPage: "FEEDBACK_TO_PAGE",
    }),
    ...mapActions({
      fetchFeedbackForms: "fetchFeedbackForms",
      fetchFeedbackFormTypes: "fetchFeedbackFormTypes",
    }),

    viewDetailFeedbackForm(item) {
      this.$router.push({
        name: "EditFeedbackForm",
        params: { handle: item.handle },
      });
    },
  },
  created() {
    this.fetchFeedbackForms();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  },
};
</script>

<style lang="scss" scoped>
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
  position: relative;
  background: #d6cba4;
  border-radius: 0.4em;
  padding: 15px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}
.add {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
  .item {
    &-first {
      height: 40px !important;
      font-weight: bold !important;
    }
    height: 50px;
    padding: 16px;
    background: white;
    border-radius: 3px;
    cursor: pointer;
    // width:100%;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow: 1px 1px 1px 1px #fafafa;
    &:hover {
      box-shadow: 2px 2px 2px 2px #d1d1d1;
    }
    &-id {
      width: 5%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-type {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-date {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-title {
      width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-url {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-delete {
      width: 10%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
