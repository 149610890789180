<template>
  <div v-if="total > 1" class="pagination">
    <button
      class="pagination__prev"
      :class="{'no-hover': pagi.current === 1}"
      :disabled="(pagi.current === 1) || disabled"
      @click="prevPagiPage"
    ></button>

    <button
      class="pagination__page"
      v-if="total > 1"
      :class="{'active': pagi.current === 1}"
      :disabled="disabled"
      @click="pagi.current = 1"
    >1</button>
    <button class="pagination__page" disabled v-if="pagi.current > 4 && pagiRange.min > 2">...</button>
    <div class="inner-pagi">
      <button
        class="pagination__page"
        :class="{'active': pagi.current === i + pagiRange.min - 1}"
        v-for="i in pagiRange.left + 1"
        :key="i + pagiRange.min - 1"
        :disabled="disabled"
        @click="pagi.current = i + pagiRange.min - 1"
      >
        <span>{{i + pagiRange.min - 1}}</span>
      </button>
    </div>
    <div class="inner-pagi" v-if="pagiRange.right+1 > 0">
      <button
        class="pagination__page"
        :class="{'active': pagi.current === i + pagi.current}"
        v-for="i in pagiRange.right + 1"
        @click="pagi.current = i + pagi.current"
        :disabled="disabled"
        :key="i + pagi.current"
      >
        <span>{{i + pagi.current}}</span>
      </button>
    </div>
    <button class="pagination__page" disabled v-if="pagi.current < total - 3 && pagiRange.max < total - 1">...</button>
    <button
      class="pagination__page"
      :class="{'active': pagi.current === total}"
      @click="pagi.current = total"
      :disabled="disabled"
      v-if="pagiRange.max < total"
    >{{total}}</button>

    <button
      class="pagination__next"
      @click="nextPagiPage"
      :class="{'no-hover': pagi.current === total}"
      :disabled="(pagi.current === total) || disabled"
    ></button>
  </div>
</template>

<script>
/**
 * Calculate deps for pagination.
 * @param {Number} _c current index
 * @param {Number} total total page count
 * @param {Number} bound number of item left/right side of pagination.
 */
function calcPagi(_c, total, bound) {
  if (_c) {
    let _r = {
      min: _c - bound,
      max: _c + bound
    };

    if (_r.min <= 1) {
      let change = 2 - _r.min;
      _r.min = 2;
      if (_r.max + change <= total) _r.max += change;
    }

    if (_r.max > total) {
      let change = _r.max - total;
      _r.max = total;
      if (_r.min - change > 1) _r.min -= change;
    }

    _r.left = _c - _r.min;
    _r.right = _r.max - _c - 1;
    return _r;
  } else {
    return {
      left: 0,
      right: 0,
      min: 1,
      max: 1
    };
  }
}

export default {
  name: "Pagination",
  props: {
    total: Number,
    pagina: Object,
    disabled: Boolean
  },
  data() {
    return {
      pagi: { per: 10, current: 1, currentraw: 1 },
      windowWidth: window.outerWidth
    };
  },
  computed: {
    pagiRange() {
      let _c = this.pagi.current;
      if (this.windowWidth > 800) {
        return calcPagi(_c, this.total, 2);
      } else {
        return calcPagi(_c, this.total, 1);
      }
    }
  },
  methods: {
    nextPagiPage() {
      let _inc = this.pagi.current + 1;
      this.pagi.current = _inc >= this.total ? this.total : _inc;
    },
    prevPagiPage() {
      let _dec = this.pagi.current - 1;
      this.pagi.current = _dec <= 1 ? 1 : _dec;
    }
  },
  watch: {
    "pagi.current"(newVal, oldVal) {
      // window.scrollTo(0, 0);
      if (newVal !== oldVal) this.$emit("page", newVal);
      // console.log("pagi changed");
    }
  },
  beforeMount() {
    if (this.pagina) this.pagi = { ...this.pagina };
  },
  mounted() {
    const func = () => {
      this.windowWidth = window.outerWidth;
    };
    this.$nextTick(() => {
      window.addEventListener("resize", func);
    });
  },
  beforeDestroy() {
    const func = () => {
      this.windowWidth = window.outerWidth;
    };
    this.$nextTick(() => {
      window.removeEventListener("resize", func);
    });
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0.2px;
  width: 100%;
  margin-top: 30px;
  // margin-bottom: 30px;

  background-color: #ffffff;

  .inner-pagi {
    flex-direction: row;
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #504f62;
    box-shadow: none;
    border-radius: 0;
    border: none;
    min-width: 40px;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: transparent;
    transition: background 0.4s ease;
    font-size: 14px;
    $radius: 2px;

    &:not([disabled]):hover {
      background: #eaebf3;
      cursor: pointer;
    }

    &:first-child {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }

    &:last-child {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }

    &:not(.no-hover):disabled {
      min-width: 10px;
    }
  }

  &__page {
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom: 2px solid #547aff;
    }

    &-disabled {
      cursor: default;
    }
  }

  &__prev:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    height: 18px;
    width: 18px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23504f62' d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' /%3E%3C/svg%3E");
  }

  &__next:after {
    content: "";
    display: inline-block;
    vertical-align: top;
    height: 18px;
    width: 18px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23504f62' d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' /%3E%3C/svg%3E");
  }

  &__total {
    display: inline-block;
    font-size: 14px;
    margin-left: 6px;
    line-height: 36px;
  }

  input {
    font-size: 13px;
    line-height: 36px;
    font-weight: 500;
    width: 36px;
    height: 36px;
    padding: 0 2px;

    text-align: center;

    border: none;
    border-radius: 2px;
    background: #fff;

    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}
</style>
