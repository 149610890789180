<template>
  <VContainer class="main--body--content">
    <VRow>
      <VCol cols="12">
        <InputImage
          title="File Uploader"
          placeholder="https://uploadcare.com/jasf9028qitws/asfl;asf/3000x3000/light"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: "FileUploader",
  components: {}
};
</script>

<style></style>
