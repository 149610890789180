<template>
  <div class="form-group mb-0" :class="className">
    <div class="gf_col-xs-12">
      <slot name="actions"></slot>
      <VSelect
        :placeholder="placeholder"
        v-model="val"
        @input="onInput"
        @change="onInput"
        v-bind="attrs"
        v-on="listeners"
        :items="options"
        :disabled="readonly"
        outlined
        dense
        :label="title"
      >
      </VSelect>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectValue",
  props: {
    title: String,
    placeholder: String,
    value: [String, Array, Boolean, Number],
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      val: "",
    };
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    },
  },
  methods: {
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    },
  },
  created() {
    this.val = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  flex-direction: column;
}
</style>
