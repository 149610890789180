// import arrayUnique from "@/library/arrayUnique.js";
import blockAPI from "@/api/block.js";

export default {
  state: {
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,
  },
  mutations: {
    BLOCK_TO_PAGE(state, page) {
      state.page = page;
    },
    BLOCK_ADD_BLOCK(state, block) {
      let index = state.list.findIndex(item => item.id == block.id);
      if (index > -1)
        state.list.splice(index, 1, block);
      else
        state.list.push(block);
    },
    BLOCK_REMOVE_BLOCK(state, block) {
      let index = state.list.findIndex(item => item.id == block.id);
      if (index > -1)
        state.list.splice(index, 1);
    },
    BLOCK_FETCH_BLOCKS(state, list) {
      if (!list || !list.length) return;
      state.list = [];
      list.forEach(item => {
        state.list.push(item);
      });
    },
  },
  actions: {
    fetchBlocks(store) {
      let state = store.state;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      blockAPI
        .getAll()
        // .getPage(state.page)
        .then(res => {
          store.commit("BLOCK_FETCH_BLOCKS", res.data.objects);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    }
  },
  getters: {}
};