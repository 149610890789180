import api from "@/api";
import { catcher } from "@/api/handleError";
import { shop } from "./endpoints";

export default {
  getListDomain() {
    return api()
      .get(shop.listDomain)
      .catch(catcher);
  },
  updateDomain(data) {
    return api()
      .post(shop.updateDomain, {
        data: data,
      })
      .catch(catcher);
  },
  fetchWrongShopsPlan() {
    return api()
      .get(shop.fetchWrongPlan)
      .catch(catcher);
  },
  updateWrongShopsPlan() {
    return api()
      .put(shop.updateWrongPlan)
      .catch(catcher);
  },
  getPagesByShopGid(data){
    return api()
      .get(shop.getPagesByShopGid, { params: data})
      .catch(catcher);
  },
  changeGidPage(data){
    return api()
      .put(shop.changeGidPage, data)
      .catch(catcher);
  },
  listAppCredit(data){
    return api()
      .get(shop.listAppCredit, { params: data })
      .catch(catcher);
  },
  createAppCredit(data){
    return api()
      .post(shop.createAppCredit, data)
      .catch(catcher);
  },
  updateGemPagesVersion(data){
    return api()
      .post(shop.updateGemPagesVersion, data)
      .catch(catcher);
  },
  updateDefaultTemplateMetaFields(data){
    return api()
      .post(shop.updateDefaultTemplateMetaFields, data)
      .catch(catcher);
  },
};
