import api from "@/api";
import { catcher } from "@/api/handleError";
import { gpConfig } from "./endpoints";

export default {
  getPage(page = 1) {
    return api()
      .get(gpConfig.getPage, { params: { page }})
      .catch(catcher);
  },
  getAll() {
    return api()
      .get(gpConfig.getAll)
      .catch(catcher);
  },
  getOne(id) {
    return api()
      .get(gpConfig.getOne(id))
      .catch(catcher);
  },
  update(id, data) {
    return api()
      .put(gpConfig.update(id), data)
      .catch(catcher);
  },
  create(data) {
    return api()
      .post(gpConfig.create, data)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(gpConfig.delete(id))
      .catch(catcher);
  },
};
