<template>
  <div class="main--body--content">
    <div class="gf_flex button-add-item">
      <VSelect dense outlined v-model="filterVal" :items="selectOptions" style="max-width: 20%"></VSelect>
      <VTextField
        type="search"
        placeholder="Type something to search..."
        outlined
        dense
        label="Search Snippets"
        class="mx-2"
        v-model="search"
      />
      <router-link class="add" :to="{ name: 'NewSnippet' }">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New snippet</span>
        </VBtn>
      </router-link>
    </div>

    <div class="gf_flex gf_flex-wrap" v-if="!fetched && loading">
      <!-- <H1>Fetching</H1> -->
      <SnippetItem v-for="index in 10" :key="index" />
      <!-- <center>Loading</center> -->
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="!snippets.length">
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>There's still no snippet in database. Please insert more to view.</h4>
        </div>
        <img class="psyduck" src="../../assets/psyduck.png"/>
      </div>
    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="list.length">
      <SnippetItem
        :key="i"
        v-for="(snippet, i) in list"
        :item="snippet"
        @click="viewDetailSnippet"
        @delete="deleteSnippet"
      />
      <Pagination :pagina.sync="pagination" :total.sync="pagination.total" @page="ToPage"/>

    </div>
    <div class="gf_flex gf_flex-wrap" v-else-if="!list.length && snippets.length > 0">
      <div class="message-empty">
        <div class="speech-bubble">
          <h4>Nothing match your search. Please try another keyword.</h4>
          <!-- <h4>Please insert more snippets into database to view.</h4> -->
        </div>
        <img class="pikachu" src="../../assets/pika_stand.png"/>
      </div>
    </div>

    <!-- Modal -->
    <Modal
      v-if="itemDelete"
      :hideExit="true"
      cClass="gf_padding-20"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModalDelete"
    >
      <template v-slot:default>
        <div class="gf_col-md-12">
          <h3>Are you sure</h3>
          <h4 style="margin: 5px 0 30px 0;">you want to delete {{itemDelete.name}}?</h4>
          <div class="gf_btn-group gf_mt-20">
            <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
              Cancel
            </VBtn>
            <VBtn color="red" class="ml-2 white--text" @click="doDelete">
              <template v-if="deleting">Deleting</template>
              <template v-else>Delete</template>
            </VBtn>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { currentUser } from "@/utils/jwt";
import snippetAPI from "@/api/snippet.js";
import SnippetItem from "./SnippetItem.vue";
import Pagination from "@/components/essenstials/Pagination.vue";
export default {
  name: "Snippet-Index",
  components: {
    SnippetItem,
    Pagination
  },
  data() {
    return {
      search: "",
      filterVal: -1,
      showModal: false,
      itemDelete: undefined,
      deleting: false
    };
  },
  computed: {
    ...mapState({
      snippets: state => state.snippet.list,
      limit: state => state.snippet.limit,
      page: state => state.snippet.page,
      // snippets: state => state.snippet.snippets.list,
      loading: state => state.snippet.loading,
      fetched: state => state.snippet.fetched,
      categories: state => state.snippet.category.list,
      // snippets: state => (state.snippet.snippets.pages["_"+(state.snippet.snippets.current_page)] || [])
    }),
    ...mapGetters({
      // snippets: "snippets_currentPage",
      // pagi: "snippets_pagination"
    }),
    selectOptions() {
      return [
        {
          value: -1,
          text: "All"
        },
        ...this.categories.map(x => ({
          value: x.id,
          text: x.name
        }))
      ];
    },
    list() {
      return this.queriedList.filter((item, index) => {
        return (index >= (this.page-1) * this.limit) && (index < this.page * this.limit);
      });
    },
    queriedList() {
      let search = this.search.toLowerCase();
      return this.filtered.filter(item => {
        let label = (item.label || "").toLowerCase();
        if (label.indexOf(search) > -1) return true;
        // let features = item.features.join(" | ").toLowerCase();
        // if (features.indexOf(search) > -1) return true;
        return false;
      });
    },
    pagination() {
      let total = Math.ceil(this.queriedList.length / this.limit);
      return {
        per: this.limit,
        current: this.page,
        currentraw: this.page,
        total: total
      };
    },
    filtered() {
      if (this.filterVal == -1) {
        return this.snippets;
      }
      return this.snippets.filter(item => item.category_id == this.filterVal);
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      DeleteSnippet: "SNIPPET_REMOVE_SNIPPET",
      ToPage: "SNIPPET_TO_PAGE"
    }),
    ...mapActions({
      fetchSnippets: "fetchSnippets",
      // fetchSnippetsPage: "fetchSnippetsPage",
      fetchCategory: "fetchSnippetCategories"
    }),
    getSnippets() {
      let snippets = [];
      let search = this.search.toLowerCase();
      this.categories.forEach(snippet => {
        let name = snippet.name.toLowerCase();
        if (name.indexOf(search) !== -1 || search == "") {
          snippets.push(snippet);
        }
      });
      return snippets;
    },
    gotoSnippet() {
      this.OpenLoading();
    },
    viewDetailSnippet(item) {
      console.log("CALL");
      console.log(item);
      this.$router.push({
        name: "EditSnippet",
        params: { snippet_id: item.id }
      });
    },
    async deleteSnippet(item) {
      // Dear developers, please DO NOT authenticated in frontend like this, it is bullshit!
      // Please spend your time to do the authentication in backend instead :)

      // Only Nora can delete snippet
      // let user = await currentUser();
      // if(user && user.email != "nora@gempages.help") {
      //   this.OpenError({ message: "You can't do it grrrr" });
      //   return;
      // }
      if (!item) return;
      this.itemDelete = item;
    },
    doDelete() {
      this.deleting = true;
      snippetAPI
        .delete(this.itemDelete.id)
        .then(res => {
          this.deleting = false;
          this.DeleteSnippet(this.itemDelete);
          this.closeModalDelete();
          this.OpenSuccess({ message: "<p>Delete snippet successfully!</p>" });
        })
        .catch(err => {
          this.deleting = false;
          this.closeModalDelete();
          this.OpenError({ message: err });
        });
    },
    closeModalDelete() {
      this.itemDelete = undefined;
    }
  },
  created() {
    document.title = "Snippets Management";
    if (!this.categories || !this.categories.length) this.fetchCategory();
    if (!this.fetched)
      this.fetchSnippets();
    // this.fetchSnippetsPage(1);
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  }
};
</script>

<style lang="scss">
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
	position: relative;
	background: #d6cba4;
  border-radius: .4em;
  padding: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}

</style>
