import integration from "@/api/integration";
import { FETCH } from "@/utils/list";

export default {
  namespaced: true,
  state: {
    integration: {
      list: [],
      loading: false,
      fetched: false
    },
    endpoints: {},
    inputs: {}
  },
  mutations: {
    INTEGRATION_FETCH(state, { list, reset }) {
      if (!list || !list.length) return;
      let comparer = (obj, item) => {
        return obj.id == item.id;
      };
      if (reset) {
        state.integration.list = [];
      }

      FETCH(state.integration.list, list, comparer, null);
    },
    INTEGRATION_DELETE(state, id) {
      const idx = state.integration.list.findIndex(x => x.id == id);
      if (idx > -1) {
        state.integration.list.splice(idx, 1);
      }
    },
    INTEGRATION_CREATE(state, data) {
      state.integration.list.push(data);
    },
    INTEGRATION_GET(state, item) {
      const index = state.integration.list.findIndex(x => x.id == item.id);
      state.integration.list.splice(index, 1, item);
    },
    INTEGRATION_UPDATE(state, { id, data }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      delete data.id;
      const item = state.integration.list[index];
      Object.keys(data).forEach(key => {
        item[key] = data[key];
      });
      state.integration.list.splice(index, 1, item);
    },

    // Endpoint
    INTEGRATION_CREATE_ENDPOINT(state, { id, endpoint }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        state.integration.list[index].endpoints = [];
      }
      state.integration.list[index].endpoints.push(endpoint);
    },
    INTEGRATION_UPDATE_ENDPOINT(state, { id, endpointId, data }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        return;
      }
      const eIndex = list.findIndex(x => x.id == endpointId);
      if (eIndex < 0) {
        return;
      }
      const item = list[eIndex];
      if (!item) {
        return;
      }
      const copyData = JSON.parse(JSON.stringify(data));
      delete copyData.id;
      delete copyData.inputs;
      Object.keys(copyData).forEach(key => {
        item[key] = copyData[key];
      })
      state.integration.list[index].endpoints.splice(eIndex, 1, item);
    },
    INTEGRATION_DELETE_ENDPOINT(state, { id, endpointId }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        return;
      }
      const eIndex = list.findIndex(x => x.id == endpointId);
      if (eIndex < 0) {
        return;
      }
      state.integration.list[index].endpoints.splice(eIndex, 1);
    },

    // input
    INTEGRATION_CREATE_INPUT(state, { id, endpointId, input }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        return;
      }
      const eIndex = list.findIndex(x => x.id == endpointId);
      if (eIndex < 0) {
        return;
      }
      const inputs = state.integration.list[index].endpoints[eIndex].inputs;
      if (!inputs || !inputs.length) {
        state.integration.list[index].endpoints[eIndex].inputs = [];
      }
      state.integration.list[index].endpoints[eIndex].inputs.push(input);
    },
    INTEGRATION_UPDATE_INPUT(state, { id, endpointId, inputId, data }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        return;
      }
      const eIndex = list.findIndex(x => x.id == endpointId);
      if (eIndex < 0) {
        return;
      }
      const inputs = state.integration.list[index].endpoints[eIndex].inputs;
      if (!inputs || !inputs.length) {
        return;
      }
      const iIndex = inputs.findIndex(x => x.id == inputId);
      if (iIndex < 0) {
        return;
      }

      const item = inputs[iIndex];
      if (!item) {
        return;
      }
      const copyData = JSON.parse(JSON.stringify(data));
      delete copyData.id;
      Object.keys(copyData).forEach(key => {
        item[key] = copyData[key];
      })
      state.integration.list[index].endpoints[eIndex].inputs.splice(iIndex, 1, item);
    },
    INTEGRATION_DELETE_INPUT(state, { id, endpointId, inputId }) {
      const index = state.integration.list.findIndex(x => x.id == id);
      if (index < 0) {
        return;
      }
      const list = state.integration.list[index].endpoints;
      if (!list || !list.length) {
        return;
      }
      const eIndex = list.findIndex(x => x.id == endpointId);
      if (eIndex < 0) {
        return;
      }
      const inputs = state.integration.list[index].endpoints[eIndex].inputs;
      if (!inputs || !inputs.length) {
        return;
      }
      const iIndex = inputs.findIndex(x => x.id == inputId);
      if (iIndex < 0) {
        return;
      }
      state.integration.list[index].endpoints[eIndex].inputs.splice(iIndex, 1);
    },
  },
  actions: {
    async create(store, data) {
      try {
        const res = await integration.create(data);
        store.commit("INTEGRATION_CREATE", res.data.integration);

        return res.data.integration;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async list(store, force = false) {
      let state = store.state.integration;
      if (state.loading) {
        throw new Error("Fetching inprogress");
      }
      if (state.fetched && !force) {
        return state.list;
      }

      state.loading = true;
      try {
        const res = await integration.list();
        store.commit("INTEGRATION_FETCH", {
          list: res.data.integrationList,
          reset: true
        });

        state.loading = false;
        if (!state.fetched) {
          state.fetched = true;
        }
        return res.data.integrationList;
      } catch (err) {
        console.error(err);
        state.loading = false;
        throw err;
      }
    },
    async get(store, id) {
      try {
        const res = await integration.get(id);
        store.commit("INTEGRATION_GET", res.data.integration);
        return res.data.integration;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async update(store, { id, data }) {
      try {
        const res = await integration.update(id, data);
        store.commit("INTEGRATION_UPDATE", { id, data: res.data.integration });
        return res.data.integration;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async delete(store, id) {
      try {
        const res = await integration.delete(id);
        store.commit("INTEGRATION_DELETE", id);
        return res.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async createEndpoint(store, {id, data}) {
      try {
        const res = await integration.endpoint.create(id, data);
        store.commit("INTEGRATION_CREATE_ENDPOINT", { id, endpoint: res.data.endpoint});

        return res.data.endpoint;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async updateEndpoint(store, {id, endpointId, data}) {
      try {
        const res = await integration.endpoint.update(id, endpointId, data);
        store.commit("INTEGRATION_UPDATE_ENDPOINT", { id, endpointId, data: res.data.endpoint});

        return res.data.endpoint;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async deleteEndpoint(store, {id, endpointId}) {
      try {
        const res = await integration.endpoint.delete(id, endpointId);
        store.commit("INTEGRATION_DELETE_ENDPOINT", { id, endpointId });

        return res.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    // input
    async createInput(store, {id, endpointId, data}) {
      try {
        const res = await integration.endpoint.input.create(id, endpointId, data);
        store.commit("INTEGRATION_CREATE_INPUT", { id, endpointId, input: res.data.input });

        return res.data.input;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async updateInput(store, {id, endpointId, inputId, data}) {
      try {
        const res = await integration.endpoint.input.update(id, endpointId, inputId, data);
        store.commit("INTEGRATION_UPDATE_INPUT", { id, endpointId, inputId, data: res.data.input });

        return res.data.input;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async deleteInput(store, {id, endpointId, inputId}) {
      try {
        const res = await integration.endpoint.input.delete(id, endpointId, inputId);
        store.commit("INTEGRATION_DELETE_INPUT", { id, endpointId, inputId });

        return res.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
  getters: {}
};
