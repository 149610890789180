import store from "@/store";
export function catcher(error) {
  // console.log(error)
  if (error) {
    if (error.response) {
      // if (error.response.status == 401) {
      //     store.commit("OpenError", {
      //         message: "You are not logged in.<br>We will redirect after 5 seconds"
      //     });
      //     setTimeout(function () {
      //         window.location.href = "/";
      //     }, 5000);
      // } else
      if (error.response.status == 400) {
        if (error.response.data.message.messages) {
          message = error.response.data.message.messages;
        } else if (error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = error.response.statusText;
        }
        console.log(message);
        store.commit("OpenError", {
          message: message
        });
      } else if (error.response.status == 500) {
        store.commit("OpenError", {
          message: "Server encountered a problem.<br>Please try again later"
        });
      } else if (error.response.status == 503) {
        let message = "Oops. Something has broken.<br>Please try again later";
        if (error.response.data.message.messages) {
          message = error.response.data.message.messages;
        } else if (error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = error.response.statusText;
        }
        store.commit("OpenError", {
          message: message
        });
      } else {
        store.commit("OpenError", {
          message: "Oops. Something has broken.<br>Please try again later"
        });
      }
    }
    // return new PromiseRejectionEvent((resolve, reject) => {
    //     reject(error.response);
    //     if (false)
    //         resolve(error.response);
    // });
    throw new Error(error);
    return error;
  } else {
    // return new Promise((resolve, reject) => {
    //     resolve({
    //         data: {
    //             message: "Request not found",
    //             status: false
    //         }
    //     })
    // })
    store.commit("OpenError", {
      message: "Server encountered a problem.<br>Please try again later"
    });

    throw new Error("Unknown error occur!");
  }
}
