<template>
  <div class="form-group gf_flex" :class="[className]">
    <label class="list-name gf_col-sm-4 gf_col-md-5">{{title}}</label>
    <ul class="feature-list gf_col-sm-8 gf_col-md-7" title="Delete this item">
      <li class="feature-item" :class="{'readonly': readonly}" @click="deleteItem(i)" :key="i" v-for="(item, i) in val">
        <span class="delete">-</span>
        <span class="content">{{item}}</span>
      </li>
      <li class="feature-item feature-item_add" :class="[{'focus': focusing}, {'readonly': readonly}]" title="Add more item">
        <span class="add-more" @click="addItem">+</span>
        <input
          class="field_add"
          type="text"
          :placeholder="placeholder"
          v-model="newItem"
          @keyup.enter="addItem"
          @keydown.enter.prevent
          @input="onInput"
          v-bind="attrs"
          v-on="listeners"
          @focus="focusing = true"
          @blur="focusing = false"
          :required="isRequired"
          :readonly="readonly"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DynamicList",
  props: {
    title: String,
    placeholder: String,
    value: Array,
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: [],
      newItem: "",
      focusing: false
    };
  },
  computed: {
    isRequired() {
      if (!this.required)
        return false;
      if (!this.val || !this.val.length)
        return true;
    },
    listeners() {
      const { input, focus, blur, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    },
    deleteItem(index) {
      if (this.readonly)
        return;
      this.val.splice(index, 1);
    },
    addItem() {
      if (this.readonly)
        return;
      if (!this.newItem || !this.newItem.length)
        return;
      this.val.push(this.newItem);
      this.newItem = "";
    }
  },
  created() {
    this.val = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-name {
  float: none;
}
.feature-list {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 0;
  margin-bottom: 1rem;
}
.feature-item {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  &_add {
    margin-top: 5px;
    box-shadow: 0 0 2px 0 #2C3A47;
    transition: box-shadow 0.3s ease;
    &.focus {
      box-shadow: 0 0 4px 0 #3B3B98;
    }
    &.readonly {
      background: #e6e6e6;
    }
    input {
      margin: 0;
      border: none;
      border-radius: 0;
      outline: none;
      padding: 0;
      box-shadow: none;
      flex: 1;
    }
  }
  .delete, .add-more {
    display: flex;
    // padding: 10px;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 28px;
    user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    min-width: 30px;
  }
  .add-more:hover {
    color: #1B9CFC;
    cursor: pointer;
  }
  &:not(&_add) {
    .content {
      cursor: default;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
    &:not(.readonly):hover {
      cursor: pointer;
      .content {
        cursor: pointer;
        color: #ccc;
        text-decoration: line-through;
        // position: relative;
        // &:after {
        //   content: "";
        //   top: calc(50% - 1px);
        //   height: 2px;
        //   background-color: #ddd;
        //   left: -2px;
        //   right: -2px;
        //   position: absolute;
        // }
      }
    }
  }


  
  
}
</style>
