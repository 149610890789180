// import arrayUnique from "@/library/arrayUnique.js";
import notificationAPI from "@/api/notification.js";

export default {
  state: {
    list: [],
    loading: false,
    hasNext: true,
    fetched: false,
    limit: 10,
    page: 1,
  },
  mutations: {
    // NOTIFICATION_FETCH_NOTIFICATIONS_PAGE(state, {list, page}) {
    //   state.notifications.pages["_"+page] = list;
    //   state.notifications.list = list;
    // },
    NOTIFICATION_TO_PAGE(state, page) {
      // state.notifications.current_page = page;
      state.page = page;
    },
    // NOTIFICATION_UPDATE_PAGINATION(state, pagination) {
    //   Object.keys(pagination).forEach(key => {
    //     state.notifications[key] = pagination[key];
    //   })
    // },

    NOTIFICATION_ADD_NOTIFICATION(state, notification) {
      if (!notification) {
        return;
      }
      let index = -1;
      if (state.list && state.list.length) {
        console.log("dmm")
        index = state.list.findIndex(item => item.id == notification.id);
      }
      if (index > -1)
        state.list.splice(index, 1, notification);
      else
        state.list.push(notification);
    },
    NOTIFICATION_REMOVE_NOTIFICATION(state, notification) {
      let index = state.list.findIndex(item => item.id == notification.id);
      if (index > -1)
        state.list.splice(index, 1);
    },
    NOTIFICATION_FETCH_NOTIFICATIONS(state, list) {
      console.log(list)
      if (!list || !list.length) return;
      if (JSON.stringify(state.list) !== JSON.stringify(list)) {
        let union = [];
        const find = item => {
          let found = state.list.findIndex(obj => {
            return obj.id === item.id;
          });

          return found;
        };

        list.forEach(item => {
          if (typeof item.metas == "string")
            item.metas = JSON.parse(item.metas);
          let index = find(item);
          if (index >= 0) {
            union.push({
              item: item,
              index: index
            });
          } else {
            state.list.push(item);
          }
        });
        if (union.length > 0)
          union.forEach(uno => {
            state.list.splice(uno.index, 1, uno.item);
          });
      }
    },
    NOTIFICATION_FETCH_CATEGORIES(state, list) {
      if (!list || !list.length) return;
      state.category.list = [];
      list.forEach(item => {
        state.category.list.push(item);
      });
    },
    NOTIFICATION_UPDATE_NOTIFICATION(state, notification) {
      if (!notification) return;
      let index = state.list.findIndex(item => {
        return notification.id == item.id;
      });
      if (index > -1) {
        state.list.splice(index, 1, notification);
      }
    }
  },
  actions: {
    fetchNotifications(store) {
      let state = store.state;
      if (state.loading)
        return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      notificationAPI
        .getAll()
        // .getPage(state.page)
        .then(res => {
          store.commit("NOTIFICATION_FETCH_NOTIFICATIONS", res.data.noti_list);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
        })
        .catch(err => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    // fetchNotificationsPage(store, page) {
    //   let state = store.state;
    //   if (state.loading)
    //     return;
    //   state.loading = true;
    //   // if (!state.fetched)
    //     store.commit("OpenLoading");
    //   notificationAPI
    //     .getPage(page)
    //     .then(res => {
    //       store.commit("NOTIFICATION_FETCH_NOTIFICATIONS", res.data.objects);
    //       store.commit("NOTIFICATION_FETCH_NOTIFICATIONS_PAGE", {list: res.data.objects, page: res.data.pagination.current_page});
    //       // store.commit("NOTIFICATION_UPDATE_PAGINATION", res.data.pagination);
    //       let pagination = res.data.pagination;
    //       Object.keys(pagination).forEach(key => {
    //         state.notifications[key] = pagination[key];
    //       })
    //       store.commit("NOTIFICATION_TO_PAGE", res.data.pagination.current_page);
    //       // state.offset += state.limit;

    //       state.loading = false;
    //       // if (!state.fetched)
    //       {
    //         store.commit("CloseLoading");
    //         state.fetched = true;
    //       }
    //     })
    //     .catch(err => {
    //       state.loading = false;
    //       // if (!state.fetched) {
    //         store.commit("CloseLoading");
    //       // }
    //     });
    // },
  },
  getters: {
    // notifications_currentPage: state => {
    //   return (state.notifications.pages["_"+(state.notifications.current_page)] || []);
    // },
    notifications_pagination: state => {
      let total = Math.ceil(state.list.length / state.limit);
      return {
        per: state.limit,
        current: state.page,
        currentraw: state.page,
        total: total
      };
    },
  }
};
