<template>
  <label
    class="gt_image-uploader"
    :class="[{'is-hover': isHover}, className]"
    @mouseenter="mHover"
    @mouseleave="mOut"
  >
    <input
      type="file"
      @change="readImage"
      ref="imageSelect"
      title="Upload your image"
      @dragenter="mHover"
      @dragleave="mOut"
      :disabled="readonly"
    >
    <div class="view" :class="{'uploading': loading}">
      <div class="img-container" v-if="picture !== ''">
        <img :src="picture" class="preview">
      </div>
      <template v-else>
        <slot>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            style="width: 20px; height: 20px;"
          >
            <path
              fill="currentColor"
              d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
              class
            ></path>
          </svg>
        </slot>
      </template>

      <progress max="100" v-if="loading" :value="percent"/>
      <div class="remove" v-if="percent > 0 && percent < 100" @click.prevent="onCancel">&times;</div>
      <div class="remove" v-if="picture !== '' && percent >= 100" @click="onDelete">
        <svg viewBox="0 0 576 512">
          <path
            fill="#eee"
            d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
            class
          ></path>
        </svg>
      </div>
    </div>
  </label>
</template>
<script>
export default {
  name: "ImageUpload",
  props: {
    oldSrc: String,
    index: Number,
    progress: Number,
    loading: Boolean,
    className: String,
    readonly: {
      type: Boolean,
      default: false
    },
    folder: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      picture: "",
      percent: 0,
      isHover: false
    };
  },
  computed: {},
  methods: {
    mHover() {
      if (this.readonly)
        return;
      this.isHover = true;
      // console.warn("HOVERRRRRRRRRRRR")
    },
    mOut() {
      if (this.readonly)
        return;
      this.isHover = false;
      // console.warn("OUTTTTTTTTTTTT")
    },
    onClick() {},
    onCancel() {
      this.picture = "";
      this.$emit("delete", this.index);
      this.$emit("cancel");
    },
    onDelete() {
      // this.picture = "";
      // this.$emit("delete", this.index);
      // if (!this.$refs.imageSelect)
      //   return;
      // this.$refs.imageSelect.files = null;
    },
    readImage() {
      if (!this.$refs.imageSelect) return;

      let files = this.$refs.imageSelect.files;
      if (files.length > 0) {
        this.$emit("startLoad");
        let image = files[0];
        let fName = image.name;
        let fread = new FileReader();
        fread.onload = evt => {
          this.$refs.imageSelect.value = "";
          let base64 = evt.target.result;
          let type = base64.split(";")[0].split(":")[1];
          if (type.indexOf("image") < 0) {
            this.$emit("uploadError", {
              message: "Selected file is not an image."
            });
            return;
          }
          this.picture = base64;

          console.log(image);
          this.$emit("load", {
            name: fName,
            base64: evt.target.result
          });
        };
        fread.onerror = () => {
          this.$refs.imageSelect.value = "";
          console.log(5);
        };
        fread.readAsDataURL(image);
      } else {
        // alert("LOI ROI HIHI");
      }
    }
  },
  mounted() {
    if (this.oldSrc) {
      this.picture = this.oldSrc;
    }
  },
  watch: {
    progress: {
      immediate: true,
      handler(newV) {
        this.percent = newV;
      }
    },
    oldSrc(newV, oldV) {
      this.picture = newV;
      this.percent = Infinity;
    }
  }
};
</script>
<style lang="scss" scoped>
$textShade2: #7f7f7f;
$textShade3: #dadada;
$textShade1: #333333;
$hover: #eeeeee;
$blue: #547aff;
$lightBlue: #6587ff;

.gt_title_sub_upload {
  font-weight: 400;
  margin: 10px 0;
}

label.gt_image-uploader {
  position: relative;
  border-radius: 5px;
  background: #bbbbbb;
  padding: 0;
  overflow: hidden;
  min-height: 100px;
  transition: all 0.3s linear;
  border: 1px solid #dddd;
  background-color: transparent;
  display: block;

  input[type="file"] {
    display: flex;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    margin: 0;
    opacity: 0;
    max-width: 100%;
    cursor: pointer;
    width: 100%;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    min-height: 120px;

    img {
      width: 100%;
    }

    svg {
      $size: 35px;
      width: $size;
      height: $size;

      path {
        transition: all 0.2s linear 0.15s;
      }
    }

    @-webkit-keyframes animate-stripes {
      100% {
        background-position: -100px 0px;
      }
    }

    @keyframes animate-stripes {
      100% {
        background-position: -100px 0px;
      }
    }

    progress {
      margin-top: 5px;
      margin-bottom: 2px;
      width: 95%;
      position: absolute;
      appearance: none;
      -webkit-appearance: none;
      height: 4px;
      transition: 0.4s;

      &::-webkit-progress-bar {
        background: $textShade3;
        border-radius: 2px;
        padding: 0;
        transition: 0.4s;
        overflow: hidden;
        // box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.2);
      }

      &::-webkit-progress-value {
        transition: 0.4s;
        background-image: -webkit-linear-gradient(
            -45deg,
            transparent 33%,
            $blue 33%,
            $lightBlue 66%,
            transparent 66%
          ),
          -webkit-linear-gradient(top, $blue, $lightBlue),
          -webkit-linear-gradient(left, $lightBlue, $blue);
        -webkit-animation: animate-stripes 5s linear infinite;
        animation: animate-stripes 5s linear infinite;

        border-radius: 2px;
        overflow: hidden;
        background-size: 35px 20px, 100% 100%, 100% 100%;
      }
    }

    .remove {
      $size: 100%;
      max-width: $size;
      height: 100%;
      min-width: $size;
      position: absolute;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.329);
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: hidden;
      transition: all 0.2s;
      color: $blue;
      font-size: 20px;
      opacity: 0;
      cursor: pointer;
    }

    .img-container {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      max-height: 120px;
    }

    .preview {
      $size: 100%;
      max-width: $size;
      // min-height: $size;
      height: 100%;
      min-width: $size;
    }

    &.uploading {
      .img-container {
        background: black;
        img {
          filter: opacity(0.5);
        }
      }
    }
  }

  &.is-hover {
    box-shadow: 0 0 5px 0 $blue;
    & .remove {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
