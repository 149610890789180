<template>
  <div>
    <VTabs>
      <VTab @click="() => tabTemplate = 'recommend'">Recommend</VTab>
      <VTab @click="() => tabTemplate = 'preview'">Preview</VTab>
    </VTabs>
    <div class="gf_recm-temp" v-if="tabTemplate == 'recommend'">
      <div class="gf_recm-temp__header">
        <div class="gf_recm-temp__header-title">Build Top Template</div>
        <div class="gf_recm-temp__header-action">
          <button class="gf_recm-temp__header-action-btn" @click="onUpdateRecmTemplate()">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 12C7.72386 12 7.5 11.7761 7.5 11.5L7.5 2.75338L5.3627 5.00578C5.17262 5.2061 4.85615 5.2144 4.65583 5.02432C4.45552 4.83424 4.44722 4.51777 4.6373 4.31746L7.63304 1.16038C7.72437 1.06174 7.85497 1 8 1C8.14495 1 8.2755 1.06169 8.36683 1.16024L11.3627 4.31746C11.5528 4.51777 11.5445 4.83424 11.3442 5.02432C11.1438 5.2144 10.8274 5.2061 10.6373 5.00578L8.5 2.75338L8.5 11.5C8.5 11.7761 8.27614 12 8 12Z" fill="white"/>
              <path d="M1.50002 11C1.77616 11 2.00001 11.2239 2.00001 11.5L2 13.5001C2 13.7762 2.22382 14 2.5 14H13.4999C13.7761 14 13.9999 13.7762 13.9999 13.5L13.9999 11.5C13.9999 11.2238 14.2238 11 14.5 11C14.7761 11 14.9999 11.2239 14.9999 11.5L14.9999 13.5C14.9999 14.3285 14.3283 15 13.4999 15H2.5C1.67161 15 1 14.3285 1 13.5001L1.00001 11.5C1.00001 11.2238 1.22388 11 1.50002 11Z" fill="white"/>
            </svg>
            Update
          </button>
        </div>
      </div>
      <div class="gf_recm-temp__body">
        <div class="gf_recm-temp__body-left">

          <div class="gf_recm-temp__body-left__item" :class="{'gf_recm-temp__body-left__item--active':  tabPageType == item}" v-for="(item, index) in Object.keys(pageTypes)" :key="index" @click="onChangeTabs(item)">
            <div class="gf_recm-temp__body-left__item-icon">
              <img :src="getIconPageType(item)" alt="">
            </div>
            <div class="gf_recm-temp__body-left__item-label">{{pageTypes[item]}}</div>
          </div>
        </div>

        <div class="gf_recm-temp__body-right row">
          <div class="gf_recm-temp__body-right__item col-6 col-md-6 col-lg-3" v-for="(item, index) in listRecmTempByTab" :key="index">
            <div class="gf_recm-temp__frame">
              <div class="gf_recm-temp__frame__thumb" v-if="isExistsValue(index)">
                <img :src="detailTemplatePick(item, index) && detailTemplatePick(item, index).thumbnail" alt="">
              </div>
              <div class="gf_recm-temp__frame__pick" :class="{'gf_recm-temp__frame__pick--blank': !isExistsValue(index)}" @click="() => {return}">
                <div class="gf_recm-temp__frame__pick__action" @click.stop="!isExistsValue(index) ? onShowSearchTempRecm(index) : () => {return}">
                  <div class="gf_recm-temp__frame__pick__action__chore" v-if="!isExistsValue(index)" @click.stop="onShowSearchTempRecm(index)">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0002 3C17.0002 2.44772 16.5525 2 16.0002 2C15.448 2 15.0002 2.44772 15.0002 3V15.0002H3C2.44772 15.0002 2 15.448 2 16.0002C2 16.5525 2.44772 17.0002 3 17.0002H15.0002V29.0001C15.0002 29.5524 15.448 30.0001 16.0002 30.0001C16.5525 30.0001 17.0002 29.5524 17.0002 29.0001V17.0002H29.0001C29.5524 17.0002 30.0001 16.5525 30.0001 16.0002C30.0001 15.448 29.5524 15.0002 29.0001 15.0002H17.0002V3Z"/>
                    </svg>
                  </div>
                  <template v-else>
                    <div class="gf_recm-temp__frame__pick__action__change" @click.stop="onShowSearchTempRecm(index)">Change Template</div>
                    <div class="gf_recm-temp__frame__pick__action__delete" @click.stop="onPickTempate(null, index)">Delete</div>
                  </template>
                </div>
                <div class="gf_recm-temp__frame__pick__note" v-if="index == 0 && !isExistsValue(index)"> <!-- Item Firt -> Tags: Recommend Temp -->
                  <span>The selected template will have a [Recommend] badge on the production.</span>
                </div>
              </div>

              <div class="gf_recm-temp__frame__search" v-if="isShowSearchRecm(index)" v-clickaway="onhideSearchRecm">
                <div class="gf_recm-temp__frame__search__input">
                  <div class="gf_recm-temp__frame__search__input-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12C8.20063 12 9.30243 11.5768 10.1644 10.8715L13.182 13.8891C13.3772 14.0843 13.6938 14.0843 13.8891 13.8891C14.0843 13.6938 14.0843 13.3772 13.8891 13.182L10.8715 10.1644C11.5768 9.30243 12 8.20063 12 7C12 4.23858 9.76142 2 7 2ZM3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7Z" fill="#9E9E9E"/>
                    </svg>
                  </div>
                  <input type="text" v-model="searchData" placeholder="Search for template..." class="">
                </div>
                <div class="gf_recm-temp__frame__search__result ">
                  <template v-if="queriedListTempSearch.length == 0">
                     <div class="gf_recm-temp__frame__search__result__item gf_recm-temp__frame__search__result__item--disabled">
                      <i>No item results !</i>
                    </div>
                  </template>
                  <template v-else v-for="(itTmp, idx) in queriedListTempSearch">
                    <div class="gf_recm-temp__frame__search__result__item gf_recm-temp__frame__search__result__item--active" v-if="itTmp.id == item" :key="'item' + idx">
                      {{ itTmp.name }}
                    </div>
                    <div class="gf_recm-temp__frame__search__result__item gf_recm-temp__frame__search__result__item--disabled" v-else-if="listRecmTempByTab.includes(itTmp.id)" :key="'item' + item + idx">
                      {{ itTmp.name }}
                    </div>
                    <div class="gf_recm-temp__frame__search__result__item"  v-else @click="onPickTempate(itTmp.id, index)" :key="'item' + item + idx">
                      {{ itTmp.name }}
                    </div>
                  </template>
                </div>
              </div>

            </div>
            <div class="gf_recm-temp__body-right__item-type">
              <span v-if="index == 0 && !isExistsValue(index)">Recommend Slot</span>
              <span v-else-if="!isExistsValue(index)">Slot {{ index + 1 }}</span>
              <span v-else>{{ (detailTemplatePick(item, index) && detailTemplatePick(item, index).name)}}</span>
            </div>
          </div>

        </div>

      </div>
    </div>
    <template v-else>
      <PreviewTemplate />
    </template>
    <div class="main--body--content">
      <div class="gf_flex button-add-item">
        <VTextField
          class="mr-2"
          outlined
          dense
          type="search"
          placeholder="Search template...."
          v-model="search"
        />
        <VBtn color="pink" class="mr-2" @click="exportData">Export Data</VBtn>
        <router-link class="add" :to="{ name: 'NewTemplate' }">
          <VBtn color="primary" class="text-normal" type="button">
            <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
            <span>New template</span>
          </VBtn>
        </router-link>
      </div>

      <div class="gf_flex gf_flex-wrap" v-if="!fetched && loading">
        <!-- <H1>Fetching</H1> -->
        <TemplateItem v-for="index in 10" :key="index" />
        <!-- <center>Loading</center> -->
      </div>
      <div class="gf_flex gf_flex-wrap" v-else-if="!templates.length">
        <div class="message-empty">
          <div class="speech-bubble">
            <h4>
              There's still no snippet in database. Please insert more to view.
            </h4>
          </div>
          <img class="psyduck" src="../../assets/psyduck.png" />
        </div>
      </div>
      <div class="gf_flex gf_flex-wrap" v-else-if="list.length">
        <TemplateItem
          :key="i"
          v-for="(t, i) in list"
          :item="t"
          @click="viewDetailTemplate"
          @delete="deleteTemplate"
        />
        <Pagination
          :pagina.sync="pagination"
          :total.sync="pagination.total"
          @page="ToPage"
        />
      </div>
      <div
        class="gf_flex gf_flex-wrap"
        v-else-if="!list.length && templates.length > 0"
      >
        <div class="message-empty">
          <div class="speech-bubble">
            <h4>Nothing match your search. Please try another keyword.</h4>
          </div>
          <img class="pikachu" src="../../assets/pika_stand.png" />
        </div>
      </div>

      <!-- Modal -->
      <Modal
        v-if="itemDelete"
        :hideExit="true"
        cClass="gf_padding-20"
        mClass
        hClass="gf_flex gf_flex-wrap"
        @cancel="closeModalDelete"
      >
        <template v-slot:default>
          <div class="gf_col-md-12">
            <h3>Are you sure</h3>
            <h4 style="margin: 5px 0 30px 0;">
              you want to delete {{ itemDelete.label }}?
            </h4>
            <div class="gf_btn-group gf_mt-20">
              <VBtn color="secondary" class="mr-2" @click="closeModalDelete">
                Cancel
              </VBtn>
              <VBtn color="red" class="ml-2 white--text" @click="doDelete">
                <template v-if="deleting"
                  >Deleting</template
                >
                <template v-else
                  >Delete</template
                >
              </VBtn>
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import templateAPI from "@/api/template.js";
import TemplateItem from "./TemplateItem.vue";
import PreviewTemplate from "./PreviewTemplate.vue";
import Pagination from "@/components/essenstials/Pagination.vue";
import { serverUrl } from "@/configs";
import Cookie from "@/utils/cookie.js";
export default {
  name: "Template-Index",
  components: {
    PreviewTemplate,
    TemplateItem,
    Pagination,
  },
  data() {
    return {
      search: "",
      filterVal: -1,
      showModal: false,
      itemDelete: undefined,
      deleting: false,
      tabPageType: "all",
      pageTypes: {
        "all": "All Templates",
        "landingpages": "Landing Page",
        "homepages": "Home Page",
        "articlepages": "Article Page",
        "collectionpages": "Collection Page",
        "productpages": "Product Page",
      },
      showSearchRecm: "",
      searchData: "",
      tabTemplate: "recommend"
    };
  },
  computed: {
    ...mapState({
      templates: (state) => state.template.list,
      recommendTemplates: (state) => state.template.recommendTemplates,
      limit: (state) => state.template.limit,
      page: (state) => state.template.page,
      loading: (state) => state.template.loading,
      fetched: (state) => state.template.fetched,
    }),
    ...mapGetters({}),
    list() {
      return this.queriedList.filter((item, index) => {
        return (
          index >= (this.page - 1) * this.limit &&
          index < this.page * this.limit
        );
      });
    },
    queriedList() {
      let search = this.search.toLowerCase();
      return this.filtered.filter((item) => {
        let name = (item.name || "").toLowerCase();
        if (name.indexOf(search) > -1) return true;
        return false;
      });
    },
    pagination() {
      let total = Math.ceil(this.queriedList.length / this.limit);
      return {
        per: this.limit,
        current: this.page,
        currentraw: this.page,
        total: total,
      };
    },
    filtered() {
      // if (this.filterVal == -1) {
      return this.templates;
      // }
      // return this.templates.filter(
      //   (item) => item.category_id == this.filterVal
      // );
    },
    vlRecmTemplates() {
      return this.recommendTemplates.value;
    },
    listTempSearch() {
      return this.filtered.filter((item) => {
        const tags = item["tags"];
        if (tags && tags.length > 0) {
          const indexTags = tags.findIndex((tag) => {
            if (this.tabPageType == "all") {
              return true;
            } else if (this.tabPageType == "landingpage") {
              if (tag.handle == "landingpage"|| tag.handle == "subpages") return true; // tag.indexOf("subpages") > -1: Support for old templates
            } else {
              if (tag.handle == this.tabPageType) return true;
            }
          }) > -1;
          return indexTags;
        }
        return false;
      }) || [];
    },
    queriedListTempSearch() {
      let searchData = this.searchData.toLowerCase();
      return this.listTempSearch.filter((item) => {
        let name = (item.name || "").toLowerCase();
        if (name.indexOf(searchData) > -1) return true;
        return false;
      });
    },
    listRecmTempByTab() {
      return this.vlRecmTemplates && this.vlRecmTemplates[this.tabPageType] || [];
    }

  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CloseLoading: "CloseLoading",
      DeleteTemplate: "TEMPLATE_REMOVE_TEMPLATE",
      ToPage: "TEMPLATE_TO_PAGE",
    }),
    ...mapActions({
      fetchTemplates: "fetchTemplates",
      updateRecmTemplate: "updateRecmTemplate",
      updatePreviewTemplate: "updatePreviewTemplate",
    }),
    exportData() {
      const token = Cookie.get(Cookie.key);
      const url = `${serverUrl()}/api/open/templates/export-global?token=${token}`;
      window.open(url, "_blank");
    },
    viewDetailTemplate(item) {
      console.log(item);
      this.$router.push({
        name: "EditTemplate",
        params: { template_id: item.id },
      });
    },
    deleteTemplate(item) {
      if (!item) return;
      this.itemDelete = item;
    },
    doDelete() {
      this.deleting = true;
      templateAPI
        .delete(this.itemDelete.id)
        .then((res) => {
          this.deleting = false;
          this.DeleteTemplate(this.itemDelete);
          this.closeModalDelete();
          this.OpenSuccess({ message: "<p>Delete template successfully!</p>" });
        })
        .catch((err) => {
          this.deleting = false;
          this.closeModalDelete();
          this.OpenError({ message: err });
        });
    },
    closeModalDelete() {
      this.itemDelete = undefined;
    },
    getIconPageType(pageType) {
      return '/img/icons/icon-' + pageType + '.svg';
    },
    onChangeTabs(pageType) {
      this.onhideSearchRecm();
      this.tabPageType = pageType;
    },
    isExistsValue(index) {
      return this.vlRecmTemplates && this.vlRecmTemplates[this.tabPageType] && this.vlRecmTemplates[this.tabPageType][index] ? true : false;
    },
    detailTemplatePick(id, index) {
      const listTempSearch = this.listTempSearch.filter((item) => {
        return item.id == id;
      })
      if (listTempSearch && listTempSearch.length > 0) {
        return listTempSearch[0]
      }
      this.onPickTempate(null, index)
      return {};
    },
    async onUpdateRecmTemplate() {
      this.OpenLoading();
      await this.updateRecmTemplate({
        data: this.vlRecmTemplates,
        onSuccess: () => {
          this.CloseLoading();
          this.OpenSuccess({
            message: "Update Build Top Template Successfully",
            time: 1500,
          });
        },
        onError: (err) => {
          this.CloseLoading();
          this.OpenError({ message: err });
        }
      });
    },
    onShowSearchTempRecm (index) {
      this.showSearchRecm = this.tabPageType + "-" + index;
      return false;
    },
    isShowSearchRecm(index) {
      const pickSlot = this.tabPageType + "-" + index;
      return pickSlot == this.showSearchRecm;
    },
    onPickTempate(id, index) {
      let valueRecmTemplates = JSON.parse(JSON.stringify(this.recommendTemplates.value));
      this.$nextTick(() => {
        valueRecmTemplates[this.tabPageType][index] = id;
        this.recommendTemplates.value = valueRecmTemplates;
      });
      this.onhideSearchRecm();
      // valueRecmTemplates[this.tabPageType][index] = id;
      return;
    },
    getListRecmTemp(pageType) {
      return this.valueRecmTemplates[pageType] || [];
    },
    onhideSearchRecm() {
      this.searchData = "";
      this.showSearchRecm = "";
      return false;
    }
  },
  created() {
    document.title = "Template Management";
    if (!this.fetched) this.fetchTemplates();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.loading) {
      this.CloseLoading();
    }
    next(true);
  },
};
</script>

<style lang="scss">
.message-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.psyduck {
  width: 200px;
  margin-top: 5px;
  margin-right: 140px;
}
.pikachu {
  width: 280px;
  margin-top: 3px;
  margin-right: 47px;
}
.speech-bubble {
  position: relative;
  background: #d6cba4;
  border-radius: 0.4em;
  padding: 15px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #d6cba4;
    border-bottom: 0;
    border-left: 0;
    margin-left: -12.5px;
    margin-bottom: -25px;
  }
}
.gf_recm-temp {
  background: #FFFFFF;
  z-index: 1;
  position: relative;
  margin-bottom: 17px;
  border-radius: 3px;
  &__header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 3px 3px 0px 0px;
    border-bottom: 1px solid #EEEEEE;

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #212121;
    }
    &-action {
      margin-left: auto;
      &-btn {
        display: flex;
        align-items: center;
        padding: 8px 25px;
        background: #3C67FF;
        border-radius: 3px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        svg {
          margin-right: 8px;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &__body {
    // padding: 16px;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 0px 0px 3px 3px;
    &-left {
      border-radius: 0px 0px 3px 3px;
      background: #FFFFFF;
      width: 20%;
      flex: 0, 0, 20%;
      border-right: 1px solid #EDEDED;
      &__item {
        display: flex;
        align-items: center;
        padding: 10px 8px 10px 24px;
        cursor: pointer;
        &--active {
          border-left: 2px solid #3C67FF;
          background: #E5E5E5;
        }
        &:hover {
          background: #E5E5E5;
        }
        &-icon {
          color: #212121;
          margin-right: 16px;
          height: 20px;
        }
        &-label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #212121;
          padding: 0;
        }
        &--disabled {
          cursor: not-allowed;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #9E9E9E;
        }
      }
    }
    &-right {
      width: 80%;
      flex: 0, 0, 80%;
      padding: 24px 40px;
      margin: 0 -16px !important;
      &__item {
        padding: 0 16px !important;
        margin-bottom: 16px;

        .gf_recm-temp__frame {
          height: 270px;
          width: 100%;
          position: relative;
          max-height: 270px;
          @media (max-width: 1263.8px){
            height: 380px;
          }
          &__pick {
            height: 100%;
            width: 100%;
            position: relative;
            display: flex;
            background: transparent;
            border-radius: 3px;
            border: 1px solid #D8E1FF;

            &__action {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              height: 100%;
              width: 100%;
              padding: 16px;
              &__chore {
                width: 32px;
                height: 32px;
                cursor: pointer;
                svg {
                  fill: #676767;
                }
              }
              &__change {
                display: flex;
                opacity: 0;
                height: 32px;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #FFFFFF;
                background: #323232;
                border-radius: 3px;
                margin-bottom: 8px;
                cursor: pointer;
              }
              &__delete {
                display: flex;
                opacity: 0;
                width: 32px;
                height: 32px;
                height: 32px;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #FFFFFF;
                background: #F44336;
                border-radius: 3px;
                cursor: pointer;
              }
            }
            &__note {
              margin-top: auto;
              height: fit-content;
              font-style: italic;
              font-weight: 400;
              font-size: 12px;
              text-align: center;
              color: #676767;
              margin-bottom: 28px;
              padding: 0 20px;
              width: 100%;
            }
            &:hover {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) ;
              border: 1px solid #D8E1FF;
              .gf_recm-temp__frame__pick__action__chore {
                svg {
                  fill: #3C67FF;
                }
              }
              .gf_recm-temp__frame__pick__action {
                &__change, &__delete {
                  opacity: 1;
                  transition: all 0.45s
                }
              }
            }
            &--blank {
              border: 1px dashed #848484;
              cursor: pointer;
              &:hover {
                background: linear-gradient(0deg, rgba(216, 225, 255, 0.3), rgba(216, 225, 255, 0.3)), #FFFFFF !important;
                border: 1px dashed #3C67FF;
                .gf_recm-temp__frame__pick__action__chore {
                  svg {
                    fill: #3C67FF;
                  }
                }
              }
            }

          }
          &__thumb {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          &__search {
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #FFFFFF;
            background: #FFFFFF;
            z-index: 2;
            box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.12);
            &__input {
              width: 100%;
              display: flex;
              position: relative;
              height: 40px;
              background: #FFFFFF;
              border: 1px solid #D6D6D6;
              padding: 4px 16px;
              &-icon {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
              input {
                padding-left: 24px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                &:active, &:focus, &:focus-visible {
                  outline: none;
                }
                ::placeholder {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                  color: #9E9E9E;
                }
              }
            }
            &__result {
              border: 1px solid #D6D6D6;
              border-top: 0;
              width: 100%;
              height: 100%;
              padding: 8px;
              overflow-y: auto;
              max-height: 176px;
              &__item {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #212121;
                padding: 8px;
                cursor: pointer;
                &:hover {
                  background: #F4F4F4;
                  border-radius: 3px;
                }
                &--active {
                  color: #3C67FF;
                  border-left: 2px solid #3C67FF;
                  border-radius: 0 3px 3px 0;
                  &:hover {
                    border-radius: 0 3px 3px 0;
                    background: unset;
                  }
                }
                &--disabled {
                  cursor: not-allowed;
                  color: #9E9E9E;
                  &:hover {
                    background: unset;
                  }
                }
              }
            }
          }
        }
        &-type {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #212121;
        }
      }
    }
  }
}
</style>
