export default [
  {
    name: "Authenticate",
  },
  {
    name: "Root",
  },
  {
    name: "Dashboard-Index",
    text: "DashBoard",
    icon: `home`,
    role: "*",
    permission: "*",
  },
  {
    name: "Docs",
    text: "Development Docs",
    icon: `file-alt`,
    role: ["docs", "base"],
    permission: "*",
  },
  {
    name: "New-Category",
    text: "New Category",
    role: ["docs"],
    permission: "*",
  },
  {
    name: "New-Article",
    text: "New Article",
    role: ["docs"],
    permission: "*",
  },
  {
    name: "Edit-Category",
    text: "Edit Category",
    role: ["docs"],
    permission: "*",
  },
  {
    name: "Edit-Article",
    text: "Edit Article",
    role: ["docs"],
    permission: "*",
  },
  {
    name: "View-Article",
    text: "View Category",
    role: ["base"],
    permission: "*",
  },
  {
    name: "Snippets",
    text: "Snippets",
    icon: `code`,
    role: "snippet",
    permission: "read",
  },
  {
    name: "NewSnippet",
    role: "snippet",
    permission: "write",
  },
  {
    name: "EditSnippet",
    role: "snippet",
    permission: "write",
  },
  {
    name: "Integrations",
    text: "Integrations",
    icon: `network-wired`,
    role: "integration",
    permission: "read",
  },
  {
    name: "NewIntegration",
    role: "integration",
    permission: "write",
  },
  {
    name: "EditIntegration",
    role: "integration",
    permission: "write",
  },
  {
    name: "Templates",
    text: "Templates",
    icon: `cubes`,
    role: "template",
    permission: "read",
  },
  {
    name: "NewTemplate",
    role: "template",
    permission: "write",
  },
  {
    name: "EditTemplate",
    role: "template",
    permission: "write",
  },
  {
    name: "Blocks",
    text: "Blocks",
    icon: `cube`,
    role: "block",
    permission: "read",
  },
  {
    name: "Blocks",
    role: "block",
    permission: "read",
  },
  {
    name: "NewBlock",
    role: "block",
    permission: "write",
  },
  {
    name: "EditBlock",
    role: "block",
    permission: "write",
  },
  {
    name: "ListPurchase",
    text: "Purchase",
    icon: `credit-card`,
    role: "purchase",
    permission: "read",
  },
  {
    name: "CreatePurchase",
    role: "purchase",
    permission: "write",
  },
  {
    name: "ShowAllCampaigns",
    text: "Promotions",
    icon: `tags`,
    role: "promotion",
    permission: "read",
  },
  {
    name: "CreateCampaign",
    role: "promotion",
    permission: "write",
  },
  {
    name: "CampaignDetail",
    role: "promotion",
    permission: "write",
  },
  {
    name: "CouponList",
    role: "promotion",
    permission: "read",
  },
  {
    name: "Notifications",
    text: "Notify",
    icon: `bell`,
    role: "notification",
    permission: "read",
  },
  {
    name: "NewNoti",
    role: "notification",
    permission: "write",
  },
  {
    name: "EditNoti",
    role: "notification",
    permission: "write",
  },
  {
    name: "ShowAllSuggestion",
    text: "Suggestion",
    icon: `newspaper`,
    role: "suggestion",
    permission: "*",
  },
  {
    name: "CreateSuggestion",
    role: "suggestion",
  },
  {
    name: "ShowAllBlog",
    text: "Blog",
    icon: `rss`,
    role: "blog",
    permission: "*",
  },
  {
    name: "CreateBlog",
    role: "blog",
  },
  {
    name: "ShowAllBanner",
    text: "Banner",
    icon: `heading`,
    role: "banner",
    permission: "*",
  },
  {
    name: "CreateBanner",
    role: "banner",
  },
  {
    name: "ShopList",
    text: "Shops",
    icon: `store`,
    role: "shop",
    permission: "*",
  },
  {
    name: "DashboardV3",
    role: "shop",
  },
  {
    name: "WrongShopPlan",
    role: "shop",
  },
  {
    name: "FixGidDuplicated",
    role: "shop",
  },
  {
    name: "ShopifyAppCredit",
    role: "shop",
  },
  {
    name: "Testing",
    text: "A/B Testing",
    icon: `flask`,
    role: "ab-testing",
    permission: "*",
  },
  {
    name: "GPConfigs",
    text: "GP Config",
    icon: `cogs`,
    role: "gp-config",
    permission: "read",
  },
  {
    name: "NewGPConfig",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "EditGPConfig",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ListBanner",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ModifyBanner",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ListFAQs",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ModifyFAQs",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ListChangeLog",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ModifyChangeLog",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ListBlog",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "ModifyBlog",
    role: "gp-config",
    permission: "write",
  },
  {
    name: "AdminRPM",
    text: "Role & Policy",
    icon: "key",
    role: "admin",
    permission: "*",
  },
  {
    name: "AdminUserManager",
    role: "admin",
  },
  {
    name: "AdminPolicyManager",
    role: "admin",
  },
  {
    name: "AdminRoleManager",
    role: "admin",
  },
  {
    name: "LogList",
    text: "Bug Logs",
    icon: `bug`,
    role: "bug",
    permission: "*",
  },
  {
    name: "Languages",
    text: "Languages",
    icon: `language`,
    role: "language",
    permission: "*",
  },
  {
    name: "FileUploader",
    text: "File Uploader",
    icon: `file`,
    role: "admin",
    permission: "*",
  },
  {
    name: "GemMarkdownEditor",
    text: "Markdown Editor",
    icon: `cogs`,
    role: "admin",
    permission: "*",
  },
  {
    name: "feedback",
    text: "Feedback",
    icon: `cubes`,
    role: "feedback",
    permission: "*",
  },
  {
    name: "NewFeedbackForm",
    role: "feedback",
    permission: "*",
  },
  {
    name: "EditFeedbackForm",
    role: "feedback",
    permission: "*",
  },
  {
    name: "UpdateGemPagesVersion",
    role: "shop",
    permission: "*",
  },
  {
    name: "UpdateDefaultTemplateMetaFields",
    role: "shop",
    permission: "*",
  },
  {
    name: "DeAuthenticate",
  },
  {
    name: "Page404",
  },
  {
    name: "Fallback",
  },
];
