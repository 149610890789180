// import arrayUnique from "@/library/arrayUnique.js";
import suAPI from "@/api/su.js";
import { implicitPolicyToPolicy } from "@/utils/dataTransform";

export default {
  namespaced: true,
  state: {
    user: {
      list: [],
      loading: false,
      fetched: false,
    },
    role: {
      list: [],
      loading: false,
      fetched: false,
    },
    policy: {
      list: [],
      loading: false,
      fetched: false,
      implicit: {},
    },
  },
  mutations: {
    // SU_ADD_USER(state, user) {
    //   let index = state.user.list.findIndex((item) => item.id == user.id);
    //   if (index > -1) state.user.list.splice(index, 1, user);
    //   else state.user.list.push(user);
    // },
    // SU_REMOVE_USER(state, user) {
    //   let index = state.user.list.findIndex((item) => item.id == user.id);
    //   if (index > -1) state.user.list.splice(index, 1);
    // },
    SU_FETCH_USERS(state, list) {
      if (!list || !list.length) return;
      state.user.list = [];
      list.forEach((item) => {
        state.user.list.push(item);
      });
    },
    SU_USER_ADD_ROLES(state, { id, roles }) {
      const idx = state.user.list.findIndex((u) => u.id == id);
      if (idx > -1) {
        const item = state.user.list[idx];
        item.role_list.push(...roles);
        state.user.list.splice(idx, 1, item);
      }
    },
    SU_USER_DELETE_ROLE(state, { id, role }) {
      const idx = state.user.list.findIndex((u) => u.id == id);
      if (idx > -1) {
        const item = state.user.list[idx];
        const roleIndex = item.role_list.indexOf(role);
        item.role_list.splice(roleIndex, 1);
        state.user.list.splice(idx, 1, item);
      }
    },
    SU_FETCH_IMPLICIT_POLICIES(state, { email, list }) {
      const source = state.policy.implicit;
      if (!source[email]) {
        source[email] = [];
      }
      if (!list || !list.length) return;
      source[email].push(...list.map(implicitPolicyToPolicy));
    },
    SU_USER_ADD_POLICY(state, { email, policy }) {
      const source = state.policy.implicit;
      if (!source[email]) {
        source[email] = [];
      }
      if (!policy) {
        return;
      }

      source[email].push({
        ...policy,
        Domain: "gempages",
        Role: email,
      });

      state.user.list.forEach((user) => {
        if (user.roles && user.roles.includes(email)) {
          const uMail = user.email;
          if (source[uMail]) {
            source[uMail].push({
              ...policy,
              Domain: "gempages",
              Role: email,
            });
          }
        }
      });
    },
    SU_USER_DELETE_POLICY(state, { email, policy }) {
      const source = state.policy.implicit;
      if (!source[email]) {
        source[email] = [];
        return;
      }
      if (!policy) {
        return;
      }

      const idx = source[email].findIndex(
        (x) =>
          x.Role == email && x.Method == policy.Method && x.Path == policy.Path
      );

      if (idx < 0) {
        return;
      }

      source[email].splice(idx, 1);
    },

    // role
    SU_FETCH_ROLES(state, list) {
      if (!list || !list.length) return;
      state.role.list = [];
      state.role.list.push(...list);
    },
    SU_ADD_ROLE(state, { role }) {
      if (!role) {
        return;
      }
      const source = state.role;
      if (!source.list) {
        source.list = [];
      }
      source.list.push(role);
    },
    SU_DELETE_ROLE(state, { role }) {
      const source = state.role;
      if ((!role && role != "") || !source.list) {
        return;
      }
      const idx = source.list.findIndex((r) => r == role);
      if (idx > -1) {
        source.list.splice(idx, 1);
      }
    },

    // policy
    SU_FETCH_POLICIES(state, list) {
      if (!list || !list.length) return;
      state.policy.list = [];
      state.policy.list.push(...list);
    },
  },
  actions: {
    fetchUsers(store) {
      return new Promise(async (resolve, reject) => {
        let state = store.state.user;
        if (state.loading) {
          reject();
          return;
        }
        if (state.fetched) {
          resolve(state.list);
          return;
        }
        state.loading = true;
        if (!state.fetched) {
          store.commit("OpenLoading", undefined, { root: true });
        }
        try {
          const res = await suAPI.user.list();
          store.commit("SU_FETCH_USERS", res.data.data);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading", undefined, { root: true });
            state.fetched = true;
          }
          resolve();
        } catch (err) {
          console.error(err);
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading", undefined, { root: true });
          }
          reject(err);
        }
      });
    },
    addUserRoles(store, { id, email, roles }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.addRoles({
            domain: "gempages",
            sub: email,
            roles,
          });
          store.commit("SU_USER_ADD_ROLES", { id, roles });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteUserRole(store, { id, email, role }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.deleteRole({
            domain: "gempages",
            sub: email,
            role,
          });
          store.commit("SU_USER_DELETE_ROLE", { id, role });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    addUserPolicy(store, { email, policy }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.addPolicy({
            domain: "gempages",
            sub: email,
            act: policy.Method,
            object: policy.Path,
          });
          store.commit("SU_USER_ADD_POLICY", { email, policy });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteUserPolicy(store, { email, policy }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.deletePolicy({
            domain: "gempages",
            sub: email,
            act: policy.Method,
            object: policy.Path,
          });
          store.commit("SU_USER_DELETE_POLICY", { email, policy });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },

    // role
    fetchRoles(store) {
      return new Promise(async (resolve, reject) => {
        let state = store.state.role;
        if (state.loading) {
          reject();
          return;
        }
        if (state.fetched) {
          resolve(state.list);
          return;
        }
        state.loading = true;
        if (!state.fetched) {
          store.commit("OpenLoading", undefined, { root: true });
        }
        try {
          const res = await suAPI.role.list();
          store.commit("SU_FETCH_ROLES", res.data.data);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading", undefined, { root: true });
            state.fetched = true;
          }
          resolve();
        } catch (err) {
          console.error(err);
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading", undefined, { root: true });
          }
          reject(err)
        }
      });
    },
    addRole(store, { email, role }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.addRole({
            domain: "gempages",
            sub: email,
            role,
          });
          store.commit("SU_ADD_ROLE", { role });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteRole(store, { email, role }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await suAPI.user.deleteRole({
            domain: "gempages",
            sub: email,
            role,
          });
          store.commit("SU_DELETE_ROLE", { role });
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      });
    },

    // policy
    fetchPolicies(store) {
      return new Promise(async (resolve, reject) => {
        let state = store.state.policy;
        if (state.loading) {
          reject();
          return;
        }
        if (state.fetched) {
          resolve(state.list);
          return;
        }
        state.loading = true;
        if (!state.fetched) {
          // store.commit("OpenLoading", undefined, { root: true });
        }
        try {
          const res = await suAPI.policy.list();
          store.commit("SU_FETCH_POLICIES", res.data.data);

          state.loading = false;
          if (!state.fetched) {
            // store.commit("CloseLoading", undefined, { root: true });
            state.fetched = true;
          }
          resolve();
        } catch (err) {
          console.error(err);
          state.loading = false;
          if (!state.fetched) {
            // store.commit("CloseLoading", undefined, { root: true });
          }
          reject(err)
        }
      });
    },
    fetchImplicitPolicies(store, email) {
      return new Promise(async (resolve, reject) => {
        let state = store.state.policy;
        if (state.implicit[email]) {
          resolve(state.implicit[email]);
          return;
        }
        try {
          const res = await suAPI.user.getImplicitPolicies(email);
          store.commit("SU_FETCH_IMPLICIT_POLICIES", {
            email,
            list: res.data.data,
          });
          resolve(state.implicit[email]);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
  },
  getters: {},
};
