<template>
  <router-view />
</template>

<script>
export default {
  name: "Blog",
  components: {
    // AllCampaigns
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
::v-deep .campaign_wrapper {
  margin-top: 20px;
}
// .gf_section-2 {
::v-deep hr {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  height: 1px;
  background: #ebebec;
  border: 1px #ebebec;
}
// }
::v-deep .hr_low {
  margin: 10px 0;
}
</style>
