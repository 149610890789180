import shopAPI from "@/api/shop.js";

export default {
  state: {
    list: [],
    loading: false,
    fetched: false,
    page: 1,
    limit : 10,
    total_app_credits: null,
    total_pages: null,
  },
  mutations: {
    SHOPIFY_APP_CREDIT(state, list) {
      if (!list || !list.length) return;
      if (JSON.stringify(state.list) !== JSON.stringify(list)) {
        state.list = list;
      }
    },
    CREATE_SHOPIFY_APP_CREDIT(state, item) {
      const itemInList = state.list.find(x => x.id == item.id);
      if(itemInList){
        itemInList.shopifyDomain = item.shopifyDomain;
      }
    },
    APP_CREDIT_SET_PAGE(state, page) {
      state.page = page
    },
    APP_CREDIT_SET_PAGINATION(state, pagination) {
      if (!pagination) return;

      state.page = pagination.current_page;
      state.limit = pagination.per_page;
      state.total_pages = pagination.total_pages;
      state.total_app_credits = pagination.total_objects;
    },
  },
  actions: {
    fetchShopifyAppCredit(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");

      let payload = {page: state.page, limit: state.limit};
      if (data && data.shopify_domain && data.shopify_domain.trim()) {
        payload = {...payload, shopify_domain: data.shopify_domain.trim()}
      }

      shopAPI
        .listAppCredit(payload)
        .then((res) => {
          store.commit("SHOPIFY_APP_CREDIT", res.data.app_credits);
          store.commit("APP_CREDIT_SET_PAGINATION", res.data.pagination);

          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
            state.fetched = true;
          }
          if (res.data.status == 'error') {
            store.commit("OpenError", {
              message: res.data.message
            });
          }
        })
        .catch((err) => {
          state.loading = false;
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    },
    async createShopifyAppCredit(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      store.commit("OpenLoading");
      let isCreatedSuccess = false;
      await shopAPI
        .createAppCredit(data)
        .then((res) => {
          store.commit("CREATE_SHOPIFY_APP_CREDIT", res.data);
          state.loading = false;
          isCreatedSuccess = true
          store.commit("CloseLoading");
        })
        .catch((err) => {
          state.loading = false;
          store.commit("CloseLoading");
        });
      return isCreatedSuccess;
    },
    async updateGemPagesVersion(store, data) {
      let state = store.state;
      if (state.loading) return;
      state.loading = true;
      store.commit("OpenLoading");
      await shopAPI
        .updateGemPagesVersion(data)
        .then((res) => {
          state.loading = false;
          store.commit("CloseLoading");
          if (res && res.data.status == "error") {
            store.commit("OpenError", { message: res.data.message })
          } else {
            store.commit("OpenSuccess", { message: "Update version successfully!" })
          }
        })
        .catch((err) => {
          state.loading = false;
          store.commit("CloseLoading");
          store.commit("OpenError", { message: err });
        });
    },
  },
};
