<template>
  <div
    class="gf_snippet-item gf_flex gf_flex-row gf_snippet-item--content"
    v-if="item"
    @click="$emit('click', item)"
    ref="container"
  >
    <div class="gf_snippet-item--text">
      <h4>{{ item.name }}</h4>
    </div>
    <div
      class="gf_snippet-item--text gf_snippet-item--action gf_ml-auto"
      @click="toggleAvailable"
      :key="item.available ? 'lalal' : 'lulul'"
    >
      <svg viewBox="0 0 640 512" width="30" v-if="item.available">
        <path
          fill="currentColor"
          d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
          class
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="30" v-else>
        <path
          fill="currentColor"
          d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
          class
        />
      </svg>
    </div>
    <div class="gf_snippet-item--text gf_snippet-item--action" @click.stop="$emit('delete', item)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="30">
        <path
          fill="currentColor"
          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
          class
        />
      </svg>
    </div>
  </div>

  <div class="gf_snippet-item" v-else>
    <div class="gf_flex gf_flex-row gf_snippet-item--content">
      <div class="gf_snippet-item--icon">
        <svg role="img" viewBox="0 0 512 512" class="spinning">
          <path
            fill="currentColor"
            d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"
            class
          />
        </svg>
      </div>
      <div class="gf_snippet-item--text">
        <h4 class="gt_shimmer-180"></h4>
        <!-- <span class="gf_ranged-text"></span> -->
      </div>
      <div class="gf_snippet-item--text gf_ml-20">
        <a class="gt_shimmer-140"></a>
        <span class="gt_shimmer-250"></span>
      </div>
      <div class="gf_snippet-item--text gf_ml-auto gf_mr-auto gf_flex gf_align-i-center">
        <a class="gt_shimmer-300"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateItem",
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    toggleAvailable(e) {
      e.stopPropagation();
    }
  },
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";
.gf_snippet-item {
  white-space: nowrap;
  width: calc(100%);
  transition: all 0.3s;
  background: rgba(#d2dae2, 0.1);
  border: 1px solid rgba(#d2dae2, 0.5);
  border-radius: 4px;
  margin: 0 0em 1.5em 0em;
  padding: 1em;
  overflow: hidden;

  .spinning {
    animation: spin 1.5s ease-out infinite;
  }

  &:nth-child(odd) .spinning {
    animation-direction: reverse;
    animation-timing-function: ease-in;
  }

  &.inprogress {
    background: rgba(#fdff89, 0.1);
    border: 1px solid rgba(#909150, 0.3);
  }

  &.done {
    position: relative;
    background: rgba(#4cf397, 0.1);
    border: 1px solid rgba(#1b7041, 0.3);
    &:after {
      content: " ";
      position: absolute;
      $size: 5px;
      bottom: $size + 1;
      right: $size + 1;
      border: 3px solid #347b43;
      padding: $size * 1.3 $size/2;
      transform: rotate(35deg) skewY(25deg);
      border-top-color: transparent;
      border-left-color: transparent;
    }
  }

  &--icon {
    // background: #F7F7FC;
    // border: 1px solid #F7F7FC;
    // border-radius: 3px;
    margin-right: 15px;

    svg {
      width: 26px;
      color: #7e829b;
    }
  }

  &--action {
    width: 79px;
    margin: -16px -16px -16px 16px;
    // border-radius: 50%;
    // margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: #888;

    &:hover {
      color: $primary_1;
      background: #ddd;
    }
  }

  &--text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 36%;

    h4 {
      font-weight: 700;
    }

    span,
    h4,
    a {
      color: #485259;
      font-size: 16px;
      line-height: 22px;

      &:empty {
        animation: pulse 1s infinite ease-in-out;
        // width: 50px;
        height: 17px;
        // min-width: 70px;
        display: block;
        // margin-bottom: 10px;
        border-radius: 10px;
      }
    }

    span:empty {
      margin-top: 10px;
    }

    .gf_ranged-text {
      width: 100%;
      max-width: 350px;
      // min-width: 350px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      width: 100%;
      // display: flex;
    }

    a {
      // line-height: 13px;
      // font-size: 12px;
      // font-style: italic;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // height: calc(12px * 2);
      color: #1779ba;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 #ccc;
  }
}

.select {
  margin: 0;
  // margin-left: auto;
}
.icon-bounder {
  display: flex;
  // padding: 10px;
  $size: 45px;
  width: $size;
  height: $size;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  border-radius: 5px;
  background-color: $primary_1;
  &.element {
    background-color: $primary_3;
  }
  &.module {
    background-color: $primary_2;
  }
}
</style>
