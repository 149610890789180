import axios from "axios";
import api from "@/api";
import { catcher } from "@/api/handleError";
import { i18n } from "./endpoints";

export default {
  list() {
    return api()
      .get(i18n.list)
      .catch(catcher);
  },
  publish(data) {
    return api()
      .post(i18n.publish, data)
      .catch(catcher);
  },
  refresh(data) {
    return api()
      .put(i18n.refresh, data)
      .catch(catcher);
  },
  getJSON(link) {
    return axios
      .get(link)
      .catch(catcher);
  },
};
