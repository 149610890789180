import api from "@/api";
import { catcher } from "@/api/handleError";
import { integration } from "./endpoints";

export default {
  create(data) {
    return api()
      .post(integration.createRead, data)
      .catch(catcher);
  },
  list() {
    return api()
      .get(integration.createRead)
      .catch(catcher);
  },
  get(id) {
    return api()
      .get(integration.RUD(id))
      .catch(catcher);
  },
  update(id, data) {
    return api()
      .put(integration.RUD(id), data)
      .catch(catcher);
  },
  delete(id) {
    return api()
      .delete(integration.RUD(id))
      .catch(catcher);
  },
  endpoint: {
    create(id, data) {
      return api()
        .post(integration.endpoint.createRead(id), data)
        .catch(catcher);
    },
    list(id) {
      return api()
        .get(integration.endpoint.createRead(id))
        .catch(catcher);
    },
    get(id, endpointId) {
      return api()
        .get(integration.endpoint.RUD(id, endpointId))
        .catch(catcher);
    },
    update(id, endpointId, data) {
      return api()
        .put(integration.endpoint.RUD(id, endpointId), data)
        .catch(catcher);
    },
    delete(id, endpointId) {
      return api()
        .delete(integration.endpoint.RUD(id, endpointId))
        .catch(catcher);
    },
    input: {
      create(id, endpointId, data) {
        return api()
          .post(integration.endpoint.input.createRead(id, endpointId), data)
          .catch(catcher);
      },
      list(id, endpointId) {
        return api()
          .get(integration.endpoint.input.createRead(id, endpointId))
          .catch(catcher);
      },
      get(id, endpointId, inputId) {
        return api()
          .get(integration.endpoint.input.RUD(id, endpointId, inputId))
          .catch(catcher);
      },
      update(id, endpointId, inputId, data) {
        return api()
          .put(integration.endpoint.input.RUD(id, endpointId, inputId), data)
          .catch(catcher);
      },
      delete(id, endpointId, inputId) {
        return api()
          .delete(integration.endpoint.input.RUD(id, endpointId, inputId))
          .catch(catcher);
      },
    }
  }
};
