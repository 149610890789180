// import crawlerAPI from "@/api/crawler/crawler.js";
import { FETCH } from "@/utils/list.js";

export default {
  state: {
    activities: [],
    limit : 10,
    offset: 0,
  },
  mutations: {
    ANALYTICS_CACHE_ACTIVITY(state, list) {
      state.activities = list;
      let comparer = (obj, item) => {
        return obj.id === item.id;
      };
      FETCH(state.activities, list, comparer, null);
    },
  },
  actions: {
    async fetchActivities(store, data) {
      let state = store.state;
      
      await crawlerAPI
        .getActivities(data, state.limit, state.offset)
        .then(res => {
          if (res) {
            if (!state.fetched) {
              state.fetched = true;
            }

            store.commit("ANALYTICS_FETCH_ACTIVITIES", res.data.transactions);
            return res.data.transactions;
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          // store.commit("OpenError", {message: err})
          if (!state.fetched) {
            // store.commit("CloseLoading");
          }
        })

    },
  }
};
