<template>
  <div class="form-group" :class="className" v-if="options">
    <label class="gf_col-sm-4 gf_col-md-5">{{title}}</label>
    <template v-for="(item, index) in options">
      <input
        :key="index"
        type="radio"
        :value="item.v"
        v-model="val"
        @change="onInput"
        v-bind="attrs"
        v-on="listeners"
      />
      {{item.t}}
    </template>

    <!-- <div v-for="(item, index) in options" :key="index">{{item}}</div> -->
  </div>
</template>

<script>
export default {
  name: "RadioInput",
  props: {
    title: String,
    placeholder: String,
    value: [String, Number, Boolean, Event],
    className: {
      type: String,
      default: "gf_col-sm-12 gf_col-md-12"
    },
    options: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      val: ""
    };
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { ...attrs } = this.$attrs;
      return attrs;
    }
  },
  methods: {
    onInput(e) {
      // let value = e.target.value;
      // console.log(e.target.value);
      this.$emit("input", this.val);
    }
  },
  created() {
    this.val = this.value;
    // console.log(this.options);
  },
  watch: {
    value(newV) {
      this.val = newV;
    }
  }
};
</script>

<style>
</style>
