<template>
  <div class="d-flex flex-column">
    <div class="gf_flex justify-end" v-if="!disableCreateWithEmail.includes((currentUser && currentUser.email) || '')">
      <router-link class="add" :to="{name: 'CreateCampaign'}">
        <VBtn color="primary" class="text-normal" type="button">
          <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
          <span>New campaign</span>
        </VBtn>
      </router-link>
    </div>

    <div class="tab_content">

      <div class="campaign_wrapper" :key="item.id" v-for="(item, i) in campaignList">
        <router-link class="add" :to="{name: 'CampaignDetail',params: { id : item.id }} ">
          <Campaign :campaign_item="item"/>
        </router-link>
      </div>

    <Pagination :pagina.sync="pagination" :total.sync="pagination.total" @page="goToPage"/>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
// import shopAPI from "@/api/shop.js";
import Tabs from "@/components/Tabs";
import Campaign from "./Campaign";
import CampaignDetail from "./CampaignDetail";
import DatePicker from 'vue2-datepicker';
import Pagination from '@/components/essenstials/Pagination';

const TABS = [{
  title: 'Campaigns',
  value: 'campaigns',
}, {
  title: 'Create campaign',
  value: 'create',
}];

const CampaignTabs = [{
    title: 'All',
    value: 'all',
  }, {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Scheduled',
    value: 'scheduled',
  },
  {
    title: 'Expired',
    value: 'expired',
  }
];

export default {
  name: "ShowAllCampaigns",
  data() {
    return {
      shops: [],
      domain: "",
      tabs: TABS,
      currentTab: 'campaigns',
      campaignTabs: CampaignTabs,
      currentCampaignTab : 'all',
      types : 'discount',
      applyTo : 'all',
      c_name: '',
      c_prefix: '',
      condition_value : '',
      selectDateRange: true,
      unit: 'percent',
      revertToPreviousPlan : true,
      date: '',
      oneTimeCoupon : false,
      coupon_limit: '',
      filterVal : '',
      search : '',
      disableCreateWithEmail: ['thomasbui@gempages.help'],
      // campaignList : []
    };
  },
  computed: {
    ...mapState({
      campaignList: state => state.promotion.campaignList,
      limit: state => state.promotion.limit,
      currentPage : state => state.promotion.page,
      total_campaigns : state => state.promotion.total_campaigns,
      total_pages : state => state.promotion.total_pages,
    }),
    ...mapGetters({
      currentUser: "authUser"
    }),

    pagination() {
      return {
        per: this.limit,
        current: this.currentPage,
        currentraw: this.currentPage,
        total: this.total_pages
      };
    }

  },
  methods: {
    ...mapMutations({
      ChangeLoading: "ChangeLoading",
      toPage : "CAMPAIGN_FETCH_BY_PAGE"
    }),
    ...mapActions({
      fetchCampaigns: "fetchCampaigns"
    }),
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    handleCampaignTabsClick(newTab) {
      this.currentCampaignTab = newTab;
    },
    login(shop) {
      if (
        confirm("Bạn có chắc chắn muốn đăng nhập vào: " + shop.myshopify_domain)
      ) {
        this.ChangeLoading(true);
        shopAPI.login(shop.id).then(res => {
          this.ChangeLoading(false);
          if (res.data.status) {
            var host = window.location.hostname;
            let url = `http://${host}/`;
            window.open(url, "_blank");
          } else {
            alert(res.data.message);
          }
        });
      }
    },
    generateCoupons() {

    },
    goToPage(page) {
      this.toPage(page);
      // console.log('gotopage')
      this.fetchCampaigns(true);
    }
  },
  created() {
    this.fetchCampaigns();
  },
  updated() {

  },
  components: {
    Tabs,
    DatePicker,
    Campaign,
    CampaignDetail,
    Pagination
  }
};
</script>
<style lang="scss" scoped>
.main--body--content--child--content {
  background: #fff !important;

}

.button-add-item {
  a {
    margin-left: 0 !important;
  }
}

.tab_content {

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .hr_low {
    margin-top: -5px;
  }

  .tab_item {
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 20px;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    input {
      width: 100%;
      margin-bottom: 0;
      box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    }

    input[type='radio'],
    input[type='checkbox'] {
      width: auto;
      margin-bottom: 15px;
      margin-right: 10px;
      border: none;
      box-shadow: none;
    }

    .input_value {
      width: 30%;
    }

    .sub_title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    a {
        color: #006fbb;

        &:hover {
          color: #084e8a;
        }
    }
  }

  .create_segment  {
    margin-right: 25px;
  }

  .tab_summary {
      min-height: 200px;
      background-color: #f9fafb !important;
  }

  .campaign_wrapper {
    margin-bottom: 20px;
    // padding: 0 20px 0 0;
  }

}

.tab_list {
  background-color: #fff;
  padding: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}


</style>

