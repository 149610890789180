import purchasesAPI from "@/api/purchases.js";

export default {
  state: {
    purchaseList: [],
    currentPurchase: null,
    lastPurchase: null,
    loading: false,
    hasNext: true,
    page: 1,
    limit: 10,
    total_purchases: null,
    total_pages: null,
    paginationCoupon: {
      purchases_id: null,
      page: 1,
      limit: 10,
      total_purchases: null,
      total_pages: null
    },
    fetched: false
  },
  mutations: {
    PURCHASE_FETCH_ALL(state, list) {
      if (!list || !list.length) return;

      state.purchaseList = list;
    },
    PURCHASE_FETCH_PAGINATION(state, pagination) {
      if (!pagination) return;

      state.page = pagination.current_page;
      state.limit = pagination.per_page;
      state.total_pages = pagination.total_pages;
      state.total_purchases = pagination.total_objects;
    },
    PURCHASE_FETCH_BY_PAGE(state, page) {
      state.page = page;
    }
  },
  actions: {
    fetchPurchases(store, { isPaging, query }) {
      let state = store.state;
      state.loading = true;
      if (!state.fetched) store.commit("OpenLoading");
      else if (isPaging) store.commit("OpenLoading");
      const payload = { page: state.page, limit: state.limit };
      if (query) {
        payload.shopify_domain = query.trim();
      }
      purchasesAPI
        .getAll(payload)
        .then(res => {
          if (res.data) {
            if (!state.fetched) {
              state.fetched = true;
            }
            store.commit("CloseLoading");

            store.commit("PURCHASE_FETCH_ALL", res.data.transactions);
            store.commit("PURCHASE_FETCH_PAGINATION", res.data.pagination);
          } else {
            console.warn(res.data.message);
          }
          state.loading = false;
        })
        .catch(err => {
          state.loading = false;
          store.commit("OpenError", { message: err });
          if (!state.fetched) {
            store.commit("CloseLoading");
          }
        });
    }
  }
};
