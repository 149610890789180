export const getAppMode = () => {
  if (process.env.NODE_ENV == "development") {
    return "local";
  }

  switch (window.location.host) {
    case "gempagesv6.web.app": {
      return "firebase";
    }
    case "admin.gempages.xyz": {
      return "staging";
    }
    case "admin.gempages.net": {
      return "production";
    }
    default:
      return "staging";
  }
};

export const serverUrl = () => {
  let envUrl = process.env.VUE_APP_BASE_URL;
  let storeUrl = localStorage._GEM_API_HOST;
  let appMode = getAppMode();
  if (appMode == "local" || appMode == "staging") {
    if (storeUrl) {
      return storeUrl;
    } else {
      localStorage._GEM_API_HOST = envUrl;
    }
  }

  return envUrl;
};

export const baseUrl = () => {
  let appMode = getAppMode();
  if (appMode == "local") {
    return "http://localhost:8000";
  }
  if (["staging", "firebase"].includes(appMode)) {
    return "https://api-admin.gempages.xyz";
  }

  return "https://api-admin.gempages.net";
};

export const appUrl = () => {
  const apiUrl = localStorage._GEM_API_HOST;
  if (!apiUrl) return "https://app.gempages.net";
  switch (apiUrl) {
    case "https://api-dev.gempages.xyz": {
      return "https://app-dev.gempages.xyz";
    }
    case "https://api.gempages.xyz": {
      return "https://app.gempages.xyz";
    }
    case "http://localhost:3000": {
      return "http://localhost:3000";
    }
    default:
      return "https://app.gempages.net";
  }
};
